import { PortfolioTabs } from "constants/portfolio";

import { useEffect, useState } from "react";

import { ampli } from "ampli";
import AvailableLabel from "components/common/AvailableLabel";
import { StatusOnboarding } from "interfaces/createAccount";
import { ThemeVariants } from "interfaces/theme";
import { UserData } from "interfaces/user";
import type { BalanceCurrencies } from "interfaces/wallet";
import { getTabName } from "components/page-cocos-portfolio/utils";
import { useDeviceCheck } from "hooks/useDeviceCheck";

import DistributionTab from "./components/DistributionTab";
import ReturnsTab from "./components/ReturnsTab";
import ValueTab from "./components/ValueTab";
import PortfolioCurrencySwitch from "./components/common/CurrencySwtich";
import PortfolioEmptyState, {
  PortfolioEmpty,
} from "./components/common/PortfolioEmptyState";
import Header from "./components/common/Header";
import Desktop from "./components/Desktop";
import Balance from "./components/Desktop/Balance";

import styles from "./styles.module.scss";

interface PortfolioContentProps {
  user: UserData;
  selectedCurrency: BalanceCurrencies;
  variant?: ThemeVariants;
}

const PortfolioContent: React.FC<PortfolioContentProps> = ({
  user,
  selectedCurrency,
  variant = ThemeVariants.Capital,
}) => {
  const [selectedTab, setSelectedTab] = useState<PortfolioTabs>(
    PortfolioTabs.VALUE
  );
  const [portfolioState, setPortfolioState] = useState<PortfolioEmpty | null>(
    null
  );
  const { isDesktop } = useDeviceCheck();

  const { hasAccount, statusOnboarding } = user;

  const getHasEmptyState = () => {
    if (statusOnboarding === StatusOnboarding.NOT_STARTED)
      return setPortfolioState(PortfolioEmpty.ACCOUNT);

    if (statusOnboarding === StatusOnboarding.IN_PROGRESS)
      return setPortfolioState(PortfolioEmpty.ONBOARDING);

    return setPortfolioState(null);
  };

  useEffect(() => {
    ampli.portfolioScreenViewed({ tab: getTabName(selectedTab) });

    getHasEmptyState();
  }, []);

  return (
    <div className={styles.wrapper}>
      <Header
        variant={variant}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {portfolioState && (
        <>
          {isDesktop ? (
            <div className={styles.desktopEmptyBalanceWrapper}>
              <Balance
                balanceData={{
                  totalBalance: 0,
                  variation: {
                    amount: 0,
                    percentage: 0,
                  },
                  cashBalance: 0,
                  holdingsBalance: 0,
                }}
                variant={variant}
                isLoading={false}
              />
            </div>
          ) : (
            <>
              <PortfolioCurrencySwitch variant={variant} />

              <AvailableLabel
                currency={selectedCurrency}
                available={0}
                variant={variant}
              />
            </>
          )}
          <PortfolioEmptyState
            portfolioState={portfolioState}
            variant={variant}
          />
        </>
      )}
      {hasAccount && isDesktop && <Desktop variant={variant} />}
      {hasAccount && !isDesktop && (
        <>
          {selectedTab === PortfolioTabs.VALUE && (
            <ValueTab variant={variant} />
          )}
          {selectedTab === PortfolioTabs.RETURNS && (
            <ReturnsTab variant={variant} />
          )}
          {selectedTab === PortfolioTabs.DISTRIBUTION && <DistributionTab />}
        </>
      )}
    </div>
  );
};

export default PortfolioContent;
