import { useEffect } from "react";

import { ampli } from "ampli";
import classNames from "classnames";
import { useCryptoCable } from "context/CryptoCableProvider";
import { BankAccount } from "interfaces/crypto";
import { ThemeVariants } from "interfaces/theme";
import {
  ArrowDownToLine,
  BadgePercent,
  Banknote,
  Clock4,
  X,
} from "lucide-react";
import PageHeader from "components/common/PageHeader";
import Footer from "components/common/Footer";
import BulletList, { BulletListVariants } from "components/common/BulletList";
import FilledButton from "components/common/FilledButton";
import Banner, { BannerVariants } from "components/common/Banner";
import Text, { TextVariants } from "components/common/Text";
import { Share } from "@capacitor/share";

import AccountInfo from "./AccountInfo/AccountInfo";

import styles from "./styles.module.scss";

interface BankAccountDetailsProps {
  bankAccount: BankAccount;
  isCapital?: boolean;
}

const BankAccountDetails: React.FC<BankAccountDetailsProps> = ({
  bankAccount,
  isCapital = false,
}) => {
  const { goToHome } = useCryptoCable();

  useEffect(() => {
    ampli.cclCryptoAddress({ referral: isCapital ? "capital" : "crypto" });
  }, []);

  const {
    sourceCurrency,
    sourceBankName,
    sourceBankAddress,
    sourceBankRoutingNumber,
    sourceBankRoutingAccount,
    accountHolderFirstName,
    accountHolderLastName,
  } = bankAccount;

  const holderName = `${accountHolderFirstName} ${accountHolderLastName}`;

  const onShare = () => {
    ampli.cclCryptoDataShared();

    Share.share({
      text: `Currency: ${sourceCurrency} \nAccount holder: ${holderName}  \nBank name: ${sourceBankName} \nBank address: ${sourceBankAddress} \nRouting number: ${sourceBankRoutingNumber} \nAccount type: Checking \nAccount number: ${sourceBankRoutingAccount}`,
    });
  };

  const onClose = () => {
    ampli.cclCryptoExit({ referral: "address" });
    goToHome();
  };

  return (
    <div
      className={classNames(styles.contentWrapper, {
        [styles.isCapital]: isCapital,
      })}
    >
      <>
        {!isCapital && (
          <PageHeader
            title="Recibí tus dólares en USDC"
            icon={X}
            onClick={onClose}
            className={styles.header}
          />
        )}
        <Banner
          className={styles.banner}
          variant={
            isCapital ? BannerVariants.INFO_CAPITAL : BannerVariants.INFO_CRYPTO
          }
          text="Únicamente podés recibir dólares de cuentas que estén a tu nombre."
        />
        <Text
          color="var(--slate800)"
          className={styles.title}
          variant={TextVariants.RegularText}
        >
          Datos bancarios
        </Text>
        <AccountInfo bankDetails={bankAccount} isCapital={isCapital} />
        <BulletList
          variant={
            isCapital ? BulletListVariants.Capital : BulletListVariants.Crypto
          }
          bullets={[
            {
              icon: Clock4,
              text: "Normalmente, demora 2 días hábiles",
            },
            {
              icon: BadgePercent,
              text: "Costo por transacción del 1%",
            },
            {
              icon: Banknote,
              text: (
                <>
                  Monto mínimo de depósito de <b>200 US$</b>
                </>
              ),
            },
            {
              icon: ArrowDownToLine,
              text: "Tarifa fija de 0,50 US$ por depósito ACH",
            },
          ]}
          className={styles.bulletList}
        />
      </>

      <Footer>
        <FilledButton
          onClick={onShare}
          variant={isCapital ? ThemeVariants.Capital : ThemeVariants.Crypto}
        >
          Compartir detalles
        </FilledButton>
      </Footer>
    </div>
  );
};

export default BankAccountDetails;
