import { useEffect, useRef } from "react";

import { ampli } from "ampli";
import PayIcon from "assets/icons/PayIcon";
import classNames from "classnames";
import FilledButton from "components/common/FilledButton";
import Footer from "components/common/Footer";
import OutlinedButton from "components/common/OutlinedButton";
import PageHeader from "components/common/PageHeader";
import { useCocosPay } from "context/CocosPayProvider";
import dayjs from "dayjs";
import { ThemeVariants } from "interfaces/theme";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { shareScreenshot } from "utils/share";
import PayReceiptDetail from "components/common/PayReceipt";

import styles from "./styles.module.scss";

const PayReceipt: React.FC = () => {
  const { receiptData } = useCocosPay();

  const {
    quantity,
    paymentMethod,
    businessName,
    idPaymentShort,
    createdAt,
    transactionQuantity,
    transactionCurrency,
    localQuantity,
    idBankTransaction,
  } = receiptData;

  const navigate = useNavigate();

  const shareRef = useRef<HTMLDivElement>(null);

  const shareId = "for-share";

  useEffect(() => {
    shareScreenshot(shareRef.current, shareId, `pago-${businessName}`);
  }, []);

  const onClickDownload = () => {
    ampli.payDownloadReceipt({
      amount: quantity,
      method: paymentMethod,
      paid_to: businessName,
      receipt_number: idPaymentShort,
      date: dayjs(createdAt).format("DD/MM/YYYY"),
      time: dayjs(createdAt).format("HH:mm"),
      referral: "receipt",
    });

    shareScreenshot(shareRef.current, shareId, `pago-${businessName}`);
  };

  const onClickExit = () => {
    ampli.payExit({ referral: "receipt" });
    navigate(-1);
  };

  return (
    <div className={classNames(styles.PayReceiptContainer, "capacitor")}>
      <PageHeader
        icon={X}
        iconColor="var(--black-to-white)"
        title="Comprobante"
        onClick={onClickExit}
        className={styles.header}
      />
      <div ref={shareRef} id={shareId} className={styles.forShare}>
        <div className={styles.wrapper}>
          <PayIcon color="var(--black-to-white)" />
          <PayReceiptDetail
            businessName={businessName.trim()}
            createdAt={createdAt}
            paymentMethod={paymentMethod}
            idPayment={idPaymentShort}
            idBankTransaction={idBankTransaction}
            transactionQuantity={transactionQuantity}
            transactionCurrency={transactionCurrency}
            localQuantity={localQuantity}
          />
        </div>
      </div>
      <Footer>
        <div className={styles.buttonsWrapper}>
          <FilledButton variant={ThemeVariants.Pay} onClick={onClickDownload}>
            Descargar comprobante
          </FilledButton>
          <OutlinedButton variant={ThemeVariants.Pay} onClick={onClickExit}>
            Volver al inicio
          </OutlinedButton>
        </div>
      </Footer>
    </div>
  );
};

export default PayReceipt;
