import * as React from "react";

import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import "./index.scss";

type Anchor = "top" | "left" | "bottom" | "right";

interface PropTypes {
  anchor?: Anchor;
  open: boolean;
  onToggleDisplay?: () => void;
  children: React.ReactChild | React.ReactChild[] | React.ReactNode;
  classes?: string; //adding this cause modals are placed outside the pages
  onClose?: () => void; //add this if you want a different function when closing modal.
}

export const UIModalSlider = (props: PropTypes) => {
  const {
    anchor = "bottom",
    children,
    classes,
    open,
    onClose,
    onToggleDisplay,
  } = props;

  const toggleDrawer = (close?: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    if (close && onClose) {
      onClose();
      return;
    } else {
      onToggleDisplay && onToggleDisplay();
    }
  };

  return (
    <SwipeableDrawer
      anchor={anchor}
      className={`ui-modal-slider ${classes || ""}`}
      open={open}
      onClose={toggleDrawer(true)}
      onOpen={toggleDrawer()}
    >
      <Box>
        <div className="rectangle" />
        <div className="slider-content">{children}</div>
      </Box>
    </SwipeableDrawer>
  );
};
