const AppleWallet: React.FC = () => (
  <svg
    width="29"
    height="20"
    viewBox="0 0 29 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M25.952 1.09766H3.147V16.9767H25.952V1.09766Z" fill="#DEDBCE" />
    <path
      d="M25.7088 1.35156H3.41006V10.8112H25.7088V1.35156Z"
      fill="#40A5D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7079 5.37644C25.7079 5.29979 25.7079 5.22409 25.7079 5.14745C25.7079 5.0831 25.7069 5.01876 25.705 4.95441C25.7012 4.81437 25.6927 4.67338 25.6681 4.53522C25.6426 4.39518 25.6019 4.2646 25.5366 4.13685C25.4732 4.01195 25.3899 3.89651 25.2896 3.79715C25.1903 3.69779 25.0758 3.61452 24.9499 3.55018C24.8222 3.48489 24.6916 3.4442 24.5515 3.41865C24.4134 3.39405 24.2724 3.38553 24.1323 3.38175C24.068 3.37985 24.0036 3.37891 23.9393 3.37891C23.8627 3.37891 23.787 3.37891 23.7103 3.37891H23.6479H6.28201H5.40671C5.33007 3.37891 5.25436 3.37891 5.17772 3.37891C5.11337 3.37891 5.04903 3.37985 4.98468 3.38175C4.84463 3.38553 4.70364 3.39405 4.56548 3.41865C4.42544 3.4442 4.29485 3.48489 4.16711 3.55018C4.0422 3.61358 3.92676 3.69685 3.8274 3.79715C3.72804 3.89651 3.64477 4.011 3.58042 4.13685C3.51513 4.2646 3.47444 4.39518 3.44889 4.53522C3.42429 4.67338 3.41577 4.81437 3.41199 4.95441C3.41009 5.01876 3.40915 5.0831 3.40915 5.14745C3.40915 5.22409 3.40915 5.29979 3.40915 5.37644V6.25172V5.5638V12.8395H25.7079V5.60922V5.37644Z"
      fill="#FFB003"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7079 7.40378C25.7079 7.32714 25.7079 7.25144 25.7079 7.17479C25.7079 7.11045 25.707 7.0461 25.7051 6.98176C25.7013 6.84171 25.6928 6.70072 25.6682 6.56257C25.6426 6.42252 25.6019 6.29194 25.5367 6.1642C25.4733 6.03929 25.39 5.92385 25.2897 5.82449C25.1903 5.72514 25.0758 5.64187 24.95 5.57752C24.8222 5.51223 24.6916 5.47154 24.5516 5.44599C24.4134 5.42139 24.2724 5.41287 24.1324 5.40909C24.0681 5.4072 24.0037 5.40625 23.9394 5.40625C23.8627 5.40625 23.787 5.40625 23.7104 5.40625H23.647H6.28207H5.40677C5.33013 5.40625 5.25443 5.40625 5.17778 5.40625C5.11343 5.40625 5.04909 5.4072 4.98474 5.40909C4.84469 5.41287 4.7037 5.42139 4.56555 5.44599C4.4255 5.47154 4.29491 5.51223 4.16717 5.57752C4.04226 5.64092 3.92682 5.72419 3.82746 5.82449C3.7281 5.92385 3.64483 6.03835 3.58048 6.1642C3.51519 6.29194 3.4745 6.42252 3.44895 6.56257C3.42435 6.70072 3.41583 6.84171 3.41205 6.98176C3.41016 7.0461 3.40921 7.11045 3.40921 7.17479C3.40921 7.25144 3.40921 7.32714 3.40921 7.40378V8.27906V7.59114V14.8669H25.7079V7.63656V7.40378Z"
      fill="#40C740"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7079 9.43113C25.7079 9.35448 25.7079 9.27878 25.7079 9.20213C25.7079 9.13779 25.7069 9.07344 25.705 9.0091C25.7012 8.86905 25.6927 8.72806 25.6681 8.58991C25.6426 8.44987 25.6019 8.31928 25.5366 8.19154C25.4732 8.06663 25.3899 7.95119 25.2896 7.85184C25.1903 7.75248 25.0758 7.66921 24.9499 7.60486C24.8222 7.53957 24.6916 7.49888 24.5515 7.47334C24.4134 7.44873 24.2724 7.44022 24.1323 7.43643C24.068 7.43454 24.0036 7.43359 23.9393 7.43359C23.8627 7.43359 23.787 7.43359 23.7103 7.43359H23.6469H6.28201H5.40671C5.33007 7.43359 5.25436 7.43359 5.17772 7.43359C5.11337 7.43359 5.04903 7.43454 4.98468 7.43643C4.84463 7.44022 4.70364 7.44873 4.56548 7.47334C4.42544 7.49888 4.29485 7.53957 4.16711 7.60486C4.0422 7.66826 3.92676 7.75153 3.8274 7.85184C3.72804 7.95119 3.64477 8.06569 3.58042 8.19154C3.51513 8.31928 3.47444 8.44987 3.44889 8.58991C3.42429 8.72806 3.41577 8.86905 3.41199 9.0091C3.41009 9.07344 3.40915 9.13779 3.40915 9.20213C3.40915 9.27878 3.40915 9.35448 3.40915 9.43113V10.3064V9.61848V16.8942H25.7079V9.6639V9.43113Z"
      fill="#F26D5F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.50979 0C2.70844 0 2.05881 0.649629 2.05881 1.45099V9.79746C2.05881 10.1706 2.3613 10.4731 2.73444 10.4731C3.10758 10.4731 3.41007 10.1706 3.41007 9.79746V4.22311V3.34783C3.41007 3.27118 3.41007 3.19549 3.41007 3.11884C3.41007 3.05449 3.41102 2.99015 3.41291 2.9258C3.4167 2.78576 3.42521 2.64477 3.44982 2.50662C3.47536 2.36657 3.51605 2.23599 3.58135 2.10824C3.64475 1.98334 3.72802 1.8679 3.82832 1.76854C3.92768 1.66918 4.04218 1.58591 4.16803 1.52157C4.29483 1.45817 4.42541 1.41654 4.56546 1.39193C4.70362 1.36733 4.84461 1.35881 4.98466 1.35503C5.049 1.35314 5.11335 1.35219 5.17769 1.35219C5.25434 1.35219 5.33004 1.35219 5.40669 1.35219H6.28198H23.645H23.7122C23.7888 1.35219 23.8645 1.35219 23.9412 1.35219C24.0055 1.35219 24.0699 1.35314 24.1342 1.35503C24.2743 1.35881 24.4152 1.36733 24.5534 1.39193C24.6935 1.41748 24.824 1.45817 24.9518 1.52346C25.0767 1.58686 25.1921 1.67013 25.2915 1.77043C25.3908 1.86979 25.4741 1.98429 25.5385 2.11014C25.6038 2.23788 25.6444 2.36846 25.67 2.50851C25.6946 2.64666 25.7031 2.78765 25.7069 2.9277C25.7088 2.99204 25.7097 3.05639 25.7097 3.12073C25.7097 3.19738 25.7097 3.27308 25.7097 3.34972V4.225V9.79935C25.7097 10.1725 26.0122 10.475 26.3854 10.475C26.7585 10.475 27.061 10.1725 27.061 9.79935V1.45099C27.061 0.64963 26.4114 0 25.61 0H3.50979Z"
      fill="#D9D6CC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7987 9.03516C20.6539 9.03516 20.5081 9.03516 20.3634 9.0361C20.2413 9.03705 20.1192 9.03799 19.9972 9.04178C19.7313 9.04935 19.4625 9.06449 19.1995 9.1118C18.9326 9.16006 18.6838 9.2386 18.4415 9.36161C18.4103 9.3777 17.7432 9.6805 17.1432 10.6135C16.6881 11.3222 15.8024 12.0736 14.5618 12.0736C13.3222 12.0736 12.4365 11.3222 11.9804 10.6135C11.3493 9.63035 10.6291 9.33512 10.6812 9.36161C10.4389 9.23765 10.1901 9.15912 9.92323 9.1118C9.66017 9.06449 9.39144 9.0484 9.12554 9.04178C9.00347 9.03894 8.8814 9.03705 8.75933 9.0361C8.61455 9.03516 8.46883 9.03516 8.32405 9.03516H2.0787V18.4948H27.08V9.03516H20.7987Z"
      fill="#DEDBCE"
    />
  </svg>
);

export default AppleWallet;
