import { useEffect, useState } from "react";

import BasicWrapper from "components/common/BasicWrapper";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { Currencies } from "interfaces/wallet";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getWallet } from "features/wallet/walletSlice";
import TransactionInput from "components/common/TransactionInput";
import Banner, { BannerVariants } from "components/common/Banner";
import { ampli } from "ampli";
import API from "apis";
import { ordersService } from "apis/services";
import LoadingSpinner from "components/common/LoadingSpinner";

import DonationBottomsheet from "./DonationBottomsheet";
import Failure from "./Failure";
import Success from "./Success";

import styles from "./styles.module.scss";

enum DonationScreens {
  DONATE,
  ERROR,
  SUCCESS,
}

const DonatePage: React.FC = () => {
  const navigate = useNavigate();
  const [donationAmount, setDonationAmount] = useState<number>(0);
  const [donationScreen, setDonationScreen] = useState<DonationScreens>(
    DonationScreens.DONATE
  );
  const [isDonationSheetOpen, setIsDonationSheetOpen] = useState<boolean>(
    false
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const wallet = useAppSelector((state) => state.wallet.wallet);

  const onClickDonate = () => {
    setIsDonationSheetOpen(true);
  };

  const onConfirmDonation = async () => {
    ampli.donationOrderConfirmed({ amount: donationAmount });

    try {
      setIsLoading(true);
      await API.post(ordersService.donations, {
        amount: donationAmount,
      });
      setDonationScreen(DonationScreens.SUCCESS);
    } catch (error) {
      setDonationScreen(DonationScreens.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getWallet());
    ampli.donationScreenViewed();
  }, []);

  const insufficientFunds =
    !wallet || donationAmount <= 0 || wallet?.CI.ars < donationAmount;

  if (isLoading)
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner size={150} />
      </div>
    );

  if (donationScreen === DonationScreens.ERROR)
    return <Failure attemptedDonationAmount={donationAmount} />;

  if (donationScreen === DonationScreens.SUCCESS)
    return <Success donatedAmount={donationAmount} />;

  return (
    <BasicWrapper
      navigationHeaderProps={{
        leftIcon: <X />,
        onClick: () => navigate(mainRoutes.home),
        title: "Donación",
      }}
      primaryButtonProps={{
        children: "Donar",
        onClick: onClickDonate,
        disabled: insufficientFunds,
      }}
    >
      <Banner
        variant={BannerVariants.INFO_CAPITAL}
        text="Donando a la Fundación Sí, colaborás con la compra de materiales para la reconstrucción de más de 100 hogares perdidos en los incendios en el Bolsón."
      />
      <TransactionInput
        onChange={(value) => setDonationAmount(value)}
        balance={wallet?.CI.ars}
        currency={Currencies.ARS}
      />
      <DonationBottomsheet
        amount={donationAmount}
        isOpen={isDonationSheetOpen}
        onConfirm={onConfirmDonation}
        setIsOpen={setIsDonationSheetOpen}
      />
    </BasicWrapper>
  );
};

export default DonatePage;
