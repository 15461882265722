import { useEffect, useState } from "react";

import API from "apis";
import { App } from "@capacitor/app";
import { lt, valid } from "semver";
import { useSelector } from "react-redux";
import { cardService } from "apis/services";
import getPlatform from "utils/getPlatform";
import { useCocosCard } from "context/CocosCardProvider";
import { getLoggedUser } from "store/selectors/user.selector";
import { PushProvisioningCapacitorPlugin } from "@cocos-capital/push-provisioning-apple";
import AppleWalletButton from "components/common/AppleWalletButton";

interface AppleWalletActivationProps {
  idCard: string;
  cardLastFour: string;
}

const AppleWalletActivation: React.FC<AppleWalletActivationProps> = ({
  idCard,
  cardLastFour,
}) => {
  const { pomeloData, getPomeloData, setIsError } = useCocosCard();
  const { isIOS, isNative } = getPlatform();
  const user = useSelector(getLoggedUser);

  const [isAvailable, setIsAvailable] = useState(false);
  const [isPaired, setIsPaired] = useState(false);
  const [appVersion, setAppVersion] = useState<string>("");

  const targetVersion = "2.1.0";

  const getAppVersion = async () => {
    const { version } = await App.getInfo();

    return setAppVersion(version);
  };

  const checkAvailability = async () => {
    const { available } = await PushProvisioningCapacitorPlugin.isAvailable();
    setIsAvailable(available);

    const { paired } = await PushProvisioningCapacitorPlugin.isPaired({
      cardLastFour,
    });
    setIsPaired(paired);
  };

  useEffect(() => {
    if (isNative && isIOS) {
      checkAvailability();
    }
  }, [cardLastFour, idCard, pomeloData]);

  useEffect(() => {
    if (isNative) {
      getAppVersion();
    }
  }, []);

  if (
    !isAvailable ||
    !isIOS ||
    isPaired ||
    (valid(appVersion) && lt(appVersion, targetVersion))
  ) {
    return null;
  }

  const onClickButton = async () => {
    const {
      certificates,
      nonce,
      nonceSignature,
    } = await PushProvisioningCapacitorPlugin.startEnroll({
      cardHolder: `${user?.first_name} ${user?.last_name}` || "",
      cardLastFour,
    });

    try {
      const {
        data: {
          data: { activation_data, encrypted_pass_data, ephemeral_public_key },
        },
      } = await API.post(cardService.applePayActivation, {
        certificates,
        nonce,
        nonce_signature: nonceSignature,
        card_id: idCard,
      });

      await PushProvisioningCapacitorPlugin.completeEnroll({
        activationData: activation_data,
        encryptedPassData: encrypted_pass_data,
        ephemeralPublicKey: ephemeral_public_key,
      });
    } catch (error) {
      setIsError(true);
    } finally {
      getPomeloData();
    }
  };

  return <AppleWalletButton onClick={onClickButton} />;
};

export default AppleWalletActivation;
