import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { useDeviceCheck } from "hooks/useDeviceCheck";
import {
  MOBILE_SUBSECTIONS,
  PROFILE_SECTIONS,
  ProfileSection,
} from "interfaces/profile";
import {
  Crown,
  GraduationCap,
  Landmark,
  MessageCircleQuestion,
  Scale,
  Settings,
  ShieldCheck,
  User2,
  UserRoundCheck,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";

export const SECTIONS: ProfileSection[] = [
  {
    icon: User2,
    title: PROFILE_SECTIONS.PERSONAL_DATA,
    detail: "Consultá la información de tu cuenta",
    path: mainRoutes.personalData,
    subSection: MOBILE_SUBSECTIONS.ACCOUNT,
    showMobile: true,
    showDesktop: true,
  },
  {
    icon: UserRoundCheck,
    title: PROFILE_SECTIONS.INVERSOR_TEST,
    detail: "Conocé tu perfil de inversor",
    path: mainRoutes.profileTest,
    subSection: MOBILE_SUBSECTIONS.ACCOUNT,
    showMobile: true,
    showDesktop: false,
  },
  {
    icon: MessageCircleQuestion,
    title: PROFILE_SECTIONS.HELP,
    detail: "Resolvé todas tus dudas o consultas",
    path: mainRoutes.help,
    subSection: MOBILE_SUBSECTIONS.HELP,
    showMobile: true,
    showDesktop: true,
  },
  {
    icon: Landmark,
    title: PROFILE_SECTIONS.BANK_ACCOUNTS,
    detail: "Gestioná todas tus cuentas",
    path: mainRoutes.accounts,
    subSection: MOBILE_SUBSECTIONS.ACCOUNT,
    showMobile: true,
    showDesktop: false,
  },
  {
    icon: GraduationCap,
    title: PROFILE_SECTIONS.LEARN,
    detail: "Iniciate en el mundo de las inversiones",
    path: mainRoutes.profileLearn,
    subSection: MOBILE_SUBSECTIONS.HELP,
    showMobile: true,
    showDesktop: true,
  },
  {
    icon: ShieldCheck,
    title: PROFILE_SECTIONS.SECURITY,
    detail: "Hacé tu experiencia más segura",
    path: mainRoutes.security,
    subSection: MOBILE_SUBSECTIONS.ACCOUNT,
    showMobile: true,
    showDesktop: true,
  },
  {
    icon: Settings,
    title: PROFILE_SECTIONS.CONFIG,
    detail: "Seleccioná todas tus preferencias",
    path: mainRoutes.profileConfig,
    subSection: MOBILE_SUBSECTIONS.ACCOUNT,
    showMobile: true,
    showDesktop: true,
  },
  {
    icon: Scale,
    title: PROFILE_SECTIONS.LEGALS,
    detail: "Conocé nuestros términos y condiciones",
    path: mainRoutes.profileLegals,
    subSection: MOBILE_SUBSECTIONS.HELP,
    showMobile: true,
    showDesktop: true,
  },
  {
    icon: Crown,
    title: PROFILE_SECTIONS.GOLD,
    detail: "Conocé todos los beneficios y tarifas",
    path: mainRoutes.cocosGold,
    subSection: MOBILE_SUBSECTIONS.ACCOUNT,
    showMobile: false,
    showDesktop: false,
  },
];

interface Context {
  sections: ProfileSection[];
  selectedSection: ProfileSection;
  onClickSection: (section: ProfileSection) => void;
  goldSection: ProfileSection | undefined;
  breadcrumbList: string[];
  addBreadcrumbOption: (v: string) => void;
  onClickBreadcrumb: (v: string) => void;
  zendeskOpen: boolean;
  setZendeskOpen: (v: boolean) => void;
}

const ProfileContext = createContext<Context | null>(null);

export const ProfileProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceCheck();

  const sections = SECTIONS.filter((section) =>
    isMobile ? section.showMobile : section.showDesktop
  );

  const goldSection = SECTIONS.find(
    (section) => section.title === PROFILE_SECTIONS.GOLD
  );

  const [selectedSection, setSelectedSection] = useState<ProfileSection>(
    sections[0]
  );

  const [breadcrumbList, setBreadcrumbList] = useState<string[]>([]);

  const [zendeskOpen, setZendeskOpen] = useState<boolean>(false);

  const onClickSection = (section: ProfileSection) => {
    setSelectedSection(section);
    setBreadcrumbList(
      section.title !== PROFILE_SECTIONS.PERSONAL_DATA ? [section.title] : []
    );
    if (isMobile) {
      navigate(section.path);
      return;
    }
  };

  const onClickBreadcrumb = (value: string) => {
    const index = breadcrumbList.indexOf(value);

    const newList = breadcrumbList.slice(0, index + 1);

    setBreadcrumbList(newList);
  };

  const addBreadcrumbOption = (value: string) => {
    setBreadcrumbList([...breadcrumbList, value]);
  };

  const memoizedValues = useMemo(() => {
    return {
      sections,
      selectedSection,
      onClickSection,
      goldSection,
      breadcrumbList,
      addBreadcrumbOption,
      onClickBreadcrumb,
      zendeskOpen,
      setZendeskOpen,
    };
  }, [
    sections,
    selectedSection,
    onClickSection,
    goldSection,
    breadcrumbList,
    addBreadcrumbOption,
    onClickBreadcrumb,
    zendeskOpen,
    setZendeskOpen,
  ]);

  return (
    <ProfileContext.Provider value={memoizedValues}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileContext;

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("[ProfileContext] Missing context");
  }
  return context;
};
