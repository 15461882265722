import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import useKeyboardHeight from "hooks/useKeyboardHeight";

import styles from "./styles.module.scss";

interface FooterProps {
  children: React.ReactNode;
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ children, className }) => {
  const { isIOS } = getPlatform();

  const { keyboardHeight } = useKeyboardHeight();

  return (
    <div
      className={classNames(
        styles.footerWrapper,
        {
          [styles.ios]: isIOS,
          [styles.keyboardOpen]: keyboardHeight,
        },
        className
      )}
      style={{
        bottom: `${isIOS ? 0 : keyboardHeight}px`,
        transition: "0.2s",
      }}
    >
      {children}
    </div>
  );
};

export default Footer;
