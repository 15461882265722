import ARGFlag from "assets/icons/ARGFlag";
import { UIModalSlider } from "components/common/modal-slider";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import Text, { TextVariants } from "components/common/Text";
import px2rem from "utils/px2rem";
import OutlinedButton from "components/common/OutlinedButton";
import FilledButton from "components/common/FilledButton";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface DonationBottomsheetProps {
  amount: number;
  isOpen: boolean;
  onConfirm: () => void;
  setIsOpen: (isOpen: boolean) => void;
}

const DonationBottomsheet: React.FC<DonationBottomsheetProps> = ({
  isOpen,
  amount,
  setIsOpen,
  onConfirm,
}) => {
  const { isDesktop } = useDeviceCheck();
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <UIModalSlider
      open={isOpen}
      onToggleDisplay={onClose}
      classes={classNames({ [styles.sheet]: isDesktop })}
    >
      <div className={styles.sheetWrapper}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          Confirmá tu donación
        </Text>
        <ARGFlag size={px2rem(32)} />
        <PriceLabel price={amount} size={PriceLabelSizes.Default} />
        <div className={styles.buttonsContainer}>
          <OutlinedButton onClick={onClose}>Volver</OutlinedButton>
          <FilledButton onClick={onConfirm}>Confirmar</FilledButton>
        </div>
      </div>
    </UIModalSlider>
  );
};

export default DonationBottomsheet;
