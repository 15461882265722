import ff from "constants/featureFlags";
import { cryptoStatus } from "constants/cryptos";

import { useState } from "react";

import { Typography } from "@mui/material";
import { ArrowDown } from "assets/icons/arrow-down-circle";
import { ArrowUpIcon as ArrowUp } from "assets/icons/arrow-up-circle";
import { InversionIcon } from "assets/icons/inversion-icon";
import { MepIcon } from "assets/icons/mep-icon";
import GoToOrdersButton from "components/common/GoToOrdersButton";
import NoCtte from "components/common/NoCtte";
import { BoxTypes, UIBox } from "components/common/box";
import MarketsModal from "components/common/markets-modal";
import { HeaderHome } from "components/page-home/header";
import MEPSelector from "components/common/MEPSelector";
import AcademyHome from "components/page-home/academy";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useFeatureFlag from "hooks/useFeatureFlag";
import { trackAction } from "utils/amplitude";
import { screenNames } from "router/routes";
import { openRightModal } from "features/rightModal/rightModalSlice";
import { useAppState } from "context/AppStateProvider";
import DonatePage from "components/page-donate";

import "./index.scss";

interface PropTypes {
  onClickDepositar: () => void;
  onClickExtraer: () => void;
  onClickInvestmentSuggestion: () => void;
  onClickBid: () => void;
}
export const HomeDesktop = ({
  onClickDepositar,
  onClickExtraer,
  onClickInvestmentSuggestion,
  onClickBid,
}: PropTypes) => {
  const { isFeatureEnabled: isBidEnabled } = useFeatureFlag(ff.ENABLE_BID);
  const { isFeatureEnabled: isDonationEnabled } = useFeatureFlag(
    ff.ENABLE_DONATION
  );

  const isCompactMode = false;
  const darkMode = useAppSelector((state) => state.user.user?.dark_theme);
  const status_crypto = useAppSelector(
    (state) => state.user.user?.status_crypto
  );
  const instrument = useAppSelector(
    (state) => state.markets.investmentSuggested
  )[0];

  const [isMEPOpen, setIsMepOpen] = useState<boolean>(false);
  const { openRightModalContent } = useAppState();

  const isDonationVisible =
    isDonationEnabled && status_crypto === cryptoStatus.APPROVED;

  const handleClickMep = () => {
    trackAction(`${screenNames.home} - Click Dolar Mep`);
    setIsMepOpen(true);
  };
  const dispatch = useAppDispatch();

  const handleClickDonate = () => {
    openRightModalContent(<DonatePage />);
    dispatch(openRightModal());
  };

  return (
    <div className={`home-desktop ${isCompactMode ? "compact" : "wide"}`}>
      <div className="page-home-content">
        <HeaderHome />
        <div className="buttons-actions">
          <UIBox
            className="depositar"
            type={BoxTypes.ClickableXL}
            onClick={onClickDepositar}
          >
            <ArrowDown color={darkMode ? "#accefa" : "#0062e1"} size={30} />
            <Typography>Depositar</Typography>
          </UIBox>
          <UIBox
            className="extraer"
            type={BoxTypes.ClickableXL}
            onClick={onClickExtraer}
          >
            <ArrowUp color={darkMode ? "#accefa" : "#0062e1"} size={30} />
            <Typography>Extraer</Typography>
          </UIBox>
          <UIBox
            className="dolar-mep"
            type={BoxTypes.ClickableXL}
            onClick={handleClickMep}
          >
            <MepIcon color={darkMode ? "#accefa" : "#0062e1"} />
            <Typography>Dólar MEP</Typography>
          </UIBox>
          {isDonationVisible && (
            <UIBox
              className="new"
              type={BoxTypes.ClickableXL}
              onClick={handleClickDonate}
            >
              <MepIcon color={darkMode ? "#accefa" : "#0062e1"} />
              <Typography>Donar</Typography>
            </UIBox>
          )}
          {isBidEnabled && (
            <UIBox
              className="inversion-sugerida new"
              type={BoxTypes.ClickableXL}
              onClick={onClickBid}
            >
              <InversionIcon color={darkMode ? "#accefa" : "#0062e1"} />
              <Typography>Licitación</Typography>
            </UIBox>
          )}
          {instrument && (
            <UIBox
              className="inversion-sugerida new"
              type={BoxTypes.ClickableXL}
              onClick={onClickInvestmentSuggestion}
            >
              <InversionIcon color={darkMode ? "#accefa" : "#0062e1"} />
              <Typography>Inversión destacada</Typography>
            </UIBox>
          )}
        </div>
        <NoCtte />
        <GoToOrdersButton />
        <div className="home-market-data">
          <MarketsModal />
        </div>
        <div className="white-container">
          <AcademyHome />
        </div>
      </div>
      <MEPSelector onClose={() => setIsMepOpen(false)} isOpen={isMEPOpen} />
    </div>
  );
};
