import { CDN_URL } from "constants/index";

import CocosBrand, { CocosBrandVariants } from "assets/icons/CocosBrand";
import px2rem from "utils/px2rem";
import Text, { TextVariants } from "components/common/Text";
import USFlag from "assets/icons/USFlag";
import { Instruments } from "interfaces/markets";

import styles from "./styles.module.scss";

interface LogoProps {
  isCrypto: boolean;
  ticker: string;
  logo: string | null;
  type?: string;
}

const Logo: React.FC<LogoProps> = ({ isCrypto, logo, ticker, type }) => {
  const getImageLogo = (img: string | null) => {
    if (!img) return `${CDN_URL}/logos/ARG.jpg`;

    return `${CDN_URL}/logos/${img}`;
  };

  return (
    <div className={styles.logoContainer}>
      {isCrypto ? (
        <CocosBrand variant={CocosBrandVariants.CRYPTO} size={px2rem(24)} />
      ) : (
        <div className={styles.logo}>
          {type === Instruments.LETRA_INTERNACIONAL ? (
            <USFlag />
          ) : (
            <img src={getImageLogo(logo)} alt="logo de asset portfolio card" />
          )}
        </div>
      )}
      <Text
        color="var(--slate900)"
        className={styles.text}
        variant={TextVariants.RegularTextS}
      >
        {ticker}
      </Text>
    </div>
  );
};

export default Logo;
