import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import Currency, { CurrencySizes } from "components/common/Text/Currency";
import {
  getFCIIconColor,
  getIcon,
  getPaymentCurrencyLabel,
  getPaymentCurrencySymbol,
  isUSD,
} from "components/page-cocos-pay/utils";
import { PaymentCurrencies } from "interfaces/pay/enums";
import { Currencies } from "interfaces/wallet";
import { ChevronRight } from "lucide-react";
import { formatToCurrency } from "utils";
import px2rem from "utils/px2rem";
import OverlappedIcons from "components/common/OverlappedIcons";
import USDTIcon from "assets/icons/USDT";
import USDCIcon from "assets/icons/USDC";
import DAIIcon from "assets/icons/DAI";
import { useCocosPay } from "context/CocosPayProvider";

import styles from "./styles.module.scss";

interface MethodCardProps {
  currency: PaymentCurrencies;
  availableArs: number;
  availableUsd?: number;
  amount: number;
  price?: number;
  onClick?: (currency: PaymentCurrencies) => void;
  disabled?: boolean;
}

const MethodCard: React.FC<MethodCardProps> = ({
  currency,
  availableArs,
  availableUsd,
  amount,
  price = 0,
  onClick,
  disabled = false,
}) => {
  const { paymentData } = useCocosPay();

  const { transactionCurrency } = paymentData;

  const isBR = transactionCurrency === Currencies.BRL;

  const slate900 = "var(--slate900)";
  const slate800 = "var(--slate800)";

  const isUSDCurrency = isUSD(currency);

  const balance = isUSDCurrency ? availableUsd : availableArs;

  const paymentCurrency = isUSDCurrency ? Currencies.USD : Currencies.ARS;

  const isCurrency = [PaymentCurrencies.ARS, PaymentCurrencies.USD].includes(
    currency
  );

  const amountToPay = isCurrency ? amount : price * amount;

  const Icon = getIcon(currency);

  const isCryptoOverlapped = currency === PaymentCurrencies.STABLECOIN;

  const handleOnClick = () => {
    if (disabled) return;
    onClick && onClick(currency);
  };

  return (
    <div
      className={classNames(styles.methodCardContainer, {
        [styles.disabled]: disabled,
      })}
      onClick={handleOnClick}
    >
      <div className={styles.currency}>
        {isCryptoOverlapped ? (
          <OverlappedIcons icons={[USDTIcon, USDCIcon, DAIIcon]} />
        ) : (
          <Icon size={px2rem(32)} color={getFCIIconColor(currency)} />
        )}
        <div className={styles.info}>
          <Text variant={TextVariants.RegularText} color={slate900}>
            {getPaymentCurrencyLabel(currency)}
          </Text>
          <Text variant={TextVariants.RegularTextS} color={slate800}>
            {getPaymentCurrencySymbol(currency)}{" "}
            <Text
              variant={TextVariants.SemiboldUpperValueS}
              color={slate800}
              component="span"
            >
              {formatToCurrency(balance || 0, false, false, 2)}
            </Text>
            {" disponible"}
          </Text>
        </div>
      </div>
      {(isUSDCurrency || isBR) && !disabled && (
        <div className={styles.rate}>
          <div className={styles.equal}>
            <Text variant={TextVariants.RegularUpperValueS} color={slate800}>
              ≈&nbsp;
            </Text>
            <Currency
              size={CurrencySizes.SMALL}
              color={slate900}
              amount={amountToPay}
              ticker={paymentCurrency}
            />
          </div>
          <ChevronRight size={px2rem(16)} color={slate800} />
        </div>
      )}
    </div>
  );
};

export default MethodCard;
