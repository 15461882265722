import { useEffect, useState } from "react";

import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { SafeArea } from "capacitor-plugin-safe-area";
import getPlatform from "utils/getPlatform";

const useKeyboardHeight = (footerHeight?: number) => {
  const keyboardInitialState = 0;

  const [keyboardHeight, setKeyboardHeight] = useState(keyboardInitialState);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener("keyboardWillShow", (info) => {
        SafeArea.getSafeAreaInsets().then(({ insets }) => {
          setKeyboardHeight(info.keyboardHeight - insets.bottom);
        });
      });

      Keyboard.addListener("keyboardWillHide", () => {
        setKeyboardHeight(keyboardInitialState);
      });

      return () => {
        Keyboard.removeAllListeners();
      };
    }
  }, []);

  return {
    keyboardHeight,
    extraSpace: getExtraSpace(keyboardHeight, footerHeight),
  };
};

export default useKeyboardHeight;

const getExtraSpace = (keyboardHeight: number, footerHeight: number = 0) => {
  const { isIOS } = getPlatform();
  if (!keyboardHeight) return 0;

  if (isIOS) return footerHeight;

  return keyboardHeight + footerHeight;
};
