import { useEffect, useState } from "react";

import { OrderType } from "interfaces/markets";
import { getWallet } from "features/wallet/walletSlice";
import { getDolarMep } from "features/markets/marketsActions";
import {
  clearDolarOrder,
  setCreateOrderError,
} from "features/markets/marketsSlice";
import { checkMarketOpen } from "store/selectors/markets.selector";
import { Schedules } from "interfaces/calendar";
import { useAppSelector, useAppDispatch } from "hooks/redux";

import CreateOrder from "./CreateOrder";
import Success from "./Success";
import Failure from "./Failure";

export enum MepSteps {
  CreateOrder,
  Success,
  Failure,
}

interface MepProps {
  isFixed: boolean;
}

const Mep: React.FC<MepProps> = ({ isFixed }) => {
  const dispatch = useAppDispatch();

  const isOvernight = checkMarketOpen(Schedules.OVERNIGHT);

  const [amount, setAmount] = useState<string>("");
  const [orderSide, setOrderSide] = useState<OrderType>(OrderType.Buy);
  const [currentStep, setCurrentStep] = useState<MepSteps>(
    MepSteps.CreateOrder
  );
  const [convertedAmount, setConvertedAmount] = useState<number>(0);

  const { dolarMep } = useAppSelector((state) => state.markets);

  const isBuy = orderSide === OrderType.Buy;

  const getMepPrice = () => {
    if (isBuy) {
      if (isFixed) {
        return isOvernight ? dolarMep.overnight.ask : dolarMep.close.ask;
      }

      return dolarMep.open.ask;
    }

    if (isFixed) {
      return isOvernight ? dolarMep.overnight.bid : dolarMep.close.bid;
    }

    return dolarMep.open.bid;
  };

  const mepPrice = getMepPrice();

  useEffect(() => {
    dispatch(setCreateOrderError({ message: "", status: null }));
    dispatch(clearDolarOrder());
    dispatch(getDolarMep());
    dispatch(getWallet());

    document.documentElement.classList.add("newStyles");

    return () => {
      document.documentElement.classList.remove("newStyles");
    };
  }, []);

  useEffect(() => {
    if (isBuy) {
      setConvertedAmount(Number(amount) / mepPrice);
      return;
    }

    setConvertedAmount(Number(amount) * mepPrice);
  }, [amount]);

  if (currentStep === MepSteps.CreateOrder)
    return (
      <CreateOrder
        amount={amount}
        isFixed={isFixed}
        mepPrice={mepPrice}
        orderSide={orderSide}
        setAmount={setAmount}
        setOrderSide={setOrderSide}
        setCurrentStep={setCurrentStep}
        convertedAmount={convertedAmount}
      />
    );

  if (currentStep === MepSteps.Success)
    return (
      <Success
        amount={amount}
        isFixed={isFixed}
        mepPrice={mepPrice}
        orderSide={orderSide}
        convertedAmount={convertedAmount}
      />
    );

  return (
    <Failure
      amount={amount}
      isFixed={isFixed}
      orderSide={orderSide}
      convertedAmount={convertedAmount}
    />
  );
};

export default Mep;
