import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { cryptoKeys } from "apis/queryKeyFactories";
import { cryptoService } from "apis/services";
import { AxiosError } from "axios";
import { CryptoTicker } from "interfaces/api-responses";

const ONE_MINUTE = 60000;

const fetchTickers = async () => {
  const { data } = await API.get<CryptoTicker[]>(cryptoService.tickers);

  return data;
};

export const useTickersQuery = () => {
  return useAppQuery<CryptoTicker[], AxiosError>({
    queryKey: () => cryptoKeys.tickers,
    queryFn: fetchTickers,
    options: {
      onError: (e: AxiosError) => {
        return e;
      },
      retry: false,
      refetchInterval: ONE_MINUTE,
      staleTime: ONE_MINUTE,
    },
  });
};
