import { COCOS_BIZ_ONBOARDING_URL } from "constants/index";

import CocosBiz from "assets/icons/CocosBiz";
import Text, { TextVariants } from "components/common/Text";
import Heading, { HeadingVariants } from "components/common/Heading";
import Divider from "components/common/Divider";
import px2rem from "utils/px2rem";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Browser } from "@capacitor/browser";
import { ampli } from "ampli";

import styles from "./styles.module.scss";

const CocosBizBanner: React.FC = () => {
  const { isDesktop } = useDeviceCheck();
  const iconSize = isDesktop ? 68.4 : 57;

  const onClickBanner = () => {
    ampli.signupPjBannerClicked();
    Browser.open({ url: COCOS_BIZ_ONBOARDING_URL });
  };

  return (
    <div className={styles.banner}>
      <div className={styles.bannerWrapper} onClick={onClickBanner}>
        <div className={styles.textWrapper}>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            color="var(--slate900)"
            component="h3"
          >
            ¿Tenés una empresa?
          </Heading>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Abrí una cuenta como persona jurídica y potenciá el crecimiento de
            tu negocio.
          </Text>
        </div>
        <div>
          <CocosBiz size={px2rem(iconSize)} />
        </div>
      </div>
      <Divider orientation="horizontal" className={styles.divider} />
    </div>
  );
};

export default CocosBizBanner;
