import ARGFlag from "assets/icons/ARGFlag";
import CocosBrand from "assets/icons/CocosBrand";
import USFlag from "assets/icons/USFlag";
import { PayError, PaymentCurrencies } from "interfaces/pay/enums";

export const isUSD = (currency: PaymentCurrencies) => {
  return [PaymentCurrencies.USD, PaymentCurrencies.COCOAUSD].includes(currency);
};

export const getIcon = (currency: PaymentCurrencies) => {
  if (currency === PaymentCurrencies.USD) return USFlag;

  if (currency === PaymentCurrencies.ARS) return ARGFlag;

  return CocosBrand;
};

export const getFCIIconColor = (currency: PaymentCurrencies) => {
  if (currency === PaymentCurrencies.COCOAUSD) return "#3DD68C";

  return "#70B8FF";
};

export const getMessageError = (error?: PayError) => {
  if (error === PayError.ALREADY_PAID)
    return "Registramos que este pago ya fue aprobado. Avisale al cajero para evitar pagos duplicados.";
  if (error === PayError.INVALID_QR)
    return "Este tipo de QR no es válido para pagar con Cocos Pay.";
  if (error === PayError.PERMISSIONS_DENIED)
    return "Se han rechazado los permisos para acceder a la cámara.";
  if (error === PayError.UNSUPPORTED_DEVICE)
    return "Este dispositivo no está soportado para esta operación.";

  return "No pudimos escanear el código QR correctamente. Por favor, volvé a intentarlo más tarde.";
};

export const getPaymentCurrencyLabel = (paymentCurrency: PaymentCurrencies) => {
  const paymentCurrencyLabels = {
    [PaymentCurrencies.ARS]: "Pesos",
    [PaymentCurrencies.USD]: "Dólar MEP",
    [PaymentCurrencies.BRL]: "Reales",
    [PaymentCurrencies.COCOA]: "COCOA",
    [PaymentCurrencies.COCOAUSD]: "COCOAUSD",
    [PaymentCurrencies.COCORMA]: "COCORMA",
    [PaymentCurrencies.USDC]: "USDC",
    [PaymentCurrencies.USDT]: "USDT",
    [PaymentCurrencies.DAI]: "DAI",
    [PaymentCurrencies.STABLECOIN]: "Dólar crypto",
  };

  return paymentCurrencyLabels[paymentCurrency] || "";
};

export const getPaymentCurrencySymbol = (
  paymentCurrency: PaymentCurrencies
) => {
  if (isUSD(paymentCurrency)) return "US$";

  return "$";
};

export const MIN_USD_TO_PAY = 1;

export const MIN_BRL_TO_PAY = 5;

export const MAX_DEBT = 10000;
