export const getAnnualRate = (
  fci: string | undefined,
  variation?: number | null
): number => {
  const ANNUAL_RATE: Record<string, number> = {
    COCOA: 0.3,
    COCOAUSD: 0.0425,
    COCORMA: 0.325,
  };

  if (fci && ANNUAL_RATE[fci] !== undefined) {
    return ANNUAL_RATE[fci];
  }

  return variation ? variation : 0;
};
