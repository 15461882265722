import Heading, { HeadingVariants } from "components/common/Heading";
import { useOrders } from "apis/activity/queries/useOrders";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import { mockOrdersResponse, OrdersTypes } from "interfaces/orders/interface";
import CapitalActivityCard from "components/common/ActivityList/ActivityCard/CapitalActivityCard";
import { OrderStatus } from "interfaces/orders/enums";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import EmptyStateWithOnboarding from "components/common/EmptyStateWithOnboarding";
import mainRoutes from "router/routes";
import FirstInvestment from "assets/illustrations/FirstInvestment";
import { EmptyButtonTypes } from "components/common/EmptyState";
import { ThemeVariants } from "interfaces/theme";

import {
  getMainIcon,
  getOperationSideHeading,
  getSettlementDays,
  getSmallIcon,
  getStatusIcon,
} from "../../utils";
import type { FilterOrderType } from "..";

import styles from "./styles.module.scss";

interface ActivityListProps {
  filterStatus: FilterOrderType;
}

const ActivityList = ({ filterStatus }: ActivityListProps) => {
  const { data, isLoading } = useOrders();
  const [_, setSeachParams] = useSearchParams();
  const navigate = useNavigate();

  const filteredOrders = (orders: OrdersTypes[]) => {
    return orders.filter((item) => {
      if (filterStatus === OrderStatus.PENDING) {
        return (
          item.status === OrderStatus.PENDING ||
          item.status === OrderStatus.PARTIALLY_EXECUTED ||
          item.status === OrderStatus.PARTIALLY_CANCELLED
        );
      }
      if (filterStatus === OrderStatus.FILLED) {
        return item.status === OrderStatus.FILLED;
      }
      return item;
    });
  };

  const formatDateToSpanish = (dateString: string): string => {
    return dayjs(dateString).locale("es").format("D [de] MMM");
  };

  const onClickOrder = () => {
    setSeachParams({ id: "orderId" });
  };

  if (isLoading)
    return (
      <div className={styles.activitySkeletonContainer}>
        <Skeleton height={50} count={4} className={styles.activitySkeleton} />
      </div>
    );

  if (isEmpty(mockOrdersResponse))
    return (
      <EmptyStateWithOnboarding
        extraEmptyState
        title="Hacé una inversión"
        subtitle="Visitá el mercado para invertir en Cedears, Acciones, Bonos Públicos, ONs, Letras, Fondos de Inversión y más."
        buttonText="Ir al mercado"
        onClick={() => navigate(mainRoutes.market)}
        illustration={<FirstInvestment />}
        buttonType={EmptyButtonTypes.BUTTON}
        buttonVariant={ThemeVariants.Capital}
      />
    );

  return (
    <>
      {mockOrdersResponse.map(({ executionDate, orders }) => (
        <>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            color="var(--slate800)"
            className={styles.dateHeading}
            component="h2"
          >
            {formatDateToSpanish(executionDate)}
          </Heading>
          <div className={styles.activityContainer}>
            {filteredOrders(orders).map(
              (
                {
                  amount,
                  isMepOperation,
                  logo,
                  orderId,
                  quantity,
                  settlementDays,
                  side,
                  status,
                  source,
                  ticker,
                },
                index
              ) => (
                <div key={orderId} className={styles.activityCardRowContainer}>
                  <div>{getStatusIcon(status)}</div>
                  <div
                    className={classNames(styles.activityCardRow, {
                      [styles.borderRow]: index !== orders.length - 1,
                    })}
                  >
                    <CapitalActivityCard
                      amount={amount.value}
                      currency={amount.currency}
                      heading={getOperationSideHeading(side)}
                      isMepOperation={isMepOperation}
                      mainIcon={getMainIcon(isMepOperation, logo)}
                      onClick={onClickOrder}
                      operationSide={side}
                      quantity={quantity}
                      settlementDate={getSettlementDays(settlementDays)}
                      smallIcon={getSmallIcon(side, source)}
                      ticker={ticker}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </>
      ))}
    </>
  );
};

export default ActivityList;
