import { useState, useEffect } from "react";

import {
  CameraPreview,
  CameraPreviewPictureOptions,
} from "@capacitor-community/camera-preview";
import { hapticsVibrate } from "utils/haptics";
import { Glasses, Sun, Smartphone } from "lucide-react";
import IconBadge from "components/common/Badges/IconBadge";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { Projects, trackScreen, trackAction } from "utils/amplitude";
import { trackAnalyticsEvent, AnalyticsEvents } from "utils/firebaseAnalytics";
import { trackFbEvent, FbEvents } from "utils/fbTracking";
import { onboardingScreenNames } from "router/routes";
import { useCreateAccount } from "context/CreateAccountProvider";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import NavigationHeader from "components/common/NavigationHeader";
import FilledButton from "components/common/FilledButton";
import Stepper from "components/common/Stepper";
import {
  BASE64_PREFIX,
  OnboardingDocumentType,
  OnboardingImageFileName,
} from "components/page-create-account/constants";
import { sleep } from "utils/sleep";
import { CropType } from "components/page-create-account/utils/processImage";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import * as Sentry from "@sentry/react";

import Rejected from "../Final/Rejected";
import CameraVisorSelfie from "../../CameraVisorSelfie";
import LoadingScreen from "../../Loading";

import styles from "./styles.module.scss";

export enum Step {
  TRACKING_RIGHT = "TRACKING_RIGHT",
  TRACKING_LEFT = "TRACKING_LEFT",
  FINAL = "FINAL",
}

const Selfie = () => {
  const dispatch = useAppDispatch();
  const {
    goToHome,
    goToNextStep,
    uploadDocument,
    isLoading,
    setIsLoading,
    error,
    setError,
  } = useCreateAccount();

  const [image, setImage] = useState<string>("");
  const [trackingStep, setTrackingStep] = useState<string | null>(null);
  const [step, setStep] = useState<Step>(Step.TRACKING_RIGHT);
  const [isSuccessStatus, setIsSuccessStatus] = useState<boolean>(false);
  const [displayTimer, setDisplayTimer] = useState(false);
  const [displayCamera, setDisplayCamera] = useState(false);
  const [counterTimeout, setCounterTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const previousScreen = useAppSelector(getCurrentScreen);

  const startScan = async () => {
    try {
      await CameraPreview.start({
        parent: "cameraPreview",
        toBack: true,
        storeToFile: false,
        disableAudio: true,
        enableOpacity: true,
        disableExifHeaderStripping: false,
        position: "front",
        enableFaceRecognition: true,
        width: window.screen.width,
        height: window.screen.height,
        rotateWhenOrientationChanged: false,
        lockAndroidOrientation: true,
      });

      await CameraPreview.addListener("faceRecognized", async (result) => {
        setTrackingStep(result.step);
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          page: Projects.ONBOARDING,
          code: "FACE_SCAN_ERROR",
        },
      });
    }
  };

  useEffect(() => {
    if (displayCamera) {
      startScan();

      return () => {
        CameraPreview.removeAllListeners();
      };
    }
  }, [displayCamera]);

  useEffect(() => {
    if (displayCamera) {
      if (step === Step.TRACKING_RIGHT) {
        if (trackingStep === "RIGHT") {
          const timer = setTimeout(() => {
            hapticsVibrate();
            setIsSuccessStatus(true);
            trackAction("onboarding_selfie_turned_right");
            setTimeout(() => {
              setIsSuccessStatus(false);
              setStep(Step.TRACKING_LEFT);
            }, 1500);
          }, 400);

          setCounterTimeout(timer);
        } else {
          if (counterTimeout) clearTimeout(counterTimeout);
        }
      }

      if (step === Step.TRACKING_LEFT) {
        if (trackingStep === "LEFT") {
          const timer = setTimeout(() => {
            hapticsVibrate();
            setIsSuccessStatus(true);
            trackAction("onboarding_selfie_turned_left");
            setTimeout(() => {
              setIsSuccessStatus(false);
              setStep(Step.FINAL);
            }, 1500);
          }, 400);

          setCounterTimeout(timer);
        } else {
          if (counterTimeout) clearTimeout(counterTimeout);
        }
      }

      if (step === Step.FINAL) {
        if (trackingStep === "CENTER") {
          setDisplayTimer(true);

          const timer = setTimeout(async () => {
            takePhotoAndSend();
          }, 4500);

          setCounterTimeout(timer);
        } else {
          setDisplayTimer(false);
          if (counterTimeout) {
            clearTimeout(counterTimeout);
          }
        }
      }
    }
  }, [trackingStep, step, displayCamera]);

  useEffect(() => {
    trackAnalyticsEvent(AnalyticsEvents.HOME_EXITO_PASO4_PROCESO_COMITENTE);
    trackFbEvent(FbEvents.HOME_EXITO_PASO4_PROCESO_COMITENTE);

    trackScreen(
      onboardingScreenNames.selfie,
      previousScreen,
      undefined,
      Projects.ONBOARDING
    );

    dispatch(setCurrentScreen(onboardingScreenNames.selfie));
  }, []);

  const closeCamera = async () => {
    try {
      await CameraPreview.stop();
    } catch (e: any) {
      Sentry.captureException(e, {
        tags: {
          page: Projects.ONBOARDING,
          code: "CAMERA_STOP_ERROR",
        },
      });
    }
  };

  const takePhotoAndSend = async () => {
    try {
      trackAction(
        `${onboardingScreenNames.selfie} - Take Snapshot`,
        undefined,
        Projects.ONBOARDING
      );

      const result = await CameraPreview.capture(cameraPreviewPictureOptions);

      const capturedImage = `${BASE64_PREFIX}${result.value}`;
      setImage(capturedImage);

      await closeCamera();

      setIsSuccessStatus(true);
      hapticsVibrate();

      // Extra loading time to process onboarding result
      await sleep(2000);

      setIsLoading(true);

      await uploadDocument(
        OnboardingDocumentType.SELFIE,
        capturedImage,
        OnboardingImageFileName.SELFIE,
        CropType.SELFIE
      );

      trackAction(
        `${onboardingScreenNames.selfie} - Send selfie`,
        undefined,
        Projects.ONBOARDING
      );

      goToNextStep();
    } catch (err) {
      Sentry.captureException(err, {
        tags: {
          page: Projects.ONBOARDING,
          code: "CAMERA_TAKE_PHOTO_ERROR",
        },
      });
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const cameraPreviewPictureOptions: CameraPreviewPictureOptions = {
    quality: 100,
  };

  const onClose = async () => {
    if (!image && displayCamera) {
      await closeCamera();
    }

    trackAction(
      `${onboardingScreenNames.selfie} - Click Quit`,
      undefined,
      Projects.ONBOARDING
    );

    goToHome();
  };

  if (error) {
    return <Rejected isErrorState />;
  }

  const isCameraInitilizing = displayCamera && !trackingStep;

  if (isLoading || isCameraInitilizing) {
    return (
      <LoadingScreen
        displayText={!isCameraInitilizing}
        isSelfieStep={isCameraInitilizing}
      />
    );
  }

  return (
    <div id="cameraPreview" className={styles.cameraContainer}>
      {image && (
        <img
          src={image}
          className={styles.imagePreview}
          alt="Taken photo"
          style={{ transform: "scaleX(-1)" }}
        />
      )}
      <div className={styles.selfieContainer}>
        <div className={styles.content}>
          <NavigationHeader
            title="Reconocimiento facial"
            onClick={onClose}
            iconColor={displayCamera ? "#fff" : "var(--blue900)"}
            titleColor={displayCamera ? "#fff" : "var(--slate800)"}
          />
          <Stepper stepsCount={4} currentStep={3} />

          {displayCamera ? (
            <CameraVisorSelfie
              isTrackingRightStep={step === Step.TRACKING_RIGHT}
              isTrackingLeftStep={step === Step.TRACKING_LEFT}
              isFinalStep={step === Step.FINAL}
              isSuccess={isSuccessStatus}
              displayTimer={displayTimer}
            />
          ) : (
            <>
              <Heading
                variant={HeadingVariants.RegularTitle}
                component="h2"
                color="var(--slate900)"
              >
                Al continuar vamos a abrir la cámara para validar tu identidad:
              </Heading>

              <div className={styles.textContainer}>
                <div className={styles.text}>
                  <IconBadge isSmall backgroundColor="var(--blue200)">
                    <Glasses color="var(--blue800)" />
                  </IconBadge>
                  <div>
                    <Text
                      variant={TextVariants.RegularText}
                      color="var(--slate900)"
                    >
                      No utilices accesorios o lentes
                    </Text>
                    <Text
                      variant={TextVariants.RegularTextS}
                      color="var(--slate800)"
                    >
                      Tu rostro no debe estar obstruido.
                    </Text>
                  </div>
                </div>
                <div className={styles.text}>
                  <IconBadge isSmall backgroundColor="var(--blue200)">
                    <Sun color="var(--blue800)" />
                  </IconBadge>
                  <div>
                    <Text
                      variant={TextVariants.RegularText}
                      color="var(--slate900)"
                    >
                      Buscá un lugar bien iluminado
                    </Text>
                    <Text
                      variant={TextVariants.RegularTextS}
                      color="var(--slate800)"
                    >
                      Tu rostro se debe ver con claridad.
                    </Text>
                  </div>
                </div>
                <div className={styles.text}>
                  <IconBadge isSmall backgroundColor="var(--blue200)">
                    <Smartphone color="var(--blue800)" />
                  </IconBadge>
                  <div>
                    <Text
                      variant={TextVariants.RegularText}
                      color="var(--slate900)"
                    >
                      Mantené el teléfono estable
                    </Text>
                    <Text
                      variant={TextVariants.RegularTextS}
                      color="var(--slate800)"
                    >
                      Evitá mover el teléfono mientras filmás.
                    </Text>
                  </div>
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <FilledButton
                  onClick={() => setDisplayCamera(true)}
                  className={styles.button}
                >
                  Continuar
                </FilledButton>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Selfie;
