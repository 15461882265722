import { Currencies, BalanceCurrencies } from "interfaces/wallet";
import { Instruments } from "interfaces/markets";

import { OperationSide, OrderSource, OrderType, OrderStatus } from "../enums";

export interface OrdersTypes {
  orderId: string;
  orderType?: OrderType;
  side: OperationSide;
  isMepOperation: boolean;
  ticker: string;
  instrumentType?: Instruments;
  instrumentShortName?: string;
  executionDate: string;
  settlementDate?: string;
  settlementDays: number;
  amount: {
    value: number;
    currency: BalanceCurrencies;
  };
  quantity?: number;
  status: OrderStatus;
  source?: OrderSource;
  logo?: string;
}

export interface OrdersResponse {
  executionDate: string;
  balance: number;
  orders: OrdersTypes[];
}

export const mockOrdersResponse: OrdersResponse[] = [
  {
    executionDate: "2025-02-12",
    balance: 0,
    orders: [
      {
        orderId: "53054dfc-7528-4494-a8b2-f56055a102de",
        orderType: OrderType.LIMIT,
        side: OperationSide.SUBSCRIPTION,
        isMepOperation: false,
        ticker: "COCORMA",
        instrumentType: Instruments.FCI,
        instrumentShortName: "COCORMA",
        executionDate: "2025-02-04T09:57:13.176016-03:00",
        settlementDate: "2025-02-05T09:57:13.176016-03:00",
        settlementDays: 0,
        amount: {
          value: 123000,
          currency: Currencies.ARS,
        },
        quantity: 1200,
        status: OrderStatus.FILLED,
        source: OrderSource.MANUAL,
        logo: "Cocos.png",
      },
      {
        orderId: "53054dfc-7528-4494-a8b2-f56055a102de",
        orderType: OrderType.LIMIT,
        side: OperationSide.REDEMPTION,
        isMepOperation: false,
        ticker: "COCORMA",
        instrumentType: Instruments.FCI,
        instrumentShortName: "COCORMA",
        executionDate: "2025-02-04T09:57:13.176016-03:00",
        settlementDate: "2025-02-05T09:57:13.176016-03:00",
        settlementDays: 0,
        amount: {
          value: 123000,
          currency: Currencies.ARS,
        },
        quantity: 1200,
        status: OrderStatus.PARTIALLY_EXECUTED,
        source: OrderSource.MANUAL,
        logo: "Cocos.png",
      },
      {
        orderId: "53054dfc-7528-4494-a8b2-f56055a102de",
        orderType: OrderType.LIMIT,
        side: OperationSide.BUY,
        isMepOperation: true,
        ticker: "Dolar MEP",
        instrumentType: Instruments.FCI,
        instrumentShortName: "Dolar MEP",
        executionDate: "2025-02-04T09:57:13.176016-03:00",
        settlementDate: "2025-02-05T09:57:13.176016-03:00",
        settlementDays: 3,
        amount: {
          value: 120000,
          currency: Currencies.ARS,
        },
        quantity: 100,
        status: OrderStatus.PARTIALLY_CANCELLED,
        source: OrderSource.MANUAL,
        logo: "usd.jpg",
      },
      {
        orderId: "53054dfc-7528-4494-a8b2-f56055a102de",
        orderType: OrderType.LIMIT,
        side: OperationSide.SELL,
        isMepOperation: true,
        ticker: "Dolar MEP",
        instrumentType: Instruments.FCI,
        instrumentShortName: "Dolar MEP",
        executionDate: "2025-02-04T09:57:13.176016-03:00",
        settlementDate: "2025-02-05T09:57:13.176016-03:00",
        settlementDays: 2,
        amount: {
          value: 12000,
          currency: Currencies.ARS,
        },
        quantity: 100,
        status: OrderStatus.PARTIALLY_EXECUTED,
        source: OrderSource.MANUAL,
        logo: "usd.jpg",
      },
      {
        orderId: "53054dfc-7528-4494-a8b2-f56055a102de",
        orderType: OrderType.LIMIT,
        side: OperationSide.TAKER,
        isMepOperation: false,
        ticker: "Caución",
        instrumentType: Instruments.CAUCION,
        instrumentShortName: "Caución",
        executionDate: "2025-02-04T09:57:13.176016-03:00",
        settlementDate: "2025-02-05T09:57:13.176016-03:00",
        settlementDays: 30,
        amount: {
          value: 2222000,
          currency: Currencies.ARS,
        },
        status: OrderStatus.PENDING,
        source: OrderSource.CARD,
        logo: "ARG.jpg",
      },
      {
        orderId: "53054dfc-7528-4494-a8b2-f56055a102de",
        orderType: OrderType.LIMIT,
        side: OperationSide.BID,
        isMepOperation: false,
        ticker: "METRON29",
        instrumentType: Instruments.BONOS_CORP,
        instrumentShortName: "METRON29",
        executionDate: "2025-02-04T09:57:13.176016-03:00",
        settlementDate: "2025-02-05T09:57:13.176016-03:00",
        settlementDays: 3,
        amount: {
          value: 2222000,
          currency: Currencies.ARS,
        },
        status: OrderStatus.PENDING,
        source: OrderSource.QR,
        logo: "METR.jpg",
      },
      {
        orderId: "53054dfc-7528-4494-a8b2-f56055a102de",
        orderType: OrderType.LIMIT,
        side: OperationSide.BUY,
        isMepOperation: false,
        ticker: "LOMA",
        instrumentType: Instruments.CEDEARS,
        instrumentShortName: "LOMA",
        executionDate: "2025-02-04T09:57:13.176016-03:00",
        settlementDate: "2025-02-05T09:57:13.176016-03:00",
        settlementDays: 3,
        amount: {
          value: 2222000,
          currency: Currencies.ARS,
        },
        quantity: 100,
        status: OrderStatus.CANCELLED,
        source: OrderSource.QR,
        logo: "LOMA.jpg",
      },
    ],
  },
  {
    executionDate: "2025-02-13",
    balance: 0,
    orders: [
      {
        orderId: "53054dfc-7528-4494-a8b2-f56055a102de",
        orderType: OrderType.LIMIT,
        side: OperationSide.SUBSCRIPTION,
        isMepOperation: false,
        ticker: "COCORMA",
        instrumentType: Instruments.FCI,
        instrumentShortName: "COCORMA",
        executionDate: "2025-02-04T09:57:13.176016-03:00",
        settlementDate: "2025-02-05T09:57:13.176016-03:00",
        settlementDays: 0,
        amount: {
          value: 123000,
          currency: Currencies.ARS,
        },
        quantity: 1200,
        status: OrderStatus.FILLED,
        source: OrderSource.MANUAL,
        logo: "Cocos.png",
      },
      {
        orderId: "53054dfc-7528-4494-a8b2-f56055a102de",
        orderType: OrderType.LIMIT,
        side: OperationSide.REDEMPTION,
        isMepOperation: false,
        ticker: "COCORMA",
        instrumentType: Instruments.FCI,
        instrumentShortName: "COCORMA",
        executionDate: "2025-02-04T09:57:13.176016-03:00",
        settlementDate: "2025-02-05T09:57:13.176016-03:00",
        settlementDays: 0,
        amount: {
          value: 123000,
          currency: Currencies.ARS,
        },
        quantity: 1200,
        status: OrderStatus.PARTIALLY_EXECUTED,
        source: OrderSource.MANUAL,
        logo: "Cocos.png",
      },
      {
        orderId: "53054dfc-7528-4494-a8b2-f56055a102de",
        orderType: OrderType.LIMIT,
        side: OperationSide.BUY,
        isMepOperation: true,
        ticker: "Dolar MEP",
        instrumentType: Instruments.FCI,
        instrumentShortName: "Dolar MEP",
        executionDate: "2025-02-04T09:57:13.176016-03:00",
        settlementDate: "2025-02-05T09:57:13.176016-03:00",
        settlementDays: 3,
        amount: {
          value: 120000,
          currency: Currencies.ARS,
        },
        quantity: 100,
        status: OrderStatus.PARTIALLY_CANCELLED,
        source: OrderSource.MANUAL,
        logo: "usd.jpg",
      },
    ],
  },
];
