import { IconProps } from "assets/icons/BTC";

export enum CocosBrandVariants {
  PAY = "pay",
  CAPITAL = "capital",
  CRYPTO = "crypto",
}

interface CocosBrandProps extends IconProps {
  variant?: CocosBrandVariants;
}

const CocosBrand: React.FC<CocosBrandProps> = ({
  variant = CocosBrandVariants.CAPITAL,
  color,
  size = 24,
  ...props
}) => {
  const getBackgroundColor = () => {
    if (color) return color;

    if (variant === CocosBrandVariants.PAY) return "var(--black-to-white)";

    if (variant === CocosBrandVariants.CRYPTO) return "var(--purple800)";

    return "var(--blue800)";
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width="24" height="24" rx="12" fill={getBackgroundColor()} />
      <path
        d="M14.5338 17.6728L15.7523 20.041L16.9301 19.4332C20.3923 17.6457 21.957 13.7107 20.3923 10.6671C18.8276 7.62356 14.7315 6.58803 11.2606 8.38897L10.0781 8.99677L11.2966 11.365L12.4791 10.7572C14.6463 9.63611 17.1324 10.1494 18.0274 11.8873C18.9221 13.6252 17.8835 15.9484 15.7163 17.0649L14.5338 17.6728Z"
        fill="var(--white-to-black)"
      />
      <path
        d="M3.55416 13.6658C4.58373 15.7819 6.83616 16.9705 9.28215 16.9435L9.30016 16.93C10.4625 16.9125 11.6064 16.6373 12.6499 16.124L13.8457 15.5388L12.6813 13.148L11.4854 13.7333C9.29579 14.8004 6.8184 14.2421 5.95948 12.4862C5.7667 12.0612 5.6694 11.5992 5.67403 11.1325C5.67867 10.6658 5.78523 10.2058 5.98651 9.78477C6.47529 8.72461 7.32237 7.87146 8.37844 7.37603L9.57454 6.79073L8.40985 4.39999L7.21401 4.98079C5.59167 5.7524 4.29493 7.07483 3.55416 8.71323C3.18918 9.48762 3 10.3333 3 11.1895C3 12.0458 3.18918 12.8914 3.55416 13.6658Z"
        fill="var(--white-to-black)"
      />
    </svg>
  );
};

export default CocosBrand;
