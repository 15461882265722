import Text, { TextVariants } from "components/common/Text";
import { formatPercentage } from "utils";

interface AllocationPercentageProps {
  allocation: number;
}

const AllocationPercentage: React.FC<AllocationPercentageProps> = ({
  allocation,
}) => {
  return (
    <div>
      {allocation > 0 ? (
        <Text color="var(--slate800)" variant={TextVariants.RegularTextS}>
          {formatPercentage(allocation, false)}
        </Text>
      ) : (
        <Text variant={TextVariants.RegularText}>--</Text>
      )}
    </div>
  );
};

export default AllocationPercentage;
