import Text, { TextVariants } from "components/common/Text";
import { MIN_TO_BUY, MIN_TO_SELL } from "components/page-mep/constants";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";

import { MepErrors } from "..";

import styles from "./styles.module.scss";

interface ErrorMessageProps {
  isBuy: boolean;
  error?: MepErrors;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ isBuy, error }) => {
  const navigate = useNavigate();

  const minOperationText = isBuy
    ? `El mínimo de compra es de US$${MIN_TO_BUY}.`
    : `El mínimo de venta es de US$${MIN_TO_SELL}.`;
  if (error === undefined) return null;

  if (error === MepErrors.UnderMinimum)
    return (
      <Text
        variant={TextVariants.SemiboldTextS}
        color="var(--red800)"
        className={styles.text}
      >
        {minOperationText}
      </Text>
    );

  return (
    <Text
      variant={TextVariants.RegularTextS}
      className={styles.text}
      color="var(--red800)"
    >
      Tu saldo disponible es insuficiente.{" "}
      <Text
        component="span"
        color="var(--blue800)"
        className={styles.depositLink}
        variant={TextVariants.SemiboldTextS}
        onClick={() => navigate(mainRoutes.receive)}
      >
        Ir a ingresar dinero.
      </Text>
    </Text>
  );
};

export default ErrorMessage;
