export enum OperationSide {
  BID = "BID",
  BUY = "BUY",
  REDEMPTION = "REDEMPTION",
  SELL = "SELL",
  SETTER = "SETTER",
  SUBSCRIPTION = "SUBSCRIPTION",
  TAKER = "TAKER",
  WITHDRAWAL = "WITHDRAWAL",
}

export enum OrderStatus {
  CANCELLED = "CANCELLED",
  PENDING = "PENDING",
  MARKET = "MARKET",
  PARTIALLY_EXECUTED = "PARTIALLY_EXECUTED",
  PARTIALLY_CANCELLED = "PARTIALLY_CANCELLED",
  FILLED = "FILLED",
  REJECTED = "REJECTED",
}

export enum OrderType {
  MARKET = "MARKET",
  LIMIT = "LIMIT",
}

export enum OrderSource {
  MANUAL = "MANUAL",
  CARD = "CARD",
  QR = "QR",
}
