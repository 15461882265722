import {
  ATENCION_CAPITAL_EMAIL,
  ATENCION_CRYPTO_EMAIL,
} from "constants/emails";

import { useEffect } from "react";

import { ampli } from "ampli";
import { ThemeVariants } from "interfaces/theme";
import FilledButton from "components/common/FilledButton";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { X } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface CreationInProgressProps {
  onClickLater: () => void;
  variant: ThemeVariants;
}

const CreationInProgress: React.FC<CreationInProgressProps> = ({
  onClickLater,
  variant,
}) => {
  const isCryptoVariant = variant === ThemeVariants.Crypto;

  useEffect(() => {
    ampli.cclCryptoOnboarding({
      status: "failed",
      referral: isCryptoVariant ? "crypto" : "capital",
    });
  }, []);

  const onClickContactSupport = () => {
    ampli.cclCryptoSupport({ referral: "intro" });
    window.location.href = `mailto:${ATENCION_CRYPTO_EMAIL}`;
  };

  const onClose = () => {
    ampli.cclCryptoExit({ referral: "failed" });
    onClickLater();
  };

  const supportEmail = isCryptoVariant
    ? ATENCION_CRYPTO_EMAIL
    : ATENCION_CAPITAL_EMAIL;

  return (
    <div className={styles.contentWrapper}>
      <Text
        color="var(--slate900)"
        className={styles.title}
        variant={TextVariants.RegularText}
      >
        Algo salió mal
      </Text>
      <div className={styles.iconWrapper}>
        <X size={px2rem(24)} color="var(--red900)" />
      </div>
      <Text
        color="var(--slate800)"
        className={styles.text}
        variant={TextVariants.RegularText}
      >
        No pudimos crear tu cuenta. Por favor, por cualquier consulta comunicate
        a{" "}
        <a
          className={styles.email}
          color={isCryptoVariant ? "var(--purple800)" : "var(--blue800)"}
          href={`mailto:${supportEmail}`}
        >
          {supportEmail}
        </a>
      </Text>
      <div className={styles.buttonContainer}>
        <FilledButton onClick={onClickContactSupport} variant={variant}>
          Contactar a soporte
        </FilledButton>
        <OutlinedButton onClick={onClose} variant={variant}>
          Volver al inicio
        </OutlinedButton>
      </div>
    </div>
  );
};

export default CreationInProgress;
