import { cryptoStatus } from "constants/cryptos";
import { CryptoTermsAndConditionsURL } from "constants/index";

import React, { useEffect, useState } from "react";

import { ampli } from "ampli";
import API from "apis";
import { cryptoService } from "apis/services";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import Icon, { IconVariants } from "components/common/Icon";
import PageHeader from "components/common/PageHeader";
import Text, { TextVariants } from "components/common/Text";
import InputCheckbox from "components/common/input/InputCheckbox";
import { ThemeVariants } from "interfaces/theme";
import { BookOpenCheck, X } from "lucide-react";
import mainRoutes, { CryptoRoutes } from "router/routes";
import { updateCryptoStatus, updateTyCAccepted } from "features/user/userSlice";
import { Navigate, useNavigate } from "react-router-dom";
import { getLoggedUser } from "store/selectors/user.selector";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import TyCError from "./components/Error";

import styles from "./styles.module.scss";

export enum LiriumCoinagTyCVariants {
  CRYPTO = "Crypto",
  PAY = "Pay",
}

interface LiriumCoinagTyCProps {
  status?: cryptoStatus;
  variant?: LiriumCoinagTyCVariants;
}

const LiriumCoinagTyC: React.FC<LiriumCoinagTyCProps> = ({
  status,
  variant = LiriumCoinagTyCVariants.CRYPTO,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(getLoggedUser);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { latest_tnc_accepted } = user;
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const isCrypto = variant === LiriumCoinagTyCVariants.CRYPTO;

  const linkColor = isCrypto ? "var(--purple800)" : "var(--black-to-white)";
  const iconVariant = isCrypto ? IconVariants.CRYPTO : IconVariants.PAY;
  const iconColor = isCrypto ? "var(--purple900)" : "var(--black-to-white)";

  const isApproved = status === cryptoStatus.APPROVED;

  const buttonLabel = isCrypto ? "Ingresar a Cocos Crypto" : "Ingresar";

  const notApprovedOrAcceptedTyC = !isApproved || !latest_tnc_accepted;

  const checkboxLabel = (
    <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
      Acepto los{" "}
      <Text component="span" variant={TextVariants.SemiboldTextS}>
        <a
          href={CryptoTermsAndConditionsURL}
          target="_blank"
          rel="noreferrer"
          style={{ color: linkColor }}
        >
          Términos y Condiciones
        </a>
      </Text>
      .
    </Text>
  );

  useEffect(() => {
    ampli.cryptoScreenTyc({
      previous_screen: "capital",
      new_user: !isApproved,
    });
  }, []);

  const createCustomer = async () => {
    const { data } = await API.post<any>(cryptoService.customer);
    dispatch(updateCryptoStatus(data.status));
    if (data.status === cryptoStatus.PENDING_DOCUMENTS) uploadDocuments();
    else throw new Error("Error in create customer");
  };

  const uploadDocuments = async () => {
    const { data } = await API.post<any>(cryptoService.uploadDocuments);
    dispatch(updateCryptoStatus(data.status));
    if (data.status === cryptoStatus.PENDING_ADDRESSES) createAddress();
    else throw new Error("Error in upload documents");
  };

  const createAddress = async () => {
    const { data } = await API.post<any>(cryptoService.depositAddress);
    dispatch(updateCryptoStatus(data.status));
    if (data.status === cryptoStatus.APPROVED) {
      isCrypto && navigate(CryptoRoutes.HOME, { replace: true });
    } else throw new Error("Error in create address");
  };

  const handleCreateAccount = async () => {
    try {
      setLoading(true);

      if (!latest_tnc_accepted) {
        await API.post<any>(cryptoService.tncAccepted);
        dispatch(updateTyCAccepted(true));
      }

      if (isApproved) {
        isCrypto && navigate(CryptoRoutes.HOME, { replace: true });
        return;
      }
      if (status === cryptoStatus.PENDING || !status) {
        await createCustomer();
      }
      if (status === cryptoStatus.PENDING_DOCUMENTS) {
        await uploadDocuments();
      }
      if (status === cryptoStatus.PENDING_ADDRESSES) {
        await createAddress();
      }
    } catch (error: any) {
      setError(true);
      ampli.cryptoTycFailed();
    } finally {
      ampli.cryptoTycContinue({ new_user: !isApproved });
      setLoading(false);
    }
  };

  return (
    <div className={styles.liriumCoinagTyC}>
      <PageHeader
        title="Leer antes de ingresar"
        icon={X}
        iconColor={iconColor}
        onClick={() => navigate(mainRoutes.home)}
      />
      <div className={styles.wrapper}>
        <Icon variant={iconVariant} icon={BookOpenCheck} />
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h1"
          color="var(--slate900)"
        >
          Términos y condiciones
        </Heading>
        <div className={styles.text}>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            Las inversiones en criptomonedas implican un alto grado de riesgo.
          </Text>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            Cocos Capital S.A. (en adelante, “Cocos Capital”) no ofrece,
            recomienda o incentiva la compra o venta de criptomonedas,
            criptoactivos o cualquier otro Activo Digital (los “Activos
            Digitales y Criptomonedas”) que no tenga oferta pública autorizada
            por la Comisión Nacional de Valores.
          </Text>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            Las operaciones con Activos Digitales y Criptomonedas son realizadas
            a través de la plataforma de Cocos Crypto S.A. (“Cocos Crypto”), una
            sociedad afiliada a Cocos Capital. Cocos Crypto no se encuentra
            inscripta ante la Comisión Nacional de Valores, y toda la actividad
            relativa a Activos Digitales y Criptomonedas no se encuentra
            sometida al control y regulación de la Comisión Nacional de Valores.
          </Text>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            Cocos Capital no tiene participación alguna ni asume obligaciones o
            responsabilidades por las operaciones que se realicen a través de
            Cocos Crypto, ni incentiva o recomienda la realización de compras o
            ventas de Activos Digitales y Criptomonedas. Cualquier operación
            realizada a través de Cocos Crypto con Activos Digitales y
            Criptomonedas será realizada por el cliente a su riesgo y de
            conformidad con los términos y condiciones relativos a la operatoria
            a través de Cocos Crypto.
          </Text>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            Al hacer uso de los servicios de Cocos Crypto, usted reconoce y
            acepta que la realización de operaciones con criptomonedas implica
            un alto grado de riesgo. En particular, al acceder a la plataforma
            de Cocos Crypto usted reconoce y acepta que:
          </Text>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            (1) el mercado de Activos Digitales y Criptomonedas es un mercado no
            regulado por un banco central o autoridad gubernamental, incluyendo
            a la Comisión Nacional de Valores y el Banco Central de la República
            Argentina;
          </Text>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            (2) al no contar con una regulación específica los Activos Digitales
            y Criptomonedas no cumplen con las condiciones necesarias para ser
            considerados dinero de curso legal o valor negociable;
          </Text>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            (3) el mercado de Activos Digitales y Criptomonedas tiene alta
            volatilidad con variaciones significativas en los precios y conlleva
            grandes riesgos asociados;
          </Text>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            (4) las plataformas de intercambio, negociación y custodia de
            Activos Digitales y Criptomonedas podrían enfrentar disrupciones
            operacionales o ser objeto de ciberataques que impliquen la pérdida
            o robo de las claves de acceso sin posibilidad de recuperarlas,
            implicando la pérdida de los activos digitales;
          </Text>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            (5) el usuario asume todos los riesgos asociados con la rentabilidad
            o resultados de las transacciones realizadas con Activos Digitales y
            Criptomonedas; y
          </Text>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            (6) las transferencias de Activos Digitales y Criptoactivos
            realizadas podrían no ser reversibles, por lo que el usuario asume
            todas las consecuencias derivadas de cualquier transferencia
            efectuada por error o bien por algún ataque sufrido por el usuario
            (ejemplo, por el uso indebido de las claves de accesos, robo del
            celular, abuso de confianza, etc.).
          </Text>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            (7) Cocos Crypto SA - Proveedor de Servicios de Activos Virtuales
            (PSAV) inscripto bajo el N°14 de fecha 8 de Mayo de 2024 en el
            Registro de Proveedores de Servicios de Activos Virtuales de CNV.
            Este registro es a los fines del control como Sujeto Obligado ante
            la Unidad de Información Financiera (UIF) y de todo otro ente
            regulador facultado a tal efecto, en el marco de sus competencias, y
            no implica licencia ni supervisión por parte de la COMISIÓN NACIONAL
            DE VALORES sobre la actividad realizada por el PSAV”.
          </Text>
        </div>
      </div>
      {notApprovedOrAcceptedTyC && (
        <InputCheckbox
          rightLabel={checkboxLabel}
          name="tyc"
          checked={isChecked}
          variant={ThemeVariants[variant]}
          onChange={() => setIsChecked(!isChecked)}
        />
      )}
      <div className={styles.button}>
        <FilledButton
          variant={ThemeVariants[variant]}
          onClick={handleCreateAccount}
          disabled={!isChecked && notApprovedOrAcceptedTyC}
          isLoading={loading}
        >
          {buttonLabel}
        </FilledButton>
      </div>
      <TyCError
        error={error}
        onToggleDisplay={() => setError(false)}
        variant={variant}
      />
    </div>
  );
};

export default LiriumCoinagTyC;
