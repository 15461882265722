import { IconProps } from "assets/icons/BTC";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";

const Clock: React.FC<IconProps> = ({ height = 121, width = 82, ...props }) => {
  const darkMode = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );

  const darkBlue = darkMode ? "#104D87" : "#113264";
  const clockBody = darkMode ? "#0090FF" : "#0D74CE";
  const blueStar = darkMode ? "#0090FF" : "#0588F0";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 82 121"
      fill="none"
      {...props}
    >
      <path
        d="M4.45638 120C4.45638 119.311 4.18282 118.651 3.69588 118.164C3.20894 117.677 2.5485 117.403 1.85986 117.403C2.54866 117.403 3.20909 117.129 3.69598 116.642C4.18288 116.155 4.45638 115.494 4.45638 114.805C4.45616 115.146 4.52321 115.484 4.65371 115.8C4.7842 116.115 4.97558 116.402 5.21689 116.643C5.45819 116.884 5.74471 117.076 6.06004 117.206C6.37536 117.337 6.71332 117.404 7.05459 117.403C6.71355 117.403 6.37581 117.47 6.06068 117.601C5.74556 117.731 5.45923 117.922 5.21808 118.163C4.97692 118.405 4.78567 118.691 4.65527 119.006C4.52486 119.321 4.45785 119.659 4.45807 120H4.45638Z"
        fill={darkBlue}
        stroke={darkBlue}
        strokeWidth="0.296745"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M76.0646 64.8523C76.0646 63.5346 75.5411 62.2709 74.6094 61.3391C73.6776 60.4074 72.4139 59.8839 71.0962 59.8839C72.4136 59.8829 73.6767 59.3592 74.6083 58.4276C75.5398 57.4961 76.0636 56.2329 76.0646 54.9155C76.0646 56.2332 76.588 57.497 77.5198 58.4287C78.4515 59.3605 79.7153 59.8839 81.033 59.8839C80.3804 59.8834 79.7341 60.0116 79.1311 60.2611C78.5281 60.5106 77.9802 60.8766 77.5187 61.338C77.0572 61.7995 76.6913 62.3474 76.4418 62.9504C76.1923 63.5534 76.0641 64.1997 76.0646 64.8523Z"
        fill={blueStar}
        stroke={blueStar}
        strokeWidth="0.653051"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M5.92454 9.93305C5.92356 8.61758 5.40132 7.35611 4.47219 6.42489C3.54305 5.49367 2.28275 4.9686 0.967285 4.96467C2.28241 4.96173 3.54272 4.43748 4.47196 3.50685C5.4012 2.57622 5.92356 1.31513 5.92454 0C5.92454 0.652908 6.05332 1.2994 6.30351 1.90247C6.55371 2.50554 6.9204 3.05334 7.38259 3.51449C7.84478 3.97565 8.3934 4.34112 8.99702 4.58996C9.60065 4.8388 10.2474 4.96614 10.9003 4.96467C10.2473 4.9637 9.60039 5.09149 8.99674 5.34073C8.39309 5.58998 7.84451 5.9558 7.38237 6.41725C6.92022 6.87871 6.55359 7.42675 6.30344 8.03002C6.05329 8.6333 5.92454 9.27997 5.92454 9.93305Z"
        fill="#FFC419"
        stroke="#FFC419"
        strokeWidth="0.653051"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M23 23.0283L55 23.0283V48.882C55 50.2099 54.3084 51.4515 53.1487 52.2028L42.7483 59.4767C42.255 59.8217 41.8392 60.2698 41.5279 60.7851C39.1392 64.739 38.8603 64.7383 36.4709 60.7831C36.1604 60.2691 35.7459 59.8219 35.2542 59.4772L24.8748 52.2019C23.7024 51.4525 23 50.2028 23 48.866V23.0283Z"
        fill={clockBody}
      />
      <path
        d="M55 98.9097L23 98.9097L23 73.056C23 71.7281 23.6916 70.4865 24.8513 69.7352L35.2429 62.5253C35.7419 62.1791 36.1622 61.7278 36.4763 61.2079C38.8597 57.2634 39.1408 57.264 41.5249 61.2098C41.8382 61.7284 42.2572 62.1789 42.7547 62.5248L53.1252 69.7361C54.2976 70.4855 55 71.7352 55 73.072L55 98.9097Z"
        fill={clockBody}
      />
      <rect
        x="11.0891"
        y="18.9233"
        width="55.6859"
        height="5.35442"
        rx="0.856707"
        fill={darkBlue}
      />
      <rect
        x="11.0891"
        y="97.7402"
        width="55.6859"
        height="5.78277"
        rx="0.856707"
        fill={darkBlue}
      />
      <rect
        x="16.6577"
        y="21.2793"
        width="0.428353"
        height="79.2454"
        fill={darkBlue}
      />
      <circle cx="16.8725" cy="60.902" r="2.14177" fill={darkBlue} />
      <rect
        x="60.7781"
        y="21.2793"
        width="0.428353"
        height="79.2454"
        fill={darkBlue}
      />
      <circle cx="60.9919" cy="60.902" r="2.14177" fill={darkBlue} />
      <path
        d="M24.9999 93.5C24.9999 94.9407 26.0744 96.1087 27.3999 96.1087H50.5999C51.9254 96.1087 52.9999 94.9407 52.9999 93.5V86.3136C52.9999 85.1532 52.2955 84.1251 51.2702 83.8014L41.7487 80.5898C40.7037 80.2373 40 79.2574 40 78.1545V59H38V78.1557C38 79.258 37.2971 80.2376 36.2528 80.5905L26.7389 83.8059C25.7096 84.1267 25.0003 85.1497 25.0003 86.3136L24.9999 93.5Z"
        fill="url(#paint0_linear_7760_2426)"
      />
      <path
        d="M25.0004 41.6494H53.0003C53.0003 41.6494 53.0003 46.736 53.0003 47.9156C53.0003 48.7692 52.6175 49.568 51.9706 50.052C48.1027 52.9462 44.1197 56.4846 40 59H38L26.0502 50.0546C25.3934 49.5688 25.0003 48.7613 25.0003 47.8978C25.0003 46.7187 25.0004 41.6494 25.0004 41.6494Z"
        fill="url(#paint1_linear_7760_2426)"
      />
      <path
        d="M48.833 4.19434V22.0048"
        stroke={darkBlue}
        strokeWidth="0.653051"
        strokeMiterlimit="10"
      />
      <path
        d="M26.7441 100.353V118.163"
        stroke={darkBlue}
        strokeWidth="0.653051"
        strokeMiterlimit="10"
      />
      <path
        d="M8.38843 42.2998V65.216"
        stroke={clockBody}
        strokeWidth="0.653051"
        strokeMiterlimit="10"
      />
      <path
        d="M71.2371 84.1919V107.112"
        stroke={clockBody}
        strokeWidth="0.653051"
        strokeMiterlimit="10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7760_2426"
          x1="52.4894"
          y1="68.8791"
          x2="24.4911"
          y2="68.8791"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.11" stopColor="#FFC419" />
          <stop offset="0.22" stopColor="#FFC724" stopOpacity="0.97" />
          <stop offset="0.38" stopColor="#FFCF42" stopOpacity="0.89" />
          <stop offset="0.58" stopColor="#FFDB74" stopOpacity="0.76" />
          <stop offset="0.8" stopColor="#FFEDB9" stopOpacity="0.58" />
          <stop offset="1" stopColor="white" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7760_2426"
          x1="52.4894"
          y1="68.8791"
          x2="24.4911"
          y2="68.8791"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.11" stopColor="#FFC419" />
          <stop offset="0.22" stopColor="#FFC724" stopOpacity="0.97" />
          <stop offset="0.38" stopColor="#FFCF42" stopOpacity="0.89" />
          <stop offset="0.58" stopColor="#FFDB74" stopOpacity="0.76" />
          <stop offset="0.8" stopColor="#FFEDB9" stopOpacity="0.58" />
          <stop offset="1" stopColor="white" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Clock;
