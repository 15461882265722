import { useState } from "react";

import { ampli } from "ampli";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import OutlinedButton from "components/common/OutlinedButton";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import Text, { TextVariants } from "components/common/Text";
import Currency from "components/common/Text/Currency";
import { UIModalSlider } from "components/common/modal-slider";
import {
  getFCIIconColor,
  getIcon,
  isUSD,
} from "components/page-cocos-pay/utils";
import { useCocosPay } from "context/CocosPayProvider";
import { PaySource, PaymentCurrencies } from "interfaces/pay/enums";
import { ThemeVariants } from "interfaces/theme";
import { Currencies } from "interfaces/wallet";
import px2rem from "utils/px2rem";
import OverlappedIcons from "components/common/OverlappedIcons";
import USDTIcon from "assets/icons/USDT";
import USDCIcon from "assets/icons/USDC";
import DAIIcon from "assets/icons/DAI";

import styles from "./styles.module.scss";

interface PayMethodConfirm {
  variant: ThemeVariants;
}

const PayMethodConfirm: React.FC<PayMethodConfirm> = ({ variant }) => {
  const {
    selectedMethod,
    setSelectedMethod,
    paymentData,
    onPayQR,
    pixPrices,
    source,
  } = useCocosPay();

  if (!selectedMethod) {
    return null;
  }

  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);

  const isBridge = source === PaySource.BRIDGE;

  const {
    name,
    amount,
    amountAvailableArs,
    amountAvailableUsd,
    price,
  } = selectedMethod;
  const { quantity, businessName, transactionCurrency, currency } = paymentData;

  const isBR = transactionCurrency === Currencies.BRL;

  const isUSDCurrency = isUSD(name);

  const isCurrency = [PaymentCurrencies.ARS, PaymentCurrencies.USD].includes(
    name
  );

  const balance = isUSDCurrency ? amountAvailableUsd : amountAvailableArs;

  const amountToPay = isCurrency ? amount : (price || 1) * amount;

  const payCurrency = isUSDCurrency ? Currencies.USD : Currencies.ARS;

  const isCryptoOverlapped = name === PaymentCurrencies.STABLECOIN;

  const exchangeRate = isBR ? pixPrices?.brlToArsRate : pixPrices?.usdToArsRate;

  const Icon = getIcon(name);

  const slate600 = "var(--slate600)";
  const slate800 = "var(--slate800)";
  const slate900 = "var(--slate900)";

  const onClose = () => {
    setSelectedMethod();
  };

  const handleConfirm = () => {
    setIsPaymentLoading(true);
    isBridge
      ? ampli.cclCryptoPaymentConfirmed({ method: name })
      : ampli.payClickConfirm({
          amount: quantity,
          method: name,
          paid_to: businessName,
        });
    onPayQR(name);

    setTimeout(() => {
      setIsPaymentLoading(false);
    }, 2000);
  };

  return (
    <UIModalSlider
      open={true}
      onToggleDisplay={onClose}
      classes={styles.payMethodConfirmContainer}
    >
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        color={slate900}
        component="h2"
      >
        Confirmar pago
      </Heading>
      <div className={styles.header}>
        {isCryptoOverlapped ? (
          <OverlappedIcons icons={[USDTIcon, USDCIcon, DAIIcon]} />
        ) : (
          <Icon size={px2rem(32)} color={getFCIIconColor(name)} />
        )}

        <PriceLabel
          currency={payCurrency}
          price={amountToPay}
          size={PriceLabelSizes.Default}
        />
      </div>
      <div className={styles.confirmBottomSheetDetail}>
        {businessName && (
          <div className={styles.row}>
            <Text variant={TextVariants.RegularText} color={slate800}>
              Pagás a
            </Text>
            <Text
              className={styles.businessName}
              variant={TextVariants.RegularText}
              color={slate900}
            >
              {businessName.trim()}
            </Text>
          </div>
        )}
        <div className={styles.row}>
          <Text variant={TextVariants.RegularText} color={slate800}>
            Balance
          </Text>
          <Currency
            ticker={payCurrency}
            amount={balance || 0}
            color={slate900}
          />
        </div>
        {(isUSDCurrency || isBR) && (
          <div className={styles.row}>
            <Text variant={TextVariants.RegularText} color={slate800}>
              Cotización
            </Text>
            <Text
              className={styles.rate}
              variant={TextVariants.SemiboldUpperValue}
              color={slate800}
            >
              <Currency
                ticker={currency}
                amount={1}
                withoutDecimals
                color={slate900}
              />
              ≈
              <Currency amount={exchangeRate || 1} color={slate900} />
            </Text>
          </div>
        )}
      </div>
      <Text variant={TextVariants.RegularTextS} color={slate600}>
        Al apretar al botón de pagar estas aceptando que el pago será procesado
        como una extracción de <b>Cocos Cap</b> hacia el CVU del cliente en{" "}
        <b>Ágil Pagos</b>
      </Text>
      <div className={styles.buttonsWrapper}>
        <OutlinedButton variant={variant} onClick={onClose}>
          Volver
        </OutlinedButton>
        <FilledButton
          variant={variant}
          onClick={handleConfirm}
          isLoading={isPaymentLoading}
          disabled={isPaymentLoading}
        >
          Pagar
        </FilledButton>
      </div>
    </UIModalSlider>
  );
};

export default PayMethodConfirm;
