import { User } from "@supabase/supabase-js";

import { UserMeResponse } from "./api-responses";

export interface FavoriteList {
  id_list: string;
  name: string;
}

export enum CocosProducts {
  CAPITAL = "CAPITAL",
  CRYPTO = "CRYPTO",
  PAY = "PAY",
}

export enum RoleActions {
  MARKET_ORDERS = "MARKET_ORDERS",
  FCI_ORDERS = "FCI_ORDERS",
  MEP_ORDERS = "MEP_ORDERS",
  BANK_ACCOUNTS = "BANK_ACCOUNTS",
  CASH_WITHDRAWALS = "CASH_WITHDRAWALS",
  MANUAL_CASH_WITHDRAWALS = "MANUAL_CASH_WITHDRAWALS",
  CRYPTO_ACCOUNT_CREATION = "CRYPTO_ACCOUNT_CREATION",
  CRYPTO_ORDERS = "CRYPTO_ORDERS",
  CRYPTO_WITHDRAWALS = "CRYPTO_WITHDRAWALS",
  CARD_CREATION = "CARD_CREATION",
  PAYMENTS = "PAYMENTS",
  CARD_DETAILS = "CARD_DETAILS",
  CARDS_MANAGEMENT = "CARDS_MANAGEMENT",
  CARDS_SENSITIVE_DATA = "CARDS_SENSITIVE_DATA",
}

export enum Tier {
  FREE = "FREE",
  GOLD = "GOLD",
  PRO = "PRO",
  PJ = "PJ",
  AFI = "AFI",
  CCERA = "CCERA",
}
export type UserData = (User | undefined) &
  (UserMeResponse | undefined) & {
    signInWithBiometrics?: boolean;
    tag?: string | null;
    hasAccount: boolean;
  };

export interface Investor {
  expiration_date: string;
  expiration_days: string;
  risk_profile: string;
}

// ENUMS

export enum InvestorTypes {
  CONSERVADOR = "Conservador",
  MODERADO = "Moderado",
  AGRESIVO = "Agresivo",
  ARRIESGADO = "Arriesgado",
}

export interface TierResponse {
  idAccount: number;
  tier: Tiers;
  aranceles: [];
  tierUpgrade: {
    propose: "gold" | null;
    requested: boolean;
  };
}

export enum Tiers {
  FREE = "FREE",
  GOLD_AVAILABLE = "GOLD_AVAILABLE",
  GOLD_REQUESTED = "GOLD_REQUESTED",
  GOLD = "GOLD",
}
