import { useEffect, useState } from "react";

import { ampli } from "ampli";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { ArrowLeft } from "lucide-react";
import { ThemeVariants } from "interfaces/theme";
import { CardSections, UserStatus } from "interfaces/card/enums";
import { ShippingInfoValues } from "interfaces/card/interface";
import useKeyboardHeight from "hooks/useKeyboardHeight";
import { useCocosCard } from "context/CocosCardProvider";
import Footer from "components/common/Footer";
import NavigationBar from "components/common/NavigationBar";
import Heading, { HeadingVariants } from "components/common/Heading";
import FilledButton from "components/common/FilledButton";
import InputField from "components/common/input/InputField";
import Select from "components/common/NewSelect";

import SelectBottomSheet from "./SelectBottomSheet";
import ShippingConfirm from "./ConfirmBottomSheet";
import { REGION_OPTIONS } from "../constants";

import styles from "./styles.module.scss";

const MAX_LENGTH = 30;
const ZIP_LENGTH = 4;
const FOOTER_HEIGHT = 40;

const CardShipping = () => {
  const {
    setSelectedSection,
    userStatus,
    setShippingInfo,
    setIsError,
  } = useCocosCard();

  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [openCardDetails, setOpenCardDetails] = useState<boolean>(false);

  const { extraSpace } = useKeyboardHeight(FOOTER_HEIGHT);

  const ValidationSchema = Yup.object().shape({
    region: Yup.string().required(),
    city: Yup.string().required(),
    street_name: Yup.string().required(),
    street_number: Yup.string().required(),
    zip_code: Yup.string().required(),
    floor: Yup.string(),
    apartment: Yup.string(),
    additional_info: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      region: "",
      city: "",
      street_name: "",
      street_number: "",
      zip_code: "",
      floor: "",
      apartment: "",
      additional_info: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (
      values: ShippingInfoValues,
      { setSubmitting }: FormikHelpers<ShippingInfoValues>
    ) => {
      setSubmitting(true);
      ampli.cardDeliveryContinue();

      try {
        const filteredValues = Object.fromEntries(
          Object.entries(values).filter(([_, value]) => value !== "")
        );

        setShippingInfo(filteredValues);
        setOpenCardDetails(true);
      } catch (e) {
        setIsError(true);
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const getSelectedValue = (value: string) =>
    REGION_OPTIONS.find((option) => option.value === value)?.label || "";

  const onClose = () =>
    userStatus === UserStatus.ACTIVE
      ? setSelectedSection(CardSections.PHYSICAL_INTRO)
      : setSelectedSection(CardSections.CARD_SELECTION);

  useEffect(() => {
    ampli.cardScreenDelivery();
  }, []);

  return (
    <div className={styles.shippingContainer}>
      <NavigationBar
        title="Envío de tu tarjeta física"
        leftIcon={ArrowLeft}
        onClickLeft={() => onClose()}
      />
      <div style={{ paddingBottom: extraSpace }}>
        <Heading
          variant={HeadingVariants.RegularTitle}
          color="var(--slate900)"
          component="h1"
          className={styles.titleHeader}
        >
          ¿Dónde vas a recibir tu tarjeta?
        </Heading>
        <form
          onSubmit={formik.handleSubmit}
          className={styles.form}
          id="cardShipping"
        >
          <Select
            label="Provincia"
            placeholder="Seleccionar"
            value={getSelectedValue(formik.values.region)}
            open={isSelectOpen}
            setOpen={setIsSelectOpen}
          >
            <SelectBottomSheet
              title="Seleccioná una provincia"
              value={formik.values.region}
              options={REGION_OPTIONS}
              setOpen={setIsSelectOpen}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="region"
            />
          </Select>

          <InputField
            label="Localidad"
            name="city"
            placeholder="Ingresá la localidad"
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />

          <InputField
            label="Calle"
            name="street_name"
            placeholder="Ingresá la calle"
            value={formik.values.street_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />

          <InputField
            label="Altura"
            name="street_number"
            placeholder="Ingresá la altura"
            value={formik.values.street_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />

          <InputField
            label="Código postal"
            name="zip_code"
            placeholder="Ingresá el código postal"
            value={formik.values.zip_code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            pattern="\d{4}"
            inputMode="numeric"
            maxLength={ZIP_LENGTH}
            required
          />

          <InputField
            label="Piso (opcional)"
            name="floor"
            placeholder="Ingresá el piso"
            value={formik.values.floor}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <InputField
            label="Departamento (opcional)"
            name="apartment"
            placeholder="Ingresá el departamento"
            value={formik.values.apartment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <InputField
            label="Indicaciones (opcional)"
            name="additional_info"
            placeholder="Ej: lo reciben en portería"
            value={formik.values.additional_info}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            maxLength={MAX_LENGTH}
          />
        </form>
      </div>
      <ShippingConfirm
        open={openCardDetails}
        onClose={() => setOpenCardDetails(false)}
      />
      <Footer>
        <FilledButton
          variant={ThemeVariants.Pay}
          type="submit"
          disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
          isLoading={formik.isSubmitting}
          form="cardShipping"
        >
          Continuar
        </FilledButton>
      </Footer>
    </div>
  );
};

export default CardShipping;
