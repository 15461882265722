import { IconProps } from "../BTC";

const CocosBiz: React.FC<IconProps> = ({ size = 100, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    viewBox="0 0 57 40"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_18916_273426)">
      <path
        d="M0.268023 19.7504C0.250026 19.1025 0.362005 18.4586 0.59996 17.8567C0.837915 17.2548 1.19185 16.7049 1.64576 16.245C2.09967 15.7831 2.64157 15.4192 3.23945 15.1712C3.83734 14.9233 4.47922 14.8013 5.1251 14.8093C6.2409 14.7753 7.33265 15.1392 8.2045 15.8371C9.07635 16.535 9.6762 17.5168 9.8942 18.6126H7.0967C6.94675 18.2227 6.6748 17.8887 6.32285 17.6648C5.97095 17.4408 5.555 17.3348 5.1371 17.3668C4.0353 17.3668 3.0015 18.2087 3.0015 19.7524C3.0015 21.2961 4.0433 22.1579 5.1371 22.1579C5.577 22.1859 6.0129 22.0599 6.37085 21.802C6.7288 21.544 6.98475 21.1681 7.0967 20.7402H9.8942C9.7122 21.866 9.12835 22.8878 8.2505 23.6136C7.37265 24.3415 6.2629 24.7234 5.1231 24.6934C4.47522 24.7034 3.83334 24.5794 3.23545 24.3335C2.63757 24.0875 2.09367 23.7216 1.64176 23.2597C1.18785 22.7978 0.831915 22.2479 0.59596 21.646C0.360005 21.0441 0.248026 20.3982 0.268023 19.7504Z"
        fill="#113264"
      />
      <path
        d="M17.7024 19.7511C17.7024 18.3414 16.7786 17.3575 15.4828 17.3575C14.1871 17.3575 13.2593 18.3434 13.2593 19.7511C13.2593 21.1588 14.1831 22.1446 15.4828 22.1446C16.7826 22.1446 17.7024 21.1668 17.7024 19.7511ZM10.5258 19.7511C10.5218 18.7673 10.8097 17.8055 11.3536 16.9856C11.8975 16.1658 12.6714 15.5259 13.5792 15.148C14.487 14.77 15.4848 14.6701 16.4487 14.862C17.4125 15.054 18.2963 15.5279 18.9922 16.2238C19.688 16.9196 20.1579 17.8075 20.3479 18.7713C20.5379 19.7371 20.4359 20.7369 20.058 21.6447C19.68 22.5525 19.0402 23.3264 18.2203 23.8703C17.4005 24.4122 16.4387 24.7001 15.4568 24.6941C14.805 24.7081 14.1571 24.5882 13.5532 24.3442C12.9493 24.1002 12.3994 23.7363 11.9395 23.2744C11.4796 22.8125 11.1177 22.2626 10.8737 21.6567C10.6318 21.0508 10.5138 20.403 10.5278 19.7511H10.5258Z"
        fill="#113264"
      />
      <path
        d="M21.2165 19.7504C21.1985 19.1025 21.3105 18.4586 21.5485 17.8567C21.7844 17.2548 22.1404 16.7049 22.5943 16.245C23.0482 15.7831 23.5901 15.4192 24.188 15.1712C24.7859 14.9233 25.4277 14.8013 26.0736 14.8093C27.1894 14.7753 28.2812 15.1392 29.153 15.8371C30.0248 16.535 30.6247 17.5168 30.8427 18.6126H28.0452C27.8953 18.2227 27.6233 17.8887 27.2714 17.6648C26.9194 17.4408 26.5035 17.3348 26.0856 17.3668C24.9838 17.3668 23.95 18.2087 23.95 19.7524C23.95 21.2961 24.9918 22.1579 26.0856 22.1579C26.5255 22.1859 26.9614 22.0599 27.3194 21.802C27.6773 21.544 27.9332 21.1681 28.0452 20.7402H30.8427C30.6607 21.866 30.0768 22.8878 29.199 23.6136C28.3212 24.3415 27.2114 24.7234 26.0716 24.6934C25.4237 24.7034 24.7819 24.5794 24.184 24.3335C23.5861 24.0875 23.0422 23.7216 22.5903 23.2597C22.1364 22.7978 21.7804 22.2479 21.5445 21.646C21.3085 21.0421 21.1965 20.3982 21.2165 19.7504Z"
        fill="#113264"
      />
      <path
        d="M38.6583 19.7511C38.6583 18.3414 37.7344 17.3575 36.4387 17.3575C35.1429 17.3575 34.2191 18.3434 34.2191 19.7511C34.2191 21.1588 35.1389 22.1446 36.4387 22.1446C37.7384 22.1446 38.6583 21.1668 38.6583 19.7511ZM31.4816 19.7511C31.4776 18.7673 31.7656 17.8055 32.3095 16.9856C32.8534 16.1658 33.6272 15.5259 34.5351 15.148C35.4429 14.77 36.4407 14.6701 37.4045 14.862C38.3683 15.054 39.2521 15.5279 39.948 16.2238C40.6439 16.9196 41.1138 17.8075 41.3038 18.7713C41.4937 19.7371 41.3917 20.7369 41.0138 21.6447C40.6359 22.5525 39.996 23.3264 39.1762 23.8703C38.3563 24.4122 37.3945 24.7001 36.4127 24.6941C35.7608 24.7081 35.1129 24.5882 34.5091 24.3442C33.9052 24.1002 33.3553 23.7363 32.8954 23.2744C32.4355 22.8125 32.0735 22.2626 31.8296 21.6567C31.5876 21.0508 31.4696 20.403 31.4836 19.7511H31.4816Z"
        fill="#113264"
      />
      <path
        d="M42.1932 21.3467L44.8367 21.3667C44.8807 22.0466 45.3286 22.5305 46.3044 22.5305C47.1903 22.5305 47.5242 22.1805 47.5242 21.6886C47.5242 21.1967 47.0083 20.9508 45.9225 20.8088C44.0769 20.5848 42.5352 19.779 42.5352 17.8114C42.5352 15.8437 43.8809 14.8059 46.1945 14.8059C48.508 14.8059 50.0936 16.0597 50.0936 17.9653L47.3982 17.9453C47.3622 17.4234 46.9923 17.0415 46.2025 17.0415C45.5146 17.0415 45.2387 17.3634 45.2387 17.7494C45.2387 18.2713 45.4346 18.4052 46.9143 18.6172C49.0279 18.9231 50.3416 19.599 50.3416 21.5246C50.3416 23.6982 48.552 24.7 46.2765 24.7C43.439 24.7 42.1952 23.0144 42.1952 21.3447L42.1932 21.3467Z"
        fill="#113264"
      />
      <path
        d="M45.9731 11.3438C43.7615 11.3678 41.7239 10.294 40.7921 8.3804C40.4621 7.68055 40.2902 6.91465 40.2902 6.1408C40.2902 5.36695 40.4621 4.60111 40.7921 3.90125C41.4619 2.41953 42.6337 1.22377 44.1014 0.5259L45.1832 0L46.237 2.16159L45.1552 2.69148C44.1994 3.13939 43.4336 3.91125 42.9916 4.86906C42.8097 5.249 42.7137 5.6649 42.7097 6.08685C42.7057 6.50875 42.7937 6.92665 42.9676 7.3106C43.7435 8.8983 45.9851 9.4042 47.9647 8.4384L49.0465 7.9085L50.1002 10.0701L49.0185 10.5999C48.0747 11.0639 47.0409 11.3139 45.9891 11.3279"
        fill="#113264"
      />
      <path
        d="M51.8224 14.1452L50.7209 12.0037L51.7904 11.4538C53.7499 10.444 54.6899 8.34239 53.8799 6.77069C53.0704 5.19899 50.8229 4.73506 48.8631 5.74889L47.7933 6.29879L46.6915 4.15718L47.7613 3.60728C50.9009 1.97959 54.6039 2.91541 56.0199 5.66689C57.4354 8.41834 56.0199 11.9777 52.8884 13.5934L51.8224 14.1433V14.1452Z"
        fill="#0D74CE"
      />
      <path
        d="M23.4691 39.9549C23.2412 39.9549 23.0343 39.9159 22.8483 39.8379C22.6624 39.766 22.5034 39.667 22.3715 39.541C22.2395 39.4091 22.1375 39.2591 22.0656 39.0912C21.9936 38.9172 21.9576 38.7313 21.9576 38.5334C21.9576 38.3414 21.9936 38.1585 22.0656 37.9845C22.1375 37.8106 22.2395 37.6606 22.3715 37.5347C22.5034 37.4027 22.6624 37.3008 22.8483 37.2288C23.0343 37.1508 23.2442 37.1118 23.4781 37.1118C23.712 37.1118 23.922 37.1508 24.1079 37.2288C24.2938 37.3008 24.4528 37.4027 24.5847 37.5347C24.7227 37.6606 24.8277 37.8106 24.8996 37.9845C24.9716 38.1585 25.0076 38.3414 25.0076 38.5334C25.0076 38.7313 24.9716 38.9172 24.8996 39.0912C24.8277 39.2591 24.7227 39.4091 24.5847 39.541C24.4528 39.667 24.2908 39.766 24.0989 39.8379C23.913 39.9159 23.703 39.9549 23.4691 39.9549Z"
        fill="#0D74CE"
      />
      <path
        d="M32.2319 40.0006C31.6321 40.0006 31.0803 39.8656 30.5764 39.5957C30.0786 39.3258 29.6767 38.9659 29.3708 38.5161V39.6947H26.7257V27.0178H29.4968V30.3107L29.4338 31.4894C29.7397 31.0335 30.1386 30.6856 30.6304 30.4457C31.1223 30.2058 31.6411 30.0858 32.1869 30.0858C32.8527 30.0858 33.4615 30.2178 34.0133 30.4817C34.5651 30.7396 35.039 31.0935 35.4349 31.5433C35.8307 31.9932 36.1366 32.518 36.3526 33.1178C36.5685 33.7176 36.6765 34.3564 36.6765 35.0342C36.6765 35.718 36.5655 36.3628 36.3436 36.9686C36.1276 37.5684 35.8217 38.0932 35.4259 38.5431C35.036 38.9929 34.5681 39.3498 34.0223 39.6137C33.4765 39.8716 32.8797 40.0006 32.2319 40.0006ZM31.7101 37.4544C32.004 37.4544 32.2859 37.4034 32.5558 37.3015C32.8257 37.1935 33.0626 37.0375 33.2666 36.8336C33.4705 36.6297 33.6324 36.3778 33.7524 36.0779C33.8784 35.778 33.9413 35.4301 33.9413 35.0342C33.9413 34.6563 33.8814 34.3204 33.7614 34.0265C33.6414 33.7266 33.4795 33.4747 33.2756 33.2708C33.0716 33.0608 32.8317 32.9019 32.5558 32.7939C32.2859 32.68 31.998 32.623 31.6921 32.623C31.3862 32.623 31.0983 32.686 30.8284 32.8119C30.5585 32.9379 30.3215 33.1088 30.1176 33.3248C29.9197 33.5407 29.7607 33.7956 29.6408 34.0895C29.5268 34.3834 29.4698 34.6983 29.4698 35.0342C29.4698 35.3941 29.5298 35.724 29.6497 36.0239C29.7697 36.3178 29.9317 36.5727 30.1356 36.7886C30.3455 36.9986 30.5854 37.1635 30.8554 37.2835C31.1253 37.3974 31.4102 37.4544 31.7101 37.4544Z"
        fill="#0D74CE"
      />
      <path
        d="M39.5749 29.2753C39.317 29.2753 39.086 29.2363 38.8821 29.1583C38.6842 29.0803 38.5132 28.9754 38.3693 28.8434C38.2313 28.7055 38.1263 28.5465 38.0544 28.3666C37.9824 28.1806 37.9464 27.9857 37.9464 27.7818C37.9464 27.5778 37.9824 27.3859 38.0544 27.206C38.1263 27.02 38.2313 26.8611 38.3693 26.7291C38.5072 26.5912 38.6782 26.4832 38.8821 26.4052C39.086 26.3212 39.3199 26.2793 39.5839 26.2793C39.8538 26.2793 40.0907 26.3212 40.2946 26.4052C40.4986 26.4832 40.6695 26.5912 40.8075 26.7291C40.9514 26.8611 41.0594 27.02 41.1314 27.206C41.2033 27.3859 41.2393 27.5778 41.2393 27.7818C41.2393 27.9917 41.2033 28.1866 41.1314 28.3666C41.0594 28.5465 40.9514 28.7055 40.8075 28.8434C40.6695 28.9754 40.4956 29.0803 40.2856 29.1583C40.0817 29.2363 39.8448 29.2753 39.5749 29.2753ZM38.1983 30.3729H40.9694V39.6939H38.1983V30.3729Z"
        fill="#0D74CE"
      />
      <path
        d="M42.203 38.021L46.4946 32.6948H42.4279V30.3735H50.2734V32.074L46.0268 37.3732H50.4894V39.6945H42.203V38.021Z"
        fill="#0D74CE"
      />
    </g>
    <defs>
      <clipPath id="clip0_18916_273426">
        <rect width="57" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CocosBiz;
