import API from "apis";
import { activityKeys } from "apis/queryKeyFactories";
import type { ErrorMessage } from "interfaces/errors";
import { useAppQuery } from "apis/queryClient";
import { ordersService } from "apis/services";
import type { OrdersResponse } from "interfaces/api-responses";

export const fetchOrders = async () => {
  const { data } = await API.get(ordersService.orders);

  return data;
};

export const useOrders = () => {
  return useAppQuery<OrdersResponse[], ErrorMessage>({
    queryKey: () => activityKeys.orders,
    queryFn: async () => await fetchOrders(),
    options: {
      onError: () => {
        return {
          message: "Hubo un error para cargar las tenencias.",
          status: 500,
        };
      },
    },
  });
};
