import type { ReactNode } from "react";

import Text, { TextVariants } from "components/common/Text";
import {
  Cancelled,
  Filled,
  PartiallyCancelled,
  PartiallyExecuted,
  Pending,
  Rejected,
} from "assets/icons/OrderStatus";
import FilledButton from "components/common/FilledButton";

import styles from "./styles.module.scss";

interface StatusInfoType {
  icon: ReactNode;
  title: string;
  detail: string;
}

const STATUS_DETAILS: StatusInfoType[] = [
  {
    icon: <Pending />,
    title: "Pendiente de ejecución",
    detail: "La órden ha sido aceptada y está activa en el mercado.",
  },
  {
    icon: <PartiallyExecuted />,
    title: "Ejecutada parcialmente",
    detail:
      "La órden ha sido ejecutada parcialmente y sigue activa en el mercado.",
  },
  {
    icon: <Filled />,
    title: "Ejecutada",
    detail: "La órden ha sido ejecutada completamente.",
  },
  {
    icon: <PartiallyCancelled />,
    title: "Cancelada parcialmente",
    detail: "La órden ha sido cancelada con una ejecución parcial.",
  },
  {
    icon: <Cancelled />,
    title: "Cancelada",
    detail: "La órden ha sido cancelada sin haberse ejecutado.",
  },
  {
    icon: <Rejected />,
    title: "Rechazada",
    detail: "La órden ha sido rechazada por el mercado.",
  },
];

const StatusModalSlider = ({ onClose }: { onClose: () => void }) => (
  <div className={styles.statusModalSlider}>
    <Text
      variant={TextVariants.RegularText}
      className={styles.statusHeading}
      color="var(--slate900)"
    >
      Estados de una orden
    </Text>
    {STATUS_DETAILS.map(({ icon, title, detail }) => (
      <div key={title} className={styles.statusDetail}>
        <div>{icon}</div>
        <div>
          <Text variant={TextVariants.RegularText} color="var(--slate900)">
            {title}
          </Text>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            {detail}
          </Text>
        </div>
      </div>
    ))}
    <FilledButton onClick={onClose} className={styles.statusModalButton}>
      Entendido
    </FilledButton>
  </div>
);

export default StatusModalSlider;
