import React, { useEffect } from "react";

import { App } from "@capacitor/app";
import { lt } from "semver";
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from "capacitor-plugin-app-tracking-transparency";
import getPlatform from "utils/getPlatform";

const AppTracking: React.FC = () => {
  const { isIOS } = getPlatform();

  // We need to check the app version because the plugin is only available from version 2.2.0
  const targetVersion = "2.2.0";

  useEffect(() => {
    if (!isIOS) {
      return;
    }

    const getAppStatus = async () => {
      const { version } = await App.getInfo();

      if (lt(version, targetVersion)) {
        return null;
      }

      try {
        const trackingStatus: AppTrackingStatusResponse = await AppTrackingTransparency.getStatus();

        if (trackingStatus.status === "notDetermined") {
          await AppTrackingTransparency.requestPermission();
        }
      } catch (error) {
        console.error("Failed to get app tracking status:", error);
      }
    };

    getAppStatus();
  }, []);

  return null;
};

export default AppTracking;
