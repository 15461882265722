import FilledButton from "components/common/FilledButton";
import Text, { TextVariants } from "components/common/Text";
import { UIModalSlider } from "components/common/modal-slider";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";
import Clock from "assets/illustrations/Clock";
import mainRoutes from "router/routes";
import Heading, { HeadingVariants } from "components/common/Heading";
import { useCocosPay } from "context/CocosPayProvider";

import styles from "./styles.module.scss";

const ServiceErrorBottomSheet: React.FC = () => {
  const navigate = useNavigate();
  const { serviceError } = useCocosPay();

  if (!serviceError) return null;

  return (
    <UIModalSlider open={true} classes={styles.serviceErrorContainer}>
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        color="var(--slate900)"
        component="h2"
      >
        ¡Atención!
      </Heading>
      <Clock />
      <Text
        className={styles.message}
        variant={TextVariants.RegularTextS}
        color="var(--slate900)"
      >
        El proveedor esta presentando demoras. En unos minutos podrás acceder al
        servicio normalmente.
      </Text>
      <div className={styles.buttonsWrapper}>
        <FilledButton
          variant={ThemeVariants.Pay}
          onClick={() => navigate(mainRoutes.home)}
        >
          Volver al inicio
        </FilledButton>
      </div>
    </UIModalSlider>
  );
};

export default ServiceErrorBottomSheet;
