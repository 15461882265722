export const CDN_URL = import.meta.env.VITE_CDN_URL;

export const CryptoTagTyCUrl = `${CDN_URL}/reglamentos/11._TyC_Cocos_Tag_-_052924.pdf`;

export const CocosServiciosDigitalesTermsAndConditionsURL =
  "https://cocos.capital/terminos";

export const CryptoTermsAndConditionsURL = `${CDN_URL}/reglamentos/Crypto_Terminos_y_Condiciones.pdf`;

export const CapitalTermsAndConditionsURL = `${CDN_URL}/reglamentos/Capital_Terminos_y_Condiciones.pdf`;

export const CocosServiciosDigitalesPrivacyURL =
  "https://cocos.capital/privacy";

export const CryptoPrivacyURL = `${CDN_URL}/reglamentos/Crypto_privacy.pdf`;

export const CapitalPrivacyURL = `${CDN_URL}/reglamentos/Capital_privacy.pdf`;

export const CryptoCablePrivacyURL = `${CDN_URL}/reglamentos/Crypto_Bridge.pdf`;

export const CocosTariffURL = "https://cocos.capital/tarifario/";
export const COPY_MESSAGE_TIME = 2000;
export const DEFAULT_LONG_TICKER = "-0002-C-CT-ARS";

export const ACADEMY_URL = "https://university.cocos.capital/";

export const openMepTyC = `${CDN_URL}/reglamentos/tyc_open_mep.pdf`;

export const closedMepTyC = `${CDN_URL}/reglamentos/tyc_closed_mep.pdf`;

export const DOLAR_OPEN_MEP_DDJJ = `${CDN_URL}/reglamentos/DDJJ_Operatoria_Dolar_MEP_2025.pdf`;
export const DOLAR_DDJJ = `${CDN_URL}/reglamentos/DDJJ_Operatoria_Dolar.pdf`;

export const COCOS_BIZ_ONBOARDING_URL =
  "https://requirements.cocos.capital/?redirect=true";
