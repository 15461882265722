import { useEffect } from "react";

import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import px2rem from "utils/px2rem";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useAppDispatch } from "hooks/redux";
import SuccessIllustration from "assets/illustrations/Success";
import Amount from "components/common/Amount";
import { Currencies } from "interfaces/wallet";
import { ampli } from "ampli";

import styles from "./styles.module.scss";

interface SuccessProps {
  donatedAmount: number;
}

const Success: React.FC<SuccessProps> = ({ donatedAmount }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceCheck();

  const goToHome = () => {
    if (isMobile) {
      navigate(mainRoutes.home);
      return;
    }

    dispatch(closeRightModal());
  };

  useEffect(() => {
    ampli.donationOrderSuccess({ amount: donatedAmount });
  }, []);

  return (
    <BasicWrapper
      navigationHeaderProps={{
        title: "¡Listo!",
        onClick: goToHome,
      }}
      primaryButtonProps={{
        children: "Volver al inicio",
        onClick: goToHome,
      }}
    >
      <div className={styles.successWrapper}>
        <SuccessIllustration size={px2rem(84)} />
        <Text
          variant={TextVariants.RegularText}
          color="var(--slate800)"
          className={styles.text}
        >
          ¡Gracias por donar!
        </Text>
        <div className={styles.detailWrapper}>
          <Text variant={TextVariants.RegularText}>Monto</Text>
          <Amount amount={donatedAmount} currency={Currencies.ARS} />
        </div>
      </div>
    </BasicWrapper>
  );
};

export default Success;
