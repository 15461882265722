import type { ReactNode } from "react";

import styles from "./styles.module.scss";

type Icons = {
  name: string;
  icon: ReactNode;
};
interface OverlappedIconsProps {
  icons: Icons[];
}

const OverlappedIcons = ({ icons }: OverlappedIconsProps) => {
  return (
    <div className={styles.overlappedIcons}>
      {icons.map(({ name, icon }, index) => (
        <div
          key={name}
          className={styles.iconContainer}
          style={{
            marginLeft: index === 0 ? "0" : index !== 1 ? "-20%" : "-25%",
          }}
        >
          {icon}
        </div>
      ))}
    </div>
  );
};

export default OverlappedIcons;
