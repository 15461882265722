import BasicWrapper from "components/common/BasicWrapper";
import Text, { TextVariants } from "components/common/Text";
import { MessageCircleQuestion } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import px2rem from "utils/px2rem";

interface OrderDetailProps {
  id: string;
}

const OrderDetail: React.FC<OrderDetailProps> = ({ id }) => {
  const [, setSeachParams] = useSearchParams();

  const onCloseOrderDetail = () => {
    setSeachParams({}, { replace: true });
  };

  const title = "Orden de Subscripción"; //TODO: Get this dinamically once the endpoint is done

  return (
    <BasicWrapper
      navigationHeaderProps={{
        onClick: onCloseOrderDetail,
        title,
        rightIcon: (
          <MessageCircleQuestion size={px2rem(24)} color="var(--blue900)" />
        ),
      }}
    >
      <Text variant={TextVariants.RegularText}>{id}</Text>
    </BasicWrapper>
  );
};

export default OrderDetail;
