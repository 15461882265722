import React, { ReactNode } from "react";

import { LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

type StackedIconsProps = {
  smallIcon: LucideIcon;
  backgroundColor: string;
} & (
  | { logoUrl: string; withImage: true }
  | { icon: ReactNode; withImage: false }
);

const StackedIcons: React.FC<StackedIconsProps> = ({
  smallIcon: SmallIcon,
  backgroundColor,
  ...props
}) => {
  const Icon = !props.withImage && props.icon;
  return (
    <div className={styles.stackedIconsWrapper}>
      {props.withImage && <img className={styles.img} src={props.logoUrl} />}
      {Icon && <div className={styles.mainIconWrapper}>{Icon}</div>}
      <div className={styles.outerCircle}>
        <div className={styles.smallIconWrapper} style={{ backgroundColor }}>
          <SmallIcon color="var(--white-to-black)" size={px2rem(10)} />
        </div>
      </div>
    </div>
  );
};

export default StackedIcons;
