import ff from "constants/featureFlags";
import { cryptoStatus } from "constants/cryptos";

import Heading, { HeadingVariants } from "components/common/Heading";
import { Currencies } from "interfaces/wallet";
import { useAppSelector } from "hooks/redux";
import useFeatureFlag from "hooks/useFeatureFlag";
import { ACTIVE_BID } from "components/common/bid/constants";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import HighlightedBanner from "components/common/HighlightedBanner";
import { HandHeart } from "lucide-react";
import Text, { TextVariants } from "components/common/Text";
import { ampli } from "ampli";
import { getLoggedUser } from "store/selectors/user.selector";

import HighlightedInvestment from "./HighlightedInvestment";
import PixBanner from "./PixBanner";

import styles from "./styles.module.scss";

const HighlightedsSection: React.FC = () => {
  const navigate = useNavigate();
  const { investmentSuggested } = useAppSelector((state) => state.markets);
  const user = useAppSelector(getLoggedUser);

  const { isFeatureEnabled: isBidEnabled } = useFeatureFlag(ff.ENABLE_BID);
  const { isFeatureEnabled: isDonationEnabled } = useFeatureFlag(
    ff.ENABLE_DONATION
  );

  const isDonationVisible =
    isDonationEnabled && user?.status_crypto === cryptoStatus.APPROVED;

  const onClickInvestmentSuggestion = () => {
    ampli.homeExited({ referral: "suggested_investment" });
    navigate(mainRoutes.investment);
  };

  const onClickBid = () => {
    ampli.homeExited({ referral: "bid" });
    navigate(mainRoutes.bid);
  };

  const onClickDonate = () => {
    ampli.homeExited({ referral: "donation" });
    navigate(mainRoutes.donate);
  };

  return (
    <div className={styles.highlightedsWrapper}>
      <Heading
        component="h2"
        color="var(--slate800)"
        className={styles.header}
        variant={HeadingVariants.RegularSubtitle}
      >
        Destacados
      </Heading>
      <div className={styles.highlighteds}>
        {isDonationVisible && (
          <HighlightedBanner
            color="red"
            onClick={onClickDonate}
            heading="Doná por los incendios en la Patagonia"
            icon={HandHeart}
          >
            <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
              Colaborás con la recuperación de hogares y comunidades afectadas
              por el fuego.
            </Text>
          </HighlightedBanner>
        )}
        <PixBanner />
        {investmentSuggested[0] && (
          <HighlightedInvestment
            tir={investmentSuggested[0].tir}
            currency={
              investmentSuggested[0].long_ticker.slice(-3) as Currencies
            }
            settlement="24 meses"
            onClick={onClickInvestmentSuggestion}
          />
        )}
        {isBidEnabled && (
          <HighlightedInvestment
            title="Licitación Aeropuertos Argentina"
            onClick={onClickBid}
            tir={ACTIVE_BID.tir}
            settlement={ACTIVE_BID.settlement}
            currency={ACTIVE_BID.currency}
            isTirEstimated
          />
        )}
      </div>
    </div>
  );
};

export default HighlightedsSection;
