import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { Operations } from "interfaces/crypto/enums";
import type { LucideIcon } from "lucide-react";
import type { BalanceCurrencies } from "interfaces/wallet";
import ARGFlag from "assets/icons/ARGFlag";
import Amount from "components/common/Amount";
import { OperationSide } from "interfaces/orders/enums";
import StackedIcons from "components/common/StackedIcons";

import SecondaryInstrument from "./SecondaryInstrument";

import styles from "./styles.module.scss";

export type CapitalActivityCardProps = {
  amount: number;
  currency: BalanceCurrencies;
  heading: string;
  isBalanceHidden?: boolean;
  mainIcon: React.ReactNode;
  onClick: () => void;
  operationSide: OperationSide;
  quantity?: number;
  settlementDate?: string;
  smallIcon: LucideIcon;
  isMepOperation: boolean;
  ticker: string;
};

const CapitalActivityCard: React.FC<CapitalActivityCardProps> = (props) => {
  const {
    amount,
    currency,
    heading,
    isBalanceHidden,
    isMepOperation,
    mainIcon = <ARGFlag />,
    onClick,
    operationSide,
    quantity,
    settlementDate,
    smallIcon,
    ticker,
  } = props;

  const showMinusOnAmount =
    operationSide === OperationSide.BUY ||
    operationSide === OperationSide.TAKER ||
    operationSide === OperationSide.BID ||
    operationSide === OperationSide.SUBSCRIPTION;

  const usdQuantity = `US$${quantity}`;

  return (
    <div className={styles.activityCardWrapper} onClick={onClick}>
      <div className={styles.iconsWrapper}>
        <StackedIcons
          withImage={false}
          icon={mainIcon}
          smallIcon={smallIcon}
          backgroundColor="var(--blue800)"
        />
      </div>
      <div className={styles.rowsWrapper}>
        <div className={styles.mainRow}>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            component="h3"
            color="var(--slate900)"
          >
            {heading}
          </Heading>
          <div className={styles.amountIcon}>
            {showMinusOnAmount ? "-" : undefined}
            <Amount
              amount={amount}
              currency={currency}
              isBalanceHidden={isBalanceHidden}
              isSmallCurrency
            />
          </div>
        </div>
        <div className={styles.secondaryRow}>
          <div className={styles.secondaryTextWrapper}>
            <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
              {ticker}
            </Text>
            {settlementDate && (
              <Text
                variant={TextVariants.RegularTextS}
                color="var(--slate800)"
                className={styles.settlementDivider}
              >
                {settlementDate}
              </Text>
            )}
          </div>
          {quantity && (
            <>
              {isMepOperation ? (
                <Text
                  variant={TextVariants.RegularTextS}
                  color="var(--slate800)"
                >
                  {!showMinusOnAmount ? "-" : undefined}
                  {usdQuantity}
                </Text>
              ) : (
                <SecondaryInstrument
                  isCapital
                  baseInstrument={ticker}
                  baseAmount={quantity}
                  operationType={
                    showMinusOnAmount ? Operations.SWAP : Operations.SELL
                  }
                  quoteAmount={quantity}
                  isBalanceHidden={isBalanceHidden}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CapitalActivityCard;
