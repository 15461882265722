import Variation from "components/common/CurrentVariation";

interface TotalVariatonProps {
  totalVariation?: number;
}

const TotalVariation: React.FC<TotalVariatonProps> = ({ totalVariation }) => {
  if (!totalVariation) return <div>--</div>;

  return (
    <div>
      <Variation value={totalVariation} />
    </div>
  );
};

export default TotalVariation;
