import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";
import { ChevronDown } from "lucide-react";

import styles from "./styles.module.scss";

interface NumbersBadgeProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  variant?: ThemeVariants;
  isDropdownVisible?: boolean;
  isDropdownExpanded?: boolean;
  label?: string;
  number?: number | string;
}

const NumbersBadge: React.FC<NumbersBadgeProps> = ({
  variant = ThemeVariants.Capital,
  label,
  number,
  isDropdownVisible,
  isDropdownExpanded = false,
  className,
  ...props
}) => (
  <div
    className={classNames(
      styles.NumbersBadgeContainer,
      styles[variant],
      className
    )}
    {...props}
  >
    {label && (
      <Text variant={TextVariants.SemiboldTextS} color="var(--slate800)">
        {label}&nbsp;
      </Text>
    )}
    {number && (
      <Text className={styles.text} variant={TextVariants.SemiboldUpperValueS}>
        {number}
      </Text>
    )}
    {isDropdownVisible && (
      <ChevronDown
        size={16}
        color="var(--slate800)"
        style={{
          rotate: isDropdownExpanded ? "-180deg" : "0deg",
          transition: "all ease-in-out .3s",
        }}
      />
    )}
  </div>
);

export default NumbersBadge;
