import { ReactNode } from "react";

import { IconProps } from "assets/icons/BTC";
import Text, { TextVariants } from "components/common/Text";
import px2rem from "utils/px2rem";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface ChipProps {
  icon: React.FC<IconProps>;
  primaryText: string | number;
  secondaryText: string | number;
  onClick: () => void;
}

const Chip: React.FC<ChipProps> = ({
  icon: Icon,
  primaryText,
  secondaryText,
  onClick,
}) => {
  return (
    <button className={styles.chipContainer} onClick={onClick}>
      <Icon size={px2rem(24)} />
      <div className={styles.labels}>
        <Text
          variant={TextVariants.SemiboldUpperValueS}
          color="var(--slate900)"
        >
          {primaryText}
        </Text>
        <Text
          variant={TextVariants.SemiboldUpperValueS}
          color="var(--slate800)"
        >
          {secondaryText}
        </Text>
      </div>
    </button>
  );
};

interface FilledChipProps {
  icon?: ReactNode;
  primaryText: string | number;
  onClick: () => void;
  isActive: boolean;
}

export const FilledChip = ({
  icon,
  primaryText,
  onClick,
  isActive,
}: FilledChipProps) => (
  <button
    className={classNames(styles.chipContainer, styles.filledChipContainer, {
      [styles.isChipActive]: isActive,
    })}
    onClick={onClick}
  >
    {icon ? (
      <div className={classNames({ [styles.iconContainer]: isActive })}>
        {icon}
      </div>
    ) : undefined}

    <div className={styles.labels}>
      <Text variant={TextVariants.RegularTextS}>{primaryText}</Text>
    </div>
  </button>
);

export default Chip;
