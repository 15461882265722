import { ArrowDownToLineIcon, RefreshCcw } from "lucide-react";
import px2rem from "utils/px2rem";
import ARGFlag from "assets/icons/ARGFlag";
import USFlag from "assets/icons/USFlag";
import classNames from "classnames";

import BottomShackle from "./BottomShackle";
import TopShackle from "./TopShackle";
import Center from "./Center";

import styles from "./styles.module.scss";

interface CableObBackgroundProps {
  isCapital?: boolean;
}

const CableObBackground: React.FC<CableObBackgroundProps> = ({
  isCapital = false,
}) => {
  const themeColor = isCapital ? "var(--blue900)" : "var(--purple900)";

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <TopShackle />
        <BottomShackle />
      </div>
      <div className={classNames(styles.iconsWrapper, styles.bottomIcons)}>
        <RefreshCcw
          color={themeColor}
          size={px2rem(20)}
          className={styles.icon}
        />
        <ARGFlag size={px2rem(24)} className={styles.icon} />
      </div>
      <div className={classNames(styles.iconsWrapper, styles.topIcons)}>
        <USFlag size={px2rem(24)} className={styles.icon} />
        <ArrowDownToLineIcon
          size={px2rem(20)}
          className={styles.icon}
          color={themeColor}
        />
      </div>
      <Center isCapital={isCapital} />
    </div>
  );
};

export default CableObBackground;
