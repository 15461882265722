import { SettlementIndex } from "constants/portfolio";

import React from "react";

import { BalanceCurrencies } from "interfaces/wallet";
import classNames from "classnames";
import { useAppState } from "context/AppStateProvider";
import type { PortfolioHoldings } from "interfaces/portfolio";

import Text, { TextVariants } from "../Text";
import SettlmentsDetails from "./SettlementDetails";
import Logo from "./Logo";
import Quantity from "./Quantity";
import Price from "./Price";
import Amount from "./Amount";
import AllocationPercentage from "./AllocationPercentage";
import AverageCost from "./AverageCost";
import DailyVariation from "./DailyVariation";
import DailyPerformance from "./DailyPerformance";
import TotalVariation from "./TotalVariation";
import TotalPerformance from "./TotalPerformance";

import styles from "./styles.module.scss";

interface AssetPortfolioRowProps {
  onClick: () => void;
  averagePrice?: number;
  totalVariation?: number;
  dailyVariation?: number;
  dailyPerformance?: number;
  totalPerformance?: number;
  holding: PortfolioHoldings;
  currency: BalanceCurrencies;
  isSettlementExpanded: boolean;
}

const AssetPortfolioRow: React.FC<AssetPortfolioRowProps> = ({
  holding,
  onClick,
  currency,
  averagePrice,
  totalVariation,
  dailyVariation,
  totalPerformance,
  dailyPerformance,
  isSettlementExpanded,
}) => {
  const { isBalanceHidden } = useAppState();

  const {
    allocation,
    logo,
    shortName,
    name,
    longTicker,
    price,
    settlements,
    ticker,
    isCrypto,
    type,
  } = holding;
  const mappedSettlements = settlements.slice(0, 2);

  return (
    <div className={styles.assetWrapper} onClick={onClick}>
      <div className={styles.tickerNameRow}>
        <Logo
          isCrypto={isCrypto}
          logo={logo}
          ticker={ticker ? ticker : name}
          type={type}
        />
        <Text
          color="var(--slate800)"
          variant={TextVariants.RegularTextS}
          className={styles.text}
        >
          {shortName}
        </Text>
      </div>
      <Quantity settlements={settlements} />
      <Price price={price} />
      {settlements[SettlementIndex.INF].amount ? (
        <Amount
          amount={settlements[SettlementIndex.INF].amount}
          currency={currency}
        />
      ) : (
        <div>
          <Text variant={TextVariants.RegularText}>--</Text>
        </div>
      )}
      <AllocationPercentage allocation={allocation} />
      <AverageCost averageCost={averagePrice} />
      <DailyVariation longTicker={longTicker} variation={dailyVariation} />
      <DailyPerformance performance={dailyPerformance} />
      <TotalVariation totalVariation={totalVariation} />
      <TotalPerformance totalPerformance={totalPerformance} />
      <div
        className={classNames(styles.settlementsDetailsContainer, {
          [styles.expanded]: isSettlementExpanded,
          [styles.collapsed]: !isSettlementExpanded,
        })}
      >
        {mappedSettlements.map(({ quantity, amount, period }) => (
          <SettlmentsDetails
            key={period}
            currency={currency}
            period={period}
            quantity={quantity}
            amount={amount}
            isBalanceHidden={isBalanceHidden}
          />
        ))}
      </div>
    </div>
  );
};

export default AssetPortfolioRow;
