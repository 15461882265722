import { useState } from "react";

import Text, { TextVariants } from "components/common/Text";
import { ArrowUpRight } from "lucide-react";
import { UIModalSlider } from "components/common/modal-slider";
import classNames from "classnames";
import getPlatform from "utils/getPlatform";

import StatusModalSlider from "./StatusModalSlider";

import styles from "./styles.module.scss";

const BottomText = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isIOS } = getPlatform();

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className={styles.bottomText}>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          No hay más órdenes por mostrar.
        </Text>
        <div className={styles.linkContainer} onClick={() => setIsOpen(true)}>
          <Text variant={TextVariants.SemiboldTextS} color="var(--blue800)">
            Estados de una órden
          </Text>
          <ArrowUpRight color="var(--blue800)" />
        </div>
      </div>
      <UIModalSlider
        open={isOpen}
        onToggleDisplay={onClose}
        classes={classNames(styles.bottomSheet, { [styles.ios]: isIOS })}
      >
        <StatusModalSlider onClose={onClose} />
      </UIModalSlider>
    </>
  );
};

export default BottomText;
