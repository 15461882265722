import { cryptoStatus } from "constants/cryptos";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { cryptoSocket, socket } from "socket";
import { Cookies } from "react-cookie";
import { User } from "@supabase/supabase-js";
import { UserMeResponse } from "interfaces/api-responses";

import {
  check2FA,
  checkTrustedDevice,
  getInvestorProfileData,
  loginUser,
  updateCableNotification,
} from "./userActions";
import { Investor, Tiers, UserData } from "../../interfaces/user";
import { ErrorMessage } from "../../interfaces/errors";

export interface UserForm {
  email: string;
  phone: string;
  referralCode: string;
}

export interface Comission {
  instrument_type: string;
  instrument_subtype?: string;
  operation_type?: string;
  comision_porcentual: number;
  monto_minimo?: number;
}

export interface UserInitialState {
  user: UserData | null;
  errorMessage: ErrorMessage;
  loading: boolean;
  investorProfile: boolean;
  investorData: Investor | null;
  isFirstLogin: boolean;
  form: UserForm;
  tier: Tiers;
  duty: Comission[];
  has2FA: boolean;
  showTrustedDeviceQuestion: boolean | null;
}

export const initialState: UserInitialState = {
  user: null,
  errorMessage: {
    message: "",
    status: null,
  },
  loading: false,
  investorProfile: true,
  investorData: {
    expiration_date: "",
    expiration_days: "",
    risk_profile: "",
  },
  isFirstLogin: false,
  form: {
    email: "",
    phone: "",
    referralCode: "",
  },
  tier: Tiers.FREE,
  duty: [],
  has2FA: false,
  showTrustedDeviceQuestion: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    logOutUser(state) {
      state.user = null;
    },
    setUserData(state, action: PayloadAction<UserData>) {
      state.user = action.payload;
    },
    updateCryptoStatus(state, action: PayloadAction<cryptoStatus>) {
      if (state.user) {
        state.user.status_crypto = action.payload;
      }
    },
    updateSignInWithBiometrics(state, action: PayloadAction<boolean>) {
      if (state.user) {
        state.user.signInWithBiometrics = action.payload;
      }
    },
    setUserError(state, action: PayloadAction<ErrorMessage>) {
      state.errorMessage = action.payload;
    },
    closeModalInvestor(state, action: PayloadAction<boolean>) {
      state.investorProfile = action.payload;
    },
    updateHas2FA(state, action: PayloadAction<boolean>) {
      state.has2FA = action.payload;
    },
    updateShowTrustedDeviceQuestion(state, action: PayloadAction<boolean>) {
      state.showTrustedDeviceQuestion = action.payload;
    },
    updateTyCAccepted(state, action: PayloadAction<boolean>) {
      if (state.user) {
        state.user.latest_tnc_accepted = action.payload;
      }
    },
    updateDarkTheme(state, action: PayloadAction<boolean>) {
      if (state.user && action.payload !== undefined) {
        state.user.dark_theme = action.payload;
      }
    },
    updateTag(state, action: PayloadAction<string | null>) {
      if (state.user) {
        state.user = {
          ...state.user,
          tag: action.payload,
        };
      }
    },
    fetchUserData(
      state,
      action: PayloadAction<{
        user: User;
        userData: UserMeResponse;
      }>
    ) {
      const { user, userData } = action.payload;
      const cookies = new Cookies();

      socket.connect();
      cryptoSocket.connect();

      // User with no idAccount will send 0 by default
      const firstOrDefaultAccount = userData.id_accounts[0] || 0;

      if (!cookies.get("id_account")) {
        cookies.set("id_account", firstOrDefaultAccount, { path: "/" });
      } else if (
        !userData.id_accounts.some(
          (id_account) => id_account === Number(cookies.get("id_account"))
        )
      ) {
        cookies.set("id_account", firstOrDefaultAccount, { path: "/" });
      }

      if (userData.dark_theme) {
        document.documentElement.classList.add("dark");
      }

      state.user = { ...userData, ...user, hasAccount: !!userData.account?.id };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getInvestorProfileData.fulfilled,
        (state, action: PayloadAction<Investor | undefined>) => {
          if (action.payload) {
            state.investorData = action.payload;
          } else {
            state.errorMessage = {
              message: "Error investor data is undefined",
              status: 500,
            };
          }
        }
      )
      .addCase(check2FA.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.has2FA = action.payload;
      })
      .addCase(check2FA.rejected, (state, { payload }) => {
        state.has2FA = payload ?? false;
      })
      .addCase(
        checkTrustedDevice.fulfilled,
        (state, action: PayloadAction<boolean | null>) => {
          state.showTrustedDeviceQuestion = action.payload;
        }
      )
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(
        updateCableNotification.fulfilled,
        (state, action: PayloadAction<UserData>) => {
          state.user = action.payload;
        }
      );
  },
});

export const {
  closeModalInvestor,
  fetchUserData,
  logOutUser,
  setLoading,
  setUserData,
  setUserError,
  updateCryptoStatus,
  updateHas2FA,
  updateShowTrustedDeviceQuestion,
  updateSignInWithBiometrics,
  updateDarkTheme,
  updateTag,
  updateTyCAccepted,
} = userSlice.actions;

export default userSlice.reducer;
