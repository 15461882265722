import { useState } from "react";

import { ampli } from "ampli";
import { BankAccount } from "interfaces/crypto";
import { ThemeVariants } from "interfaces/theme";
import { UIModalSlider } from "components/common/modal-slider";
import DetailRow from "components/common/DetailRow";
import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";

import styles from "./styles.module.scss";

type InfoModal = {
  trackingItemName: string;
  title: string;
  info: string;
};

type InfoModals = {
  BankName: InfoModal;
  AccountNumber: InfoModal;
  RoutingNumber: InfoModal;
};

enum copyTrackingItems {
  ACCOUNT_HOLDER = "account_holder",
  BANK_NAME = "bank_name",
  BANK_ADDRESS = "bank_address",
  ZIP_CODE = "zip_code",
  ROUTING_NUMBER = "routing_number",
  ACCOUNT_NUMBER = "account_number",
  ACCOUNT_TYPE = "account_type",
}

const infoModals: InfoModals = {
  BankName: {
    trackingItemName: "bank_name",
    title: "¿Por qué no es un banco de Cocos?",
    info: "Nuestra cuenta de procesamiento está a cargo de Lead Bank.",
  },
  AccountNumber: {
    trackingItemName: "account_number",
    title: "¿Qué es el account number (número de cuenta)?",
    info:
      "Es un identificador numérico único asignado a tu cuenta en USD para poder recibir depósitos en USD a través de la red ACH o mediante transferencias domésticas a través de Fedwire.",
  },
  RoutingNumber: {
    trackingItemName: "routing_number",
    title: "¿Qué es el routing number (número de ruta)?",
    info:
      "Es una identificación única de 9 dígitos asignada a cada institución bancaria que acepta depósitos ACH. Es necesario para que los bancos identifiquen de dónde deben recibir y enviar los pagos.",
  },
};

interface AccountInfoProps {
  bankDetails: BankAccount;
  isCapital?: boolean;
}

const AccountInfo: React.FC<AccountInfoProps> = ({
  bankDetails,
  isCapital,
}) => {
  const [modalInfo, setModalInfo] = useState<{
    trackingItemName: string;
    title: string;
    info: string;
  }>();

  const {
    sourceBankName,
    sourceBankAddress,
    sourceBankRoutingNumber,
    sourceBankRoutingAccount,
    accountHolderFirstName,
    accountHolderLastName,
  } = bankDetails;

  const lastCommaIndex = sourceBankAddress.lastIndexOf(",");
  const address = sourceBankAddress.substring(0, lastCommaIndex).trim();
  const zipCode = sourceBankAddress.substring(lastCommaIndex + 1).trim();
  const holderName = `${accountHolderFirstName} ${accountHolderLastName}`;

  const onClickInfo = ({ itemName }: { itemName: any }) => {
    ampli.cclCryptoMoreInfo({ item: itemName.trackingItemName });
    setModalInfo(itemName);
  };

  const variant = isCapital ? ThemeVariants.Capital : ThemeVariants.Crypto;

  return (
    <>
      <ul className={styles.list}>
        <DetailRow
          withCopy
          copyTrackingItem={copyTrackingItems.ACCOUNT_HOLDER}
          label="Account holder"
          value={holderName}
          variant={variant}
        />
        <DetailRow
          withCopy
          copyTrackingItem={copyTrackingItems.BANK_NAME}
          withInfo
          label="Bank name"
          value={sourceBankName}
          onClickInfo={() => onClickInfo({ itemName: infoModals.BankName })}
          variant={variant}
        />
        <DetailRow
          withCopy
          copyTrackingItem={copyTrackingItems.BANK_ADDRESS}
          label="Bank address"
          value={address}
          variant={variant}
        />
        <DetailRow
          withCopy
          copyTrackingItem={copyTrackingItems.ZIP_CODE}
          label="Zip code"
          value={zipCode}
          variant={variant}
        />
        <DetailRow
          withInfo
          withCopy
          copyTrackingItem={copyTrackingItems.ROUTING_NUMBER}
          value={sourceBankRoutingNumber}
          label="Routing number"
          onClickInfo={() =>
            onClickInfo({ itemName: infoModals.RoutingNumber })
          }
          variant={variant}
        />
        <DetailRow
          withCopy
          copyTrackingItem={copyTrackingItems.ACCOUNT_TYPE}
          value="Checking"
          label="Account type"
          variant={variant}
        />
        <DetailRow
          withInfo
          withCopy
          copyTrackingItem={copyTrackingItems.ACCOUNT_NUMBER}
          value={sourceBankRoutingAccount}
          label="Account number"
          onClickInfo={() =>
            onClickInfo({ itemName: infoModals.AccountNumber })
          }
          variant={variant}
        />
      </ul>
      <UIModalSlider
        open={!!modalInfo}
        classes={styles.sheetWrapper}
        onToggleDisplay={() => setModalInfo(undefined)}
      >
        <div className={styles.sheetContent}>
          <Text
            color="var(--slate900)"
            className={styles.title}
            variant={TextVariants.RegularText}
          >
            {modalInfo?.title}
          </Text>
          <Text
            variant={TextVariants.RegularText}
            color="var(--slate800)"
            className={styles.info}
          >
            {modalInfo?.info}
          </Text>
          <FilledButton
            className={styles.sheetButton}
            variant={variant}
            onClick={() => setModalInfo(undefined)}
          >
            Entendido
          </FilledButton>
        </div>
      </UIModalSlider>
    </>
  );
};

export default AccountInfo;
