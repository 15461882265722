import { useEffect, useRef, useState } from "react";

import { Capacitor } from "@capacitor/core";
import { ampli } from "ampli";
import API from "apis";
import { walletService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import PayIcon from "assets/icons/PayIcon";
import ErrorPage from "components/common/ErrorPage";
import FilledButton from "components/common/FilledButton";
import Footer from "components/common/Footer";
import LoadingSpinner from "components/common/LoadingSpinner";
import PageHeader from "components/common/PageHeader";
import dayjs from "dayjs";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { CashMovementResponse, PayMovement } from "interfaces/movements";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { shareScreenshot } from "utils/share";
import { useAppDispatch } from "hooks/redux";
import PayReceiptDetail from "components/common/PayReceipt";

import styles from "./styles.module.scss";

const PayReceipt: React.FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceCheck();

  const isNative = Capacitor.isNativePlatform();

  const [receiptData, setReceiptData] = useState<PayMovement>();
  const [error, setError] = useState<boolean>(false);

  const shareRef = useRef<HTMLDivElement>(null);

  const shareId = "for-share";

  useEffect(() => {
    const getReceiptData = async () => {
      try {
        const { data } = await API.get<CashMovementResponse>(
          `${walletService.cashMovements}/${id}`
        );

        setReceiptData(data.detail);
      } catch (error) {
        setError(true);
      }
    };

    getReceiptData();
  }, []);

  const handleOnClose = () => {
    if (isMobile) {
      return navigate(-1);
    }

    return dispatch(closeRightModal());
  };

  const onClickDownload = () => {
    if (!receiptData) return;

    const {
      quantity,
      paymentMethod,
      nameDestination,
      idPayment,
      createdAt,
    } = receiptData;

    ampli.payDownloadReceipt({
      amount: quantity,
      method: paymentMethod,
      paid_to: nameDestination,
      receipt_number: idPayment,
      date: dayjs(createdAt).format("DD/MM/YYYY"),
      time: dayjs(createdAt).format("HH:mm"),
      referral: "movement",
    });

    shareScreenshot(shareRef.current, shareId, `pago-${nameDestination}`);
  };

  if (error) {
    return (
      <ErrorPage
        capitalTheme
        withClose
        errorMessage="En este momento no es posible mostrar esta página."
      />
    );
  }

  if (!receiptData) {
    return <LoadingSpinner variant={ThemeVariants.Capital} />;
  }

  return (
    <div className={styles.payReceiptContainer}>
      <PageHeader
        icon={X}
        title="Comprobante de pago"
        onClick={handleOnClose}
        className={styles.header}
      />
      <div className={styles.body} ref={shareRef} id={shareId}>
        <PayIcon color="var(--black-to-white)" />
        <PayReceiptDetail
          businessName={receiptData.nameDestination.trim()}
          createdAt={receiptData.createdAt}
          paymentMethod={receiptData.paymentMethod}
          idPayment={receiptData.idPaymentShort}
          idBankTransaction={receiptData.idBankTransaction}
          transactionQuantity={receiptData.transactionQuantity}
          transactionCurrency={receiptData.transactionCurrency}
          localQuantity={receiptData.localQuantity}
        />
      </div>
      {isNative && (
        <Footer>
          <FilledButton variant={ThemeVariants.Pay} onClick={onClickDownload}>
            Descargar comprobante
          </FilledButton>
        </Footer>
      )}
    </div>
  );
};

export default PayReceipt;
