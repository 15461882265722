import { ampli } from "ampli";
import classNames from "classnames";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import {
  MAX_DEBT,
  MIN_BRL_TO_PAY,
  MIN_USD_TO_PAY,
} from "components/page-cocos-pay/utils";
import { useCocosPay } from "context/CocosPayProvider";
import { PaymentCurrencies, PaymentMethodErrors } from "interfaces/pay/enums";
import { PaymentMethod } from "interfaces/pay/interface";
import { isEmpty } from "lodash";
import { Info } from "lucide-react";
import px2rem from "utils/px2rem";
import { formatToCurrency } from "utils";
import { Currencies } from "interfaces/wallet";

import MethodCard from "./MethodCard";

import styles from "./styles.module.scss";

const MethodsList: React.FC = () => {
  const {
    setSelectedMethod,
    availableMethods,
    paymentMethodMessages,
    paymentData,
  } = useCocosPay();

  const { transactionCurrency, quantity } = paymentData;

  const isPix = transactionCurrency === Currencies.BRL;

  const [methodsWithFunds, methodsWithoutFunds] = availableMethods.reduce<
    [PaymentMethod[], PaymentMethod[]]
  >(
    ([trueArray, falseArray], item) => {
      if (!item.hasEnoughFunds) {
        falseArray.push(item);
      } else {
        trueArray.push(item);
      }
      return [trueArray, falseArray];
    },
    [[], []]
  );

  const notAbleToPayWithPix = isPix && quantity < MIN_BRL_TO_PAY;

  const hasUSD = availableMethods.some(
    (method: PaymentMethod) => method.name === PaymentCurrencies.USD
  );

  const noBalance = isEmpty(methodsWithFunds);

  const handleOnClick = (currency: PaymentCurrencies) => {
    const selectedMethod = methodsWithFunds?.find(
      (obj) => obj.name === currency
    );
    if (!selectedMethod) return;
    ampli.paySelectMethod({ method: selectedMethod.name });
    setSelectedMethod(selectedMethod);
  };

  const showExtraLabel = () => {
    const hasDebt = paymentMethodMessages.includes(
      PaymentMethodErrors.ARS_DEBT
    );

    if (hasDebt) {
      return (
        <Text
          className={styles.noBalance}
          variant={TextVariants.RegularTextS}
          color="var(--red800)"
        >
          Para poder realizar un pago tu deuda no debe superar los ARS{" "}
          {formatToCurrency(MAX_DEBT, true)}
        </Text>
      );
    }
    if (notAbleToPayWithPix) {
      return (
        <div className={styles.disclaimer}>
          <Info size={px2rem(16)} color="var(--black-to-white)" />
          <Text variant={TextVariants.SemiboldTextS} color="var(--slate900)">
            Monto mínimo para pagar con Pix es R$ {MIN_BRL_TO_PAY}
          </Text>
        </div>
      );
    }
    if (!hasUSD) {
      return (
        <div className={styles.disclaimer}>
          <Info size={px2rem(16)} color="var(--black-to-white)" />
          <Text variant={TextVariants.SemiboldTextS} color="var(--slate900)">
            Monto mínimo para pagar con dólares es US$ {MIN_USD_TO_PAY}
          </Text>
        </div>
      );
    }
    if (noBalance) {
      return (
        <Text
          className={styles.noBalance}
          variant={TextVariants.RegularTextS}
          color="var(--red800)"
        >
          Ingresá dinero para poder realizar el pago
        </Text>
      );
    }

    return;
  };

  return (
    <div className={styles.methodsListContainer}>
      {!noBalance && (
        <>
          <Heading
            variant={HeadingVariants.RegularTitle}
            component="h2"
            color="var(--slate800)"
          >
            ¿Cómo vas a pagar?
          </Heading>
          <div className={classNames(styles.list, styles.available)}>
            {methodsWithFunds.map(
              ({
                name,
                amount,
                amountAvailableArs,
                amountAvailableUsd,
                price,
              }) => (
                <MethodCard
                  key={name}
                  currency={name}
                  availableArs={amountAvailableArs}
                  availableUsd={amountAvailableUsd}
                  price={price}
                  amount={amount}
                  onClick={() => handleOnClick(name)}
                  disabled={notAbleToPayWithPix}
                />
              )
            )}
          </div>
        </>
      )}
      {!isEmpty(methodsWithoutFunds) && (
        <>
          <Heading
            variant={HeadingVariants.RegularTitle}
            component="h2"
            color="var(--slate800)"
          >
            Dinero insuficiente
          </Heading>
          <div className={styles.list}>
            {methodsWithoutFunds.map(
              ({
                name,
                amount,
                amountAvailableArs,
                amountAvailableUsd,
                price,
              }) => (
                <MethodCard
                  key={name}
                  currency={name}
                  availableArs={amountAvailableArs}
                  availableUsd={amountAvailableUsd}
                  amount={amount}
                  price={price}
                  disabled
                />
              )
            )}
          </div>
        </>
      )}
      {showExtraLabel()}
    </div>
  );
};

export default MethodsList;
