import { CocosServiciosDigitalesTermsAndConditionsURL } from "constants/index";

import { MouseEvent } from "react";

import FilledButton from "components/common/FilledButton";
import Text, { TextVariants } from "components/common/Text";
import { isValidEmail, isValidPassword } from "components/page-sign-up/utils";
import { Browser } from "@capacitor/browser";
import Footer from "components/common/Footer";
import useKeyboardHeight from "hooks/useKeyboardHeight";
import classNames from "classnames";

import CocosBizBanner from "./CocosBizBanner";

import styles from "./styles.module.scss";

interface EmailPasswordConfirmProps {
  email: string;
  password: string;
  isLoading: boolean;
  registerEmail: (event: MouseEvent<HTMLButtonElement>) => void;
}

const EmailPasswordConfirm: React.FC<EmailPasswordConfirmProps> = ({
  email,
  password,
  isLoading,
  registerEmail,
}) => {
  const { keyboardHeight } = useKeyboardHeight();
  const isKeyboardOpen = keyboardHeight > 0;

  const onClickTyC = () => {
    Browser.open({
      url: CocosServiciosDigitalesTermsAndConditionsURL,
    });
  };

  const MIN_PASSWORD_LENGTH = 3;

  return (
    <div className={styles.footerContainer}>
      <div
        className={classNames(styles.EmailPasswordConfirmContainer, {
          [styles.keyboardOpen]: isKeyboardOpen,
        })}
      >
        <CocosBizBanner />
        <Text variant={TextVariants.RegularTextS} color="var(--slate600)">
          Al continuar, confirmás haber leido, das tu consentimiento y estás de
          acuerdo con los{" "}
          <Text
            component="span"
            onClick={onClickTyC}
            color="var(--blue800)"
            variant={TextVariants.SemiboldTextS}
          >
            Términos y Condiciones
          </Text>{" "}
          de Cocos y que sos mayor de edad.
        </Text>
      </div>
      <Footer className={styles.footer}>
        <FilledButton
          disabled={
            email.length < MIN_PASSWORD_LENGTH ||
            !isValidEmail(email) ||
            !isValidPassword(password)
          }
          onClick={registerEmail}
          isLoading={isLoading}
          type="submit"
        >
          Continuar
        </FilledButton>
      </Footer>
    </div>
  );
};

export default EmailPasswordConfirm;
