import { CDN_URL, DEFAULT_LONG_TICKER } from "constants/index";
import { SettlementIndex } from "constants/portfolio";

import { useEffect, useState } from "react";

import { ampli } from "ampli";
import FirstInvestment from "assets/illustrations/FirstInvestment";
import EmptyState, { EmptyButtonTypes } from "components/common/EmptyState";
import Heading, { HeadingVariants } from "components/common/Heading";
import MarketDetailSidebarDesktop from "components/common/market-detail-sidebar";
import TradingContainer from "components/common/trading-container";
import CapitalFunds from "components/page-capital-funds";
import { isCocosFundRedesign } from "components/page-capital-funds/helpers";
import AssetBalanceCard, {
  AssetBalanceVariants,
} from "components/page-portfolio/Value/AssetBalanceCard";
import { useAppState } from "context/AppStateProvider";
import {
  cleanHistoricalData,
  resetMarketDataDetail,
  resetOrder,
} from "features/markets";
import { clearSellingPower } from "features/wallet/walletSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Instruments } from "interfaces/markets";
import type { PortfolioHoldings } from "interfaces/portfolio";
import { ThemeVariants } from "interfaces/theme";
import type { BalanceCurrencies } from "interfaces/wallet";
import { Currencies } from "interfaces/wallet";
import DrawerLayout from "layouts/drawer";
import LayoutV2 from "layouts/v2";
import { useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";
import type { RootState } from "store/store";
import {
  clearSubscribeList,
  setSubscribeList,
} from "features/prices/capitalPricesSlice";

import styles from "./styles.module.scss";

interface InstrumentsBalanceCardsProps {
  selectedCurrency: BalanceCurrencies;
  holdings: PortfolioHoldings[];
  isPerformance?: boolean;
  variant?: ThemeVariants;
}

const InstrumentsBalanceCards = ({
  selectedCurrency,
  holdings,
  isPerformance,
  variant = ThemeVariants.Capital,
}: InstrumentsBalanceCardsProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openRightModalContent } = useAppState();
  const { isDesktop } = useDeviceCheck();
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const [marketData, setMarketData] = useState(holdings);

  const isCapital = variant === ThemeVariants.Capital;
  const performanceCapitalCurrency = isCapital
    ? Currencies.ARS
    : selectedCurrency;

  const emptyStateSubtitle = isCapital
    ? "Visitá el mercado para invertir en Cedears, Acciones, Bonos Públicos, ONs, Letras, Fondos de Inversión y más."
    : "Visitá el mercado para invertir en crypto y diversificar tu portfolio.";
  const emptyStateRoute = isCapital ? mainRoutes.market : CryptoRoutes.MARKET;

  const emptyInstrumentsProps = {
    illustration: <FirstInvestment variant={variant} />,
    title: "Hacé una inversión",
    subtitle: emptyStateSubtitle,
    buttonText: "Ir al mercado",
    onClick: () => {
      navigate(emptyStateRoute);
    },
  };

  const hasNotInstruments = !holdings || holdings.length < 1;

  const sortedHoldings = marketData.sort(
    (a, b) =>
      b.settlements[SettlementIndex.INF].amount -
      a.settlements[SettlementIndex.INF].amount
  );

  const subscribeList = holdings.map(({ longTicker }) => longTicker);

  const getLogoImage = (logo: string | null): string => {
    if (!logo) {
      return `${CDN_URL}/logos/ARG.jpg`;
    }
    return `${CDN_URL}/logos/${logo}`;
  };

  const QuickPurchaseComponent = (marketProp: any) => (
    <LayoutV2
      title={marketProp.short_ticker}
      withSearch
      quickOperation
      onClickExtended={() =>
        redirectToTrading(
          marketProp.instrument_type,
          marketProp.ticker,
          marketProp
        )
      }
      instrument={marketProp}
    >
      <TradingContainer instrumentProp={marketProp} />
    </LayoutV2>
  );

  const redirectToTrading = (type: string, code: string, ticker: any) => {
    navigate(`${mainRoutes.market}/${type}/${code}`, { state: ticker });
  };

  const MarketDetailComponent = (selectedId: string, ticker: any) => (
    <DrawerLayout>
      <MarketDetailSidebarDesktop id={selectedId} ticker={ticker} />
    </DrawerLayout>
  );

  const resetMarketOrder = () => {
    dispatch(resetMarketDataDetail());
    dispatch(cleanHistoricalData());
    dispatch(clearSellingPower());
  };

  const handleClickBalanceCard = (holding: PortfolioHoldings) => {
    const {
      code,
      ticker,
      name,
      type,
      shortName,
      logo,
      settlements,
      isCrypto,
    } = holding;

    const cryptoTicker = ticker === "Crypto";
    const capitalTicker = ticker === "Capital";

    ampli.portfolioExited({ referral: "asset" });

    if (cryptoTicker) {
      navigate(CryptoRoutes.PORTFOLIO);
    } else if (capitalTicker) {
      navigate(mainRoutes.portfolio);
    } else if (isCrypto) {
      navigate(`${CryptoRoutes.MARKET}/${ticker}`);
    } else if (ticker === "US$ especie") {
      return;
    } else {
      const holdingMock = {
        ...holding,
        amount: settlements[SettlementIndex.INF].amount,
        currency: Currencies.ARS,
        instrument_code: code,
        instrument_name: name,
        instrument_type: type,
        logo_file_name: logo,
        long_ticker: `${ticker}${DEFAULT_LONG_TICKER}`,
        quantity: settlements[2].quantity,
        short_ticker: shortName,
        ticker,
      };

      const marketRoute = `${mainRoutes.market}/${type}/${ticker}`;

      if (
        order.instrument_code &&
        ticker &&
        !order.instrument_code.includes(ticker)
      ) {
        resetMarketOrder();
        dispatch(resetOrder());
      }

      if (type !== Instruments.FCI) {
        return isDesktop
          ? openRightModalContent(QuickPurchaseComponent(holdingMock))
          : navigate(marketRoute, {
              state: holdingMock,
            });
      } else {
        const isNewFundsRouteMobile = isCocosFundRedesign(ticker)
          ? `${mainRoutes.funds}/${ticker}`
          : marketRoute;
        const isNewFundsDesktop = isCocosFundRedesign(ticker) ? (
          <CapitalFunds shortName={ticker} />
        ) : (
          MarketDetailComponent(ticker, holdingMock)
        );

        return isDesktop
          ? openRightModalContent(isNewFundsDesktop)
          : navigate(isNewFundsRouteMobile);
      }
    }
  };

  useEffect(() => {
    setMarketData(holdings);
  }, [holdings]);

  useEffect(() => {
    if (!isCapital) return;

    dispatch(setSubscribeList(subscribeList));

    return () => {
      dispatch(clearSubscribeList());
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <Heading
        className={styles.valueHeading}
        variant={HeadingVariants.RegularTitle}
        component="h2"
        color="var(--slate800)"
      >
        Instrumento
      </Heading>
      <div className={styles.instrumentsContainer}>
        {hasNotInstruments ? (
          <div className={styles.emptyStateContainer}>
            <EmptyState
              {...emptyInstrumentsProps}
              buttonType={EmptyButtonTypes.BUTTON}
              buttonVariant={variant}
            />
          </div>
        ) : (
          <>
            {sortedHoldings.map((holding) => {
              const {
                name,
                ticker,
                shortName,
                logo,
                settlements,
                type,
                isCrypto,
                longTicker,
                resultPercentage,
              } = holding;

              return isPerformance ? (
                <AssetBalanceCard
                  onClick={() => handleClickBalanceCard(holding)}
                  shortName={ticker}
                  longName={shortName}
                  variant={AssetBalanceVariants.PERFORMANCE}
                  selectedCurrency={performanceCapitalCurrency}
                  image={getLogoImage(logo)}
                  key={ticker}
                  type={type}
                  amountVariation={settlements[SettlementIndex.INF].amount}
                  amount={settlements[SettlementIndex.INF].amount}
                  quantity={settlements[SettlementIndex.INF].quantity}
                  isCrypto={isCrypto}
                  resultPercentage={resultPercentage}
                />
              ) : (
                <AssetBalanceCard
                  onClick={() => handleClickBalanceCard(holding)}
                  shortName={ticker ? ticker : "nominales"}
                  longName={
                    type === Instruments.LETRA_INTERNACIONAL
                      ? name
                      : type === Instruments.OPCIONES ||
                        type === Instruments.FUTUROS
                      ? ticker
                      : shortName
                  }
                  variant={AssetBalanceVariants.VALUE}
                  selectedCurrency={selectedCurrency}
                  image={getLogoImage(logo)}
                  key={ticker}
                  type={type}
                  amount={settlements[SettlementIndex.INF].amount}
                  quantity={settlements[SettlementIndex.INF].quantity}
                  isCrypto={isCrypto}
                  longTicker={longTicker ?? ""}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default InstrumentsBalanceCards;
