import { useEffect } from "react";

import { FiatExternalCustomerStatus } from "interfaces/crypto";
import { useCryptoCable } from "context/CryptoCableProvider";
import CableSheet from "components/common/CableSheet";
import BankAccountDetails from "components/page-crypto-cable/BankAccountDetails";
import Banner, { BannerVariants } from "components/common/Banner";
import Text, { TextVariants } from "components/common/Text";
import DetailRow from "components/common/DetailRow";

import styles from "./styles.module.scss";

interface ReceiveCableCryptoDollarProps {
  onClose: () => void;
}

const ReceiveCableCryptoDollar: React.FC<ReceiveCableCryptoDollarProps> = ({
  onClose,
}) => {
  const { externalAccountData } = useCryptoCable();

  if (
    !externalAccountData ||
    (externalAccountData && externalAccountData?.status) !==
      FiatExternalCustomerStatus.ACTIVE
  ) {
    return (
      <div className={styles.container}>
        <Banner
          className={styles.banner}
          variant={BannerVariants.INFO_CAPITAL}
          text="Únicamente podés recibir dólares de cuentas que estén a tu nombre."
        />
        <Text
          color="var(--slate800)"
          className={styles.title}
          variant={TextVariants.RegularText}
        >
          Datos bancarios
        </Text>
        <ul className={styles.list}>
          {Array.from({ length: 7 }).map((_, index) => (
            <DetailRow key={index} isLoading />
          ))}
        </ul>
        <CableSheet isOpen={true} setIsOpen={onClose} isCapital />
      </div>
    );
  }

  const { bankAccounts, status } = externalAccountData;

  if (status === FiatExternalCustomerStatus.ACTIVE) {
    return (
      <BankAccountDetails bankAccount={bankAccounts[0]} isCapital={true} />
    );
  }

  return null;
};

export default ReceiveCableCryptoDollar;
