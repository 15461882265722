import { UIModalSlider } from "components/common/modal-slider";
import Heading, { HeadingVariants } from "components/common/Heading";
import EmptyStateWithOnboarding from "components/common/EmptyStateWithOnboarding";

import styles from "./styles.module.scss";

interface NoAccountSheetProps {
  isOpen: boolean;
  onClose: () => void;
}

const NoAccountSheet: React.FC<NoAccountSheetProps> = ({ isOpen, onClose }) => {
  return (
    <UIModalSlider open={isOpen} onToggleDisplay={onClose}>
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        color="var(--slate900)"
        className={styles.text}
        component="h2"
      >
        Antes de empezar
      </Heading>
      <EmptyStateWithOnboarding withBorder={false} extraEmptyState={false} />
    </UIModalSlider>
  );
};

export default NoAccountSheet;
