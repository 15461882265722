import { getListMenuHome } from "constants/capitalMenu";

import CommonLayout from "layouts/common";
import { useNavigate, useSearchParams } from "react-router-dom";
import NavigationHeader from "components/common/NavigationHeader";
import FixedHeader from "components/common/FixedHeader";

import ActivityContent from "./ActivityContent";
import OrderDetail from "./OrderDetail";

const Orders = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentOrderId = searchParams.get("id");

  if (currentOrderId) return <OrderDetail id={currentOrderId} />;

  return (
    <CommonLayout {...getListMenuHome()} withHeader={false}>
      <FixedHeader>
        <NavigationHeader
          title="Órdenes"
          onClick={() => navigate(-1)}
          withCloseIcon={false}
        />
      </FixedHeader>
      <ActivityContent />
    </CommonLayout>
  );
};

export default Orders;
