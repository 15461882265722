import { User2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";
import { ThemeVariants } from "interfaces/theme";

import Text, { TextVariants } from "../Text";
import Avatar from "../avatar";

import styles from "./styles.module.scss";

interface SectionHeaderProps {
  title: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.sectionHeaderContainer}>
      <Avatar
        variant={ThemeVariants.Crypto}
        onClick={() => navigate(CryptoRoutes.PROFILE)}
      />
      <Text variant={TextVariants.RegularText} color="var(--slate800)">
        {title}
      </Text>
      <div className={styles.empty} />
    </div>
  );
};

export default SectionHeader;
