import styles from "./styles.module.scss";

interface CenterProps {
  isCapital: boolean;
}

const Center: React.FC<CenterProps> = ({ isCapital = false }) => (
  <div className={styles.centerWrapper}>
    <div className={styles.svgsWrapper}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        style={{ zIndex: 3 }}
      >
        <g filter="url(#filter0_dd_5334_113328)">
          <rect
            x="8.00009"
            y="5.99951"
            width="56"
            height="56"
            rx="28"
            fill={isCapital ? "var(--blue800)" : "var(--purple800)"}
          />
          <path
            d="M22.8621 36.4479C24.4637 39.7364 27.9674 41.5836 31.7723 41.5416L31.8003 41.5206C33.6085 41.4935 35.3878 41.0658 37.011 40.2682L38.8712 39.3586L37.0598 35.6432L35.1996 36.5528C31.7935 38.2111 27.9398 37.3435 26.6037 34.6147C26.3038 33.9543 26.1525 33.2363 26.1597 32.511C26.1669 31.7857 26.3327 31.0709 26.6458 30.4166C27.4061 28.769 28.7238 27.4432 30.3666 26.6733L32.2272 25.7637L30.4154 22.0483L28.5552 22.9509C26.0316 24.1501 24.0144 26.2052 22.8621 28.7513C22.2944 29.9548 22.0001 31.2689 22.0001 32.5996C22.0001 33.9303 22.2944 35.2444 22.8621 36.4479Z"
            fill="var(--white-to-black)"
          />
          <path
            d="M39.9418 42.675L41.8372 46.3554L43.6694 45.4108C49.055 42.633 51.4889 36.5178 49.055 31.7879C46.621 27.058 40.2493 25.4487 34.8501 28.2475L33.0107 29.1921L34.9061 32.8724L36.7455 31.9278C40.1167 30.1856 43.9841 30.9833 45.3762 33.6841C46.7679 36.3848 45.1524 39.9952 41.7811 41.7304L39.9418 42.675Z"
            fill="var(--white-to-black)"
          />
        </g>
        <defs>
          <filter
            id="filter0_dd_5334_113328"
            x="9.15527e-05"
            y="-0.000488281"
            width="72"
            height="72"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.376471 0 0 0 0 0.392157 0 0 0 0 0.423529 0 0 0 0.04 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5334_113328"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.376471 0 0 0 0 0.392157 0 0 0 0 0.423529 0 0 0 0.04 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_5334_113328"
              result="effect2_dropShadow_5334_113328"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_5334_113328"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="98"
        height="98"
        viewBox="0 0 98 98"
        fill="none"
        style={{ zIndex: 2, filter: "blur(4.2px)" }}
      >
        <g filter="url(#filter0_f_5343_116708)">
          <ellipse
            cx="48.9999"
            cy="49.0006"
            rx="40"
            ry="40.0011"
            fill="var(--slate200)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_5343_116708"
            x="0.42845"
            y="0.428083"
            width="97.1429"
            height="97.1451"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="4.28571"
              result="effect1_foregroundBlur_5343_116708"
            />
          </filter>
        </defs>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="172"
        viewBox="0 0 192 172"
        fill="none"
        style={{ zIndex: 0, filter: "blur(8px)" }}
      >
        <g filter="url(#filter0_f_5334_113272)">
          <ellipse
            cx="95.9999"
            cy="87.0791"
            rx="80"
            ry="80.0794"
            fill="var(--background-2)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_5334_113272"
            x="-0.000141144"
            y="-9.00024"
            width="192"
            height="192.159"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="8"
              result="effect1_foregroundBlur_5334_113272"
            />
          </filter>
        </defs>
      </svg>
    </div>
  </div>
);

export default Center;
