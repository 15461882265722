import { useState, useEffect } from "react";

import Toast, { ToastVariants } from "components/common/Toast";
import NavigationBar from "components/common/NavigationBar";
import { useCocosCard } from "context/CocosCardProvider";
import { ArrowLeft, MessageCircleQuestion } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import {
  CardStatus,
  CardTypes,
  CardTypesLabel,
  UserStatus,
} from "interfaces/card/enums";
import {
  Card as CardInterface,
  PaymentMethodProfile,
} from "interfaces/card/interface";

import MainActions from "./MainActions";
import PaymentMethodList from "./PaymentMethodList";
import AvailableAmount from "./AvailableAmount";
import EmptyCardTitle from "./EmptyCardTitle";
import ShippingDetail from "./ShippingDetail";
import PhysicalEmptyContent from "./PhysicalEmptyContent";
import VirtualEmptyContent from "./VirtualEmptyContent";
import TransactionsHome from "./Transactions";
import PendingCreation from "./PendingCreation";
import GPayActivation from "./GPayActivation";
import AppleWalletActivation from "./AppleWalletActivation";
import CardSlider from "../CardSlider";
import Card, { CardTypeEmpty } from "../components/Card";

import styles from "./styles.module.scss";

const CardHome = () => {
  const navigate = useNavigate();
  const {
    pomeloData,
    selectedCard,
    physicalCard,
    virtualCard,
    setSelectedCard,
    displayToast,
    setDisplayToast,
    userStatus,
  } = useCocosCard();

  const [selectedCardData, setSelectedCardData] = useState<CardInterface>();

  const cardOptions = [
    { name: CardTypesLabel.PHYSICAL },
    { name: CardTypesLabel.VIRTUAL },
  ];

  const cardType =
    selectedCard === CardTypesLabel.PHYSICAL
      ? CardTypes.PHYSICAL
      : CardTypes.VIRTUAL;

  const onClose = () => {
    navigate(mainRoutes.home);
  };

  const handleTabClick = (option: CardTypesLabel) => {
    setSelectedCard(option);
  };

  const totalAmountAvailableArs =
    selectedCardData?.paymentMethodProfile.reduce(
      (acc: number, item: PaymentMethodProfile) =>
        acc + item.amountAvailableArs,
      0
    ) || 0;

  const getSelectedCardInfo = () => {
    if (!selectedCardData) return;

    if (selectedCardData.isShipping) {
      return <ShippingDetail />;
    }

    return (
      <>
        <MainActions />
        <PaymentMethodList list={selectedCardData?.paymentMethodProfile} />
        <GPayActivation
          idCard={selectedCardData.idCard}
          lastFour={selectedCardData.lastFour}
        />
        <AppleWalletActivation
          idCard={selectedCardData.idCard}
          cardLastFour={selectedCardData.lastFour}
        />
        <TransactionsHome />
      </>
    );
  };

  const EmptyCardContent =
    selectedCard === CardTypesLabel.PHYSICAL ? (
      <PhysicalEmptyContent />
    ) : (
      <VirtualEmptyContent />
    );

  useEffect(() => {
    const cardData = pomeloData?.cards.find(
      (card: CardInterface) => card.cardType === cardType
    );

    setSelectedCardData(cardData);
  }, [selectedCard, pomeloData]);

  if (userStatus === UserStatus.PENDING) {
    return <PendingCreation />;
  }

  return (
    <>
      <NavigationBar
        title="Tarjeta"
        leftIcon={ArrowLeft}
        onClickLeft={onClose}
        rightIcon={MessageCircleQuestion}
        onClickRight={() => navigate(mainRoutes.chat)}
        segmentedControlProps={{
          options: cardOptions,
          onClick: handleTabClick,
          selected: selectedCard,
        }}
      />

      <div className={styles.cardContainer}>
        {selectedCardData ? (
          <AvailableAmount amountAvailable={totalAmountAvailableArs} />
        ) : (
          <EmptyCardTitle />
        )}

        <CardSlider
          physicalCard={
            <Card
              type={physicalCard ? CardTypes.PHYSICAL : CardTypeEmpty.EMPTY}
              isPaused={physicalCard?.status === CardStatus.BLOCKED}
              number={physicalCard?.lastFour}
              isPending={physicalCard?.isShipping}
            />
          }
          virtualCard={
            <Card
              type={virtualCard ? CardTypes.VIRTUAL : CardTypeEmpty.EMPTY}
              isPaused={virtualCard?.status === CardStatus.BLOCKED}
              number={virtualCard?.lastFour}
            />
          }
        />

        {selectedCardData ? getSelectedCardInfo() : EmptyCardContent}

        {displayToast.visible && (
          <Toast
            variant={ToastVariants.Success}
            message={displayToast.message}
            onClose={() => setDisplayToast({ visible: false, message: "" })}
          />
        )}
      </div>
    </>
  );
};

export default CardHome;
