import AppleWallet from "assets/icons/AppleWallet";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface AppleWalletButtonProps {
  onClick?: () => void;
}

const AppleWalletButton: React.FC<AppleWalletButtonProps> = ({ onClick }) => (
  <div className={styles.appleWalletButton} onClick={onClick}>
    <AppleWallet />
    <Text
      variant={TextVariants.RegularTextS}
      className={styles.appleWalletText}
    >
      Agregar a Apple Wallet
    </Text>
  </div>
);

export default AppleWalletButton;
