import { Cryptos, cryptoStatus, Networks } from "constants/cryptos";

import { Operations } from "interfaces/crypto/enums";
import { Currencies } from "interfaces/wallet";
import {
  CocosProducts,
  FavoriteList,
  RoleActions,
  Tier,
} from "interfaces/user";
import { StatusOnboarding } from "interfaces/createAccount";
import { UserCardStatus } from "interfaces/card/enums";

import {
  DolarPrices,
  Instruments,
  SettlementTerms,
  OrderType,
} from "../markets";
import { Bid } from "./socket/book";

export interface UserMeResponse {
  id: string;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  email: string;
  email_confirmed: boolean;
  email_confirmed_at?: string;
  phone?: string;
  phone_confirmed: boolean;
  phone_confirmed_at?: string;
  enabled_products: CocosProducts;
  hasOwnerAccount: boolean;
  id_accounts: number[];
  dark_theme: boolean;
  cable_notification: boolean;
  statusOnboarding?: StatusOnboarding;
  status_crypto?: cryptoStatus;
  card_status?: UserCardStatus;
  latest_tnc_accepted?: boolean;
  favoritesLists: FavoriteList[];
  account?: {
    id: number;
    tier: Tier;
    actions: RoleActions;
    proposeTierUpgrade?: Tier.GOLD;
  };
}

export interface AccountsResponse {
  cbu_cvu: string;
  currency: Currencies;
  entity: string;
  id_bank_account: string;
  logo: string;
}

export interface TransfersResponse {
  currency: string;
  type: TransferTypes;
  type_label: string;
  ticker: string;
  order_number: string;
  amount: number;
  quantity: string;
  price?: number;
  ext_id_receipt: string;
  date: Date;
  instrument_type?: string | null;
  logo_file_name?: string | null;
  price_factor: number;
}

export enum TransferTypes {
  withdrawal = "WITHDRAWAL",
  deposit = "DEPOSIT",
  dividen = "DIVIDEND",
  buy = "BUY",
  sell = "SELL",
  fund = "FUND",
  redemption = "REDEMPTION",
  caucion_fund = "CAUCION_FUND",
  caucion = "CAUCION",
}

// TODO: Depecrate this interface
export interface ApiOrderResponse {
  Orden: string;
  Detalle: string;
  Sucess: boolean;
}

// TODO: Depecrate this interface
export interface OrderDetailResponse {
  currency?: string;
  error?: string | null;
  expiration_date: Date;
  instrument_short_name?: string;
  instrument_type: string;
  instrument: string;
  logo_file_name?: string | null;
  order_id: string;
  order_type_label: string;
  order_type: string;
  result_amount: number | null;
  result_price: number | null;
  result_quantity: number | null;
  set_amount: number;
  set_date: Date;
  set_price: number;
  set_quantity: number;
  settlement_days: number;
  status: OrderStatusTypes;
  ticker: string | null;
}

// TODO: Depecrate this interface
export interface OrdersResponse {
  currency: Currencies;
  expiration_date: Date;
  instrument_type: string;
  instrument: string;
  instrument_short_name?: string;
  logo_file_name?: string | null;
  order_id: string;
  order_type_label: string;
  order_type: OrderType;
  result_amount: number;
  result_price: number;
  result_quantity: number;
  set_amount: number;
  set_date: Date;
  set_price: number;
  set_quantity: number;
  settlement_days: number;
  status: OrderStatusTypes;
  ticker: string;
}

// Todo delete this once orders redesign is in prod
export enum OrderStatusTypes {
  CANCELLED = "CANCELLED",
  PENDING = "PENDING",
  MARKET = "MARKET",
  PARTIALLY_EXECUTED = "PARTIALLY_EXECUTED",
  PARTIALLY_CANCELLED = "PARTIALLY_CANCELLED",
  EXECUTED = "EXECUTED",
  REJECTED = "REJECTED",
}

export type PhoneRegisterResponse =
  | {
      phone: string;
      expires_at: string;
      id: string;
    }
  | { phone: null; expires_at: null; id: null };
export interface SellingPowerResponse {
  CI: string;
  "24hs": string;
  subyacente: string;
  Mas72hs: string;
  inf: string;
}

export interface BuyingPowerCurrencies {
  ars: number;
  usd: number;
  ext: number;
}

export interface BuyingPowerResponse {
  CI: BuyingPowerCurrencies;
  "24hs": BuyingPowerCurrencies;
}

export interface PortfolioResponse {
  total: PortfolioTotalApi;
  tickers: PortfolioTicker[];
}

export interface PortfolioTicker {
  ticker: string;
  instrument: string;
  amount: number;
  instrument_short_name: string | null;
  amount_usd: number;
  quantity: number;
  instrument_type: string;
  type?: string;
  subtype: string;
  variation?: number;
  last?: number;
  logo_file_name?: string | null;
  currency?: string;
  instrument_code?: string;
}

export interface TickersResponse {
  short_ticker: string;
  long_ticker: string;
  ext_code_cv: number;
  instrument_short_name?: string;
  instrument_type: Instruments;
  instrument_subtype: string;
  id_venue: string;
  date: Date;
  settlement_days: number;
  term?: string | null;
  newTerm?: number | null;
  currency: Currencies;
  price_factor: number;
  contract_size: number;
  min_lot_size: number;
  last: number;
  open: number | null;
  high: number | null;
  low: number | null;
  close: number;
  bid: number | null;
  ask: number | null;
  turnover: number | null;
  volume: number | null;
  variation: number | null;
  instrument_code: string;
  bids: Bid[];
  asks: Bid[];
  id_tick_size_rule?: string | null;
  tick_size?: number | null;
  returns_perc_last_year?: number | null;
  returns_perc_last_month?: number | null;
  returns_perc_begin_year?: number | null;
  nav?: number | null;
  risk?: string | null;
  rules_file_name?: string | null;
  redemption_settlement_days?: number | null;
  share_class?: string | null;
  logo_file_name?: string | null;
  is_favorite: boolean;
  prev_close?: number;
}

export interface InstrumentData {
  short_ticker?: string;
  long_ticker: string;
  ext_code_cv?: string | null;
  instrument_short_name?: string;
  short_name?: string;
  instrument_type?: Instruments;
  instrument_subtype?: string | null;
  id_venue?: string;
  date?: Date | null;
  settlement_days?: number;
  term?: number | null;
  currency?: Currencies;
  price_factor?: number;
  contract_size?: number;
  min_lot_size?: number;
  last?: number | null;
  open?: number | null;
  high?: number | null;
  low?: number | null;
  close?: number | null;
  bid?: number | null;
  ask?: number | null;
  turnover?: number | null;
  volume?: number | null;
  variation?: number | null;
  instrument_code?: string;
  bids?: Bid[] | null;
  asks?: Bid[] | null;
  id_tick_size_rule?: string | null;
  tick_size?: number | null;
  returns_perc_last_year?: number | null;
  returns_perc_last_month?: number | null;
  returns_perc_begin_year?: number | null;
  nav?: number | null;
  risk?: string | null;
  rules_file_name?: string | null;
  redemption_settlement_days?: number | null;
  share_class?: string | null;
  logo_file_name?: string | null;
  isFavorite?: boolean;
  prev_close?: number;
  id_security?: number | null;
  lastSize?: number | null;
  volumeWAP?: number;
}

export interface PortfolioTotalApi {
  ars: number;
  usd: number;
}

export interface SearchListResponse {
  instrument_type: string;
  instrument_subtypes: InstrumentSubtype[];
  type_code?: string;
  type_name?: string;
}

export interface TypeListResponse {
  instrument_type: string;
  instrument_subtypes: InstrumentSubtype[];
}

export interface InstrumentSubtype {
  id_list: string;
  list_name: string;
  instrument_subtype: string | null;
  market_data: InstrumentData[];
}

export interface DolarMepPriceResponse {
  short_ticker: string;
  ask: number;
  bid: number;
}
export interface DolarMepResponse {
  open: DolarPrices;
  close: DolarPrices;
  overnight: DolarPrices;
}

interface AcademyCard {
  id_university: string;
  header: string;
  title: string;
  call_to_action: string;
  target_url: string;
}

export type AcademyResponse = AcademyCard[];

export interface FaqsQyA {
  id_question: number;
  question: string;
  answer: string;
}

export interface FaqsSections {
  id_section: number;
  section_name: string;
  qa: FaqsQyA[];
}

export interface Price {
  date: string;
  high: number;
  low: number;
  last?: number;
  turnover: number;
  open: number;
  close: number;
}
export interface HistoricDataMarketData {
  short_ticker: string;
  long_ticker: string;
  ext_code_cv: number;
  date: string;
  settlement_days: number;
  currency: string;
  prices: Price[];
}

export interface IntervalParameter {
  value: number;
  frame: "day" | "month" | "year";
}

export interface MarketDataFilters {
  type: string;
  subtype: string | null;
  settlement_days: SettlementTerms;
  currency: Currencies;
  page?: number;
  size?: number;
}

export interface MarketData {
  current_page: number;
  per_page: number;
  total_pages: number;
  total_items: number;
  items: InstrumentData[];
  instrument_subtype: string;
}

export interface TickersRulesResponse {
  [key: string]: TickerRule[];
}

export interface TickerRule {
  id_tick_size_rule: string;
  price_from: number;
  price_to: number;
  tick_size: number;
  num_decimals: number;
}

export interface PerformanceDailyResponse {
  total: number;
  tickers: PerformanceDailyTicker[];
}

export interface PerformanceDailyTicker {
  instrument_type: Instruments;
  instrument_short_name: string;
  instrument_code: string;
  logo_file_name: string;
  result: number;
  previous_price: number;
  quantity: number;
  short_ticker: string;
  last_price: number;
  percentage: number;
}

export interface PerformanceHistoricResponse {
  instrument_type: string;
  instrument_short_name: string;
  instrument_code: string;
  logo_file_name: string;
  short_ticker: string;
  quantity: number;
  price_variation: string;
  last: number;
  amount: number;
  result: number;
  average_price: number;
  result_percentage: number;
}

export interface PerformanceGlobalResponse {
  holding_initial: number;
  holding_final: number;
  increase: number;
  profit: number;
  loss: number;
}

export interface MarketCalendarStatusResponse {
  "0": boolean;
  "1": boolean;
  "2": boolean;
  "3": boolean;
  "4": boolean;
  "5": boolean;
}

export interface CreateListResponse {
  created_at: Date;
  updated_at: Date;
  id_list: string;
  name: string;
  created_by: string;
  is_public: boolean;
  type: string;
}

export interface InvestorTestResponse {
  id_question: string;
  label: string;
  id_order: number;
  options: OptionInvestorTest[];
}

export interface OptionInvestorTest {
  id_option: string;
  label: string;
  id_order: number;
}

export interface InvestorProfileResponse {
  id_test: string;
  profile: string;
  ext_id_gallo: number;
}

export interface WithdrawResponse {
  created_at: Date;
  updated_at: Date;
  id_cash_out: string;
  id_account: number;
  amount: number;
  id_currency: string;
  is_processed: boolean;
  status: OrderStatusTypes;
  issued_at: Date;
}

export interface CreateAccountData {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  cuit: string;
  gender: string;
  dni: string;
  procedureNumber: string;
  expirationDate?: string;
  manualTranscription: boolean;
}

export interface OnboardingData {
  idOnboarding: string;
  nextStep: NextStep;
  status: FinalStatusOnboarding;
}

export enum NextStep {
  IDENTITY = "IDENTITY",
  SELFIE = "SELFIE",
  PERSONAL_DATA = "PERSONAL_DATA",
  FINISHED = "FINISHED",
}

export enum FinalStatusOnboarding {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  PENDING_WITH_REQUIREMENTS = "PENDING_WITH_REQUIREMENTS",
}

export interface CryptoSwapPortfolio {
  total?: number;
  varTotal?: number;
  varTotalPercentage?: number;
  holdings: CryptoSwapHolding[];
}

export interface CryptoSwapHolding {
  amountArs?: number;
  idSecurity?: number;
  priceArs?: number;
  quantity?: number;
  ticker?: string;
  varDailyPrice?: number;
  varDailyPricePercentage?: number;
}

export interface CryptoHistoricPortfolio {
  historicHoldings: {
    amount: number;
    date: string;
  }[];
}

export interface CryptoHistoricPrice {
  datetime: string;
  price: number;
}

export interface CryptoPortfolio {
  total: number;
  varTotal: number;
  varTotalPercentage: number;
  holdings: CryptoHolding[];
}

export interface CryptoPerformance {
  ticker: Cryptos;
  quantity: number;
  revenue: number;
  revenuePercentage: number;
}

export interface CryptoHolding {
  idSecurity: number;
  ticker: Cryptos | Currencies.ARS;
  quantity: number;
  priceArs: number;
  amountArs: number;
  varDailyPrice: number;
  varDailyPricePercentage: number;
}

export interface CryptoPrice {
  baseTicker: Cryptos;
  quoteTicker: Cryptos | Currencies.ARS;
  ask: number;
  bid: number;
  last: number;
  low: number;
  high: number;
  open: number;
  close: number;
  varDailyPrice: number;
  varDailyPricePercentage: number;
}

export interface CryptoActivity {
  data: {
    movements: CryptoMovement[];
    date: string;
  }[];
  pagination: {
    hasNext: boolean;
  };
}

export interface CryptoCustomer {
  tag: string | null;
}

export enum MovementStatus {
  ERROR = "ERROR",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}

export interface CryptoMovement {
  idAccount: number;
  date: string;
  idOrder: string;
  baseTicker: Cryptos;
  baseQuantity: number;
  quoteTicker: Cryptos;
  quoteQuantity: number;
  operation: Operations;
  status: MovementStatus;
}

export interface CryptoSwap {
  holdings: { ticker: Cryptos | Currencies.ARS; amount: number }[];
}

export interface MarketTicker {
  id_security: number;
  name: string;
  ticker: string;
}
export interface CryptoMarket {
  cryptos: { crypto: Cryptos; price: number; variation: number }[];
}

export interface CryptoMarketDetail {
  ticker: Cryptos;
  prices: { sell: number; buy: number };
  name: string;
  variation: number;
}

export interface CryptoAccount {
  name: string;
  idAccount: number;
  idAddress: string;
  address: string;
  network: string;
  tickers: Cryptos[];
}

export interface CryptoTag {
  name: string;
  accountDisplayName: string;
}

export interface CryptoAvailableNetworks {
  name: Networks;
  available: number;
  id_network: number;
}

export interface CreateSendOrderResponse {
  idOrder: string;
  status: string;
  expiresAt: string;
  quantity: string;
  fee: string;
}

export interface ConfirmSendOrderResponse {
  idOrder: string;
  status: string;
}

export interface CryptoCreateOrder {
  baseQuantity: number;
  quoteQuantity: number;
  price: number;
  idOrder: string;
  expiresAt: number;
}

export interface CryptoTicker {
  name: string;
  ticker: Cryptos;
  networks: Networks[];
  allowed_operations?: Operations[];
}

export interface Banner {
  id_account: number;
  name: string;
  supressUntil: string | null;
  seen: boolean;
}

export interface PixPrices {
  usdToBrlExchangeRate: number;
  usdPixRate: number;
  usdToBrlRate: number;
  usdToArsRate: number;
  brlToArsRate: number;
}

export interface ValidationChallengeResponse {
  id: string;
  expires_at: Date;
}

export interface Banner {
  id_account: number;
  name: string;
  supressUntil: string | null;
  seen: boolean;
}

export enum LimitPeriod {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTH = "MONTH",
}
export interface Limit {
  period: LimitPeriod;
  limit: number;
  consumed: number;
  available: number;
  currency: Currencies;
}

export interface CryptoLimitsResponse {
  SEND_TAG: Limit[];
  SEND: Limit[];
}

export interface BankAccountDataResponse {
  cvu: string;
  cuit: string;
}
