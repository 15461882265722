import { useEffect } from "react";

import { ThemeVariants } from "interfaces/theme";
import { useCryptoCable } from "context/CryptoCableProvider";
import { FiatExternalCustomerStatus } from "interfaces/crypto";
import { useCocosPay } from "context/CocosPayProvider";
import {
  PayError,
  PaySource,
  Payment,
  PaymentCurrencies,
} from "interfaces/pay/enums";
import API from "apis";
import { cryptoService } from "apis/services";
import { PaymentMethodsResponse } from "interfaces/pay/interface";
import { Currencies } from "interfaces/wallet";

import BankAccountDetails from "./BankAccountDetails";
import Paywall from "./Paywall";
import LoadingScreen from "./Loading";
import CreationProgress from "./CreationProgress";

const CryptoCablePage: React.FC = () => {
  const {
    externalAccountData,
    isLoading,
    setIsLoading,
    isCreationInProgress,
    isCapital,
  } = useCryptoCable();

  const {
    setSource,
    setAvailableMethods,
    setPaymentData,
    setPaymentMethodMessages,
    setError,
  } = useCocosPay();

  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        setIsLoading(true);
        const { data } = await API.get<PaymentMethodsResponse>(
          cryptoService.fiatExternalCustomerPaymentMethods
        );

        setAvailableMethods(data.paymentMethods);
        setPaymentMethodMessages(data.messages);

        const quantity =
          data.paymentMethods.find(
            (method) => method.name === PaymentCurrencies.ARS
          )?.amount || 10;

        setPaymentData({
          idPayment: "",
          quantity: quantity,
          currency: Currencies.ARS,
          transactionQuantity: quantity,
          transactionCurrency: Currencies.ARS,
          businessName: "",
          paymentType: Payment.CLOSE,
          countryCode: "AR",
          serviceStatus: {
            serviceDisruption: null,
          },
        });
      } catch (error: any) {
        setError(PayError.PAYMENT_METHODS_ERROR);
      } finally {
        setIsLoading(false);
      }
    };

    getPaymentMethods();
  }, []);

  setSource(PaySource.BRIDGE);

  if (isLoading)
    return (
      <LoadingScreen
        variant={isCapital ? ThemeVariants.Capital : ThemeVariants.Crypto}
      />
    );

  if (isCreationInProgress) {
    return <CreationProgress />;
  }

  if (!externalAccountData) {
    return <Paywall />;
  }

  const { bankAccounts, status } = externalAccountData;

  if (status === FiatExternalCustomerStatus.ACTIVE) {
    return (
      <BankAccountDetails bankAccount={bankAccounts[0]} isCapital={isCapital} />
    );
  }

  return <LoadingScreen />;
};

export default CryptoCablePage;
