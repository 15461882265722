import { cryptoStatus } from "constants/cryptos";

import { useEffect } from "react";

import { Capacitor } from "@capacitor/core";
import LiriumCoinagTyC, {
  LiriumCoinagTyCVariants,
} from "components/common/LiriumCoinagTyC";
import LoaderPage from "components/common/LoaderPage";
import { useCocosPay } from "context/CocosPayProvider";
import { useAppSelector } from "hooks/redux";
import { PaySteps } from "interfaces/pay/enums";
import { ThemeVariants } from "interfaces/theme";
import { CocosProducts } from "interfaces/user";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import mainRoutes from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import { RootState } from "store/store";
import { PayLocationState } from "interfaces/pay/interface";

import PayAmount from "./Amount";
import PayMethod from "./Method";
import PayPIN from "./PIN";
import PayQR from "./QR";
import PayReceipt from "./Receipt";
import PayResponse from "./Response";
import PayCode from "./Code";
import ServiceErrorBottomSheet from "./ServiceError";

const stepsComponents = {
  [PaySteps.QR]: <PayQR />,
  [PaySteps.CODE]: <PayCode />,
  [PaySteps.METHOD]: <PayMethod />,
  [PaySteps.AMOUNT]: <PayAmount />,
  [PaySteps.PIN]: <PayPIN />,
  [PaySteps.RESPONSE]: <PayResponse />,
  [PaySteps.RECEIPT]: <PayReceipt />,
};

const CocosPayPage: React.FC = () => {
  const {
    isLoading,
    setIsLoading,
    step,
    loaderTitle,
    loaderSubtitle,
    setStep,
  } = useCocosPay();
  const locationState = useLocation().state as PayLocationState | null;
  const navigate = useNavigate();
  const { has2FA } = useAppSelector((state: RootState) => state.user);

  const user = useAppSelector(getLoggedUser);

  const isNative = Capacitor.isNativePlatform();

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const {
    status_crypto,
    enabled_products,
    latest_tnc_accepted,
    hasAccount,
  } = user;

  const isPaymentEnabled =
    isNative && enabled_products.includes(CocosProducts.PAY) && hasAccount;

  useEffect(() => {
    if (!isPaymentEnabled) navigate(-1);
  }, [isPaymentEnabled]);

  useEffect(() => {
    if (locationState?.isPix) {
      setStep(PaySteps.CODE);
    }

    setIsLoading(false);
  }, []);

  if (status_crypto !== cryptoStatus.APPROVED || !latest_tnc_accepted) {
    return (
      <LiriumCoinagTyC
        status={status_crypto}
        variant={LiriumCoinagTyCVariants.PAY}
      />
    );
  }

  if (!has2FA) {
    return <Navigate replace to={mainRoutes.cocos2FA} />;
  }

  if (isLoading) {
    return (
      <LoaderPage
        title={loaderTitle}
        subtitle={loaderSubtitle}
        variant={ThemeVariants.Pay}
      />
    );
  }

  return (
    <>
      {stepsComponents[step]}
      <ServiceErrorBottomSheet />
    </>
  );
};

export default CocosPayPage;
