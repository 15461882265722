import FilledButton from "components/common/FilledButton";
import Text, { TextVariants } from "components/common/Text";
import { size } from "lodash";
import getPlatform from "utils/getPlatform";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface PhoneConfirmProps {
  isLoading: boolean;
  phoneNumber: string;
  isPhoneValid: boolean;
  registerPhone: () => void;
}

const PhoneFormConfirm: React.FC<PhoneConfirmProps> = ({
  isLoading,
  phoneNumber,
  isPhoneValid,
  registerPhone,
}) => {
  const MIN_PHONE_SIZE = 3;
  const { isIOS } = getPlatform();
  return (
    <div
      className={classNames(styles.PhoneFormConfirmContainer, {
        [styles.ios]: isIOS,
      })}
    >
      <Text variant={TextVariants.RegularTextS} color="var(--slate600)">
        Al continuar, confirmás estar autorizado a usar este número de teléfono
        y aceptás recibir un mensaje por Whatsapp. Es posible que se apliquen
        tarifas del operador.
      </Text>
      <FilledButton
        isLoading={isLoading}
        onClick={registerPhone}
        disabled={!isPhoneValid || size(phoneNumber) < MIN_PHONE_SIZE}
      >
        Continuar
      </FilledButton>
    </div>
  );
};

export default PhoneFormConfirm;
