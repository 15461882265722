import { useState } from "react";

import type { OrderStatus } from "interfaces/orders/enums";

import ActivityList from "./ActivityList";
import BottomText from "./BottomText";
import FilterPills from "./FilterPills";

import styles from "./style.module.scss";

export type FilterOrderType = OrderStatus.PENDING | OrderStatus.FILLED | "ALL";

const ActivityContent = () => {
  const [activeFilter, setActiveFilter] = useState<FilterOrderType>("ALL");

  return (
    <div className={styles.activityContainer}>
      <FilterPills
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      <ActivityList filterStatus={activeFilter} />
      <BottomText />
    </div>
  );
};

export default ActivityContent;
