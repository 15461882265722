import { LucideIcon } from "lucide-react";

export interface ProfileSection {
  icon: LucideIcon;
  title: PROFILE_SECTIONS;
  detail: string;
  path: string;
  notifications?: number;
  subSection: MOBILE_SUBSECTIONS;
  showDesktop: boolean;
  showMobile: boolean;
}

export interface SectionOption {
  icon?: LucideIcon;
  title: string;
  onClick: () => void;
}

export enum MOBILE_SUBSECTIONS {
  ACCOUNT = "Tu cuenta",
  HELP = "Información y ayuda",
  SESSION = "Sesión",
}

export enum PROFILE_SECTIONS {
  PERSONAL_DATA = "Datos personales",
  INVERSOR_TEST = "Test inversor",
  HELP = "Centro de ayuda",
  BANK_ACCOUNTS = "Cuentas bancarias",
  LEARN = "Aprendé",
  SECURITY = "Seguridad",
  CONFIG = "Configuración",
  LEGALS = "Legales",
  GOLD = "Activá Cocos Gold",
}
