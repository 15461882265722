import React, { Suspense, useEffect, useState } from "react";

import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import * as Sentry from "@sentry/react";
import AppUrlListener from "AppUrlListener";
import AppTracking from "AppTracking";
import { IdentifyProperties, ampli } from "ampli";
import API from "apis";
import { publicService } from "apis/services";
import Loader from "components/common/Loader";
import BackDropSpinner from "components/common/back-drop";
import UploaderModal from "components/common/uploader-modal";
import TwoFAPage from "components/page-2fa";
import CapitalFunds from "components/page-capital-funds";
import ChangePasswordPage from "components/page-change-password";
import Crypto2FAPage from "components/page-crypto-2fa";
import AgendaSection from "components/page-crypto-profile/SectionsList/AgendaSection";
import ConfigSection from "components/page-crypto-profile/SectionsList/ConfigSection";
import FaqsSection from "components/page-crypto-profile/SectionsList/FaqsSection";
import HelpSection from "components/page-crypto-profile/SectionsList/HelpSection";
import LearnSection from "components/page-crypto-profile/SectionsList/LearnSection";
import LegalsSection from "components/page-crypto-profile/SectionsList/LegalsSection";
import SecuritySection from "components/page-crypto-profile/SectionsList/SecuritySection";
import CryptoWelcomePage from "components/page-crypto-welcome";
import ResetPassword from "components/page-reset-password";
import { useAppState } from "context/AppStateProvider";
import { useAuth } from "context/AuthProvider";
import { useAppSelector } from "hooks/redux";
import CocosCardHelp from "pages/cocos-card/help";
import CocosCardIssueMovements from "pages/cocos-card/issueMovements";
import CocosCardIssues from "pages/cocos-card/issues";
import CocosCardLimits from "pages/cocos-card/limit";
import CocosCardPin from "pages/cocos-card/pin";
import CocosCardSettings from "pages/cocos-card/settings";
import CocosCardTransactions from "pages/cocos-card/transactions";
import CocosCardTransactionDetail from "pages/cocos-card/transactions/detail";
import CryptoHome from "pages/crypto";
import Activity from "pages/crypto/activity";
import Allocation from "pages/crypto/allocation";
import Cable from "pages/crypto/cable";
import CryptoLimits from "pages/crypto/limits";
import CryptoMarket from "pages/crypto/market";
import CryptoMarketDetail from "pages/crypto/market/detail";
import CryptoPerformance from "pages/crypto/performance";
import CryptoPortfolio from "pages/crypto/portfolio";
import Receive from "pages/crypto/receive";
import ReceiveArs from "pages/crypto/receive/ars";
import Send from "pages/crypto/send";
import Swap from "pages/crypto/swap";
import EmailValidation from "pages/email-validation";
import EnableBiometry from "pages/enable-biometry";
import PhoneValidation from "pages/phone-validation";
import TrustedDevice from "pages/trusted-device";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { socket } from "socket";
import { RootState } from "store/store";
import brazeClient from "utils/braze";
import getPlatform from "utils/getPlatform";
import CapitalAccounts from "pages/capital/accounts";
import CapitalPortfolio from "pages/capital/portfolio";
import SignUp from "pages/sign-up";
import Bid from "pages/bid";
import CapitalReceivePage from "pages/capital/receive";
import CapitalSendPage from "pages/capital/send";
import Calculadora from "pages/calculadora";
import Chat from "pages/profile/help/chat";
import CocosGold from "pages/cocos-gold";
import CocosPay from "pages/cocos-pay";
import CocosCard from "pages/cocos-card";
import CreateAccount from "pages/create-account";
import CryptoCocosTag from "pages/crypto/cocostag";
import CryptoProfile from "pages/crypto/profile";
import Faqs from "pages/profile/help/faqs";
import ForgotPassword from "pages/forgot-password";
import Help from "pages/profile/help";
import Home from "pages/home";
import InvestmentSuggested from "pages/investment-suggestion";
import InvestorTest from "pages/profile/investor-test";
import Login from "pages/login";
import Logout from "pages/logout";
import Market from "pages/market";
import MarketDetail from "pages/market/market-detail";
import Mep from "pages/mep";
import Movements from "pages/movements";
import MovementReceipt from "pages/movements/receipt";
import NotFound from "pages/errors/not-found";
import OrderConfirm from "pages/order-confirm";
import OrderDetail from "pages/order-detail";
import Orders from "pages/orders";
import PersonalData from "pages/profile/personal-data";
import Legals from "pages/profile/legals";
import Learn from "pages/profile/learn";
import Config from "pages/profile/config";
import Profile from "pages/profile";
import Results from "pages/calculadora/results";
import Security from "pages/profile/security";
import Trading from "pages/trading";
import Maintenance from "pages/maintenance";
import TickerMovements from "components/page-movements/components/movements-common/movements-ticker";
import { CapitalSendAccountsProvider } from "context/CapitalSendAccountsProvider";
import { CocosCardProvider } from "context/CocosCardProvider";
import Donate from "pages/donate";

import mainRoutes, { CryptoRoutes, default as routes } from "./routes";
import RequireAuth from "./RequireAuth";

//TODO: Mover a pages

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRouter: React.FC = () => {
  const isDarkMode = useAppSelector((state: RootState) => {
    return Boolean(state.user.user?.dark_theme);
  });
  const { subscribeList } = useAppSelector((state: RootState) => state.prices);
  const [isAppActive, setIsAppActive] = useState<boolean>(true);
  const isNative = Capacitor.isNativePlatform();
  const { isAndroid } = getPlatform();
  const { isAuthenticated, user } = useAuth();
  const { isBrazeInitialized } = useAppState();

  useEffect(() => {
    const getAppStatus = async () => {
      const { data } = await API.get(publicService.status);

      setIsAppActive(data);
    };

    getAppStatus();
  }, []);

  useEffect(() => {
    if (!isNative) return;

    if (isAndroid) {
      StatusBar.setOverlaysWebView({ overlay: false });

      setTimeout(() => {
        StatusBar.setOverlaysWebView({ overlay: true });
      }, 500);
      return;
    }
    StatusBar.setOverlaysWebView({ overlay: true });
  }, []);

  useEffect(() => {
    if (!isNative) return;
    StatusBar.setStyle({ style: isDarkMode ? Style.Dark : Style.Light });
  }, [isDarkMode]);

  useEffect(() => {
    subscribeList?.length > 0 && socket.emit("subscribe", ...subscribeList);
  }, [subscribeList]);

  useEffect(() => {
    if (!isBrazeInitialized) {
      return;
    }

    if (isAuthenticated && user?.id) {
      brazeClient.changeUser(user.id);

      const userProps: IdentifyProperties = {};

      if (user.email) {
        brazeClient.setEmail(user.email);
        userProps.email = user.email;
      }

      ampli.identify(user.id, userProps);
    }

    brazeClient.openSession();
  }, [isBrazeInitialized, user?.id]);

  useEffect(() => {
    if (user?.id) {
      Sentry.setUser({ id: user?.id });
    } else {
      Sentry.setUser(null);
    }
  }, [user?.id]);

  if (!isAppActive)
    return (
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <SentryRoutes>
            <Route path={routes.maintenance} element={<Maintenance />} />
            <Route path="*" element={<Navigate to={routes.maintenance} />} />
          </SentryRoutes>
        </BrowserRouter>
      </Suspense>
    );

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <UploaderModal />
        <AppUrlListener />
        <AppTracking />
        <SentryRoutes>
          <Route
            path={routes.maintenance}
            element={<Navigate to={routes.home} />}
          />
          <Route
            path={routes.home}
            element={<RequireAuth component={Home} />}
          />

          <Route
            path={routes.calculator}
            element={<RequireAuth component={Calculadora} />}
          />

          <Route
            path={routes.calculatorResult}
            element={<RequireAuth component={Results} />}
          />

          <Route
            path={routes.orderConfirm}
            element={<RequireAuth component={OrderConfirm} />}
          />

          <Route
            path={routes.cocosGold}
            element={<RequireAuth component={CocosGold} />}
          />

          <Route
            path={routes.investment}
            element={<RequireAuth component={InvestmentSuggested} />}
          />

          <Route path={routes.bid} element={<RequireAuth component={Bid} />} />

          <Route
            path={`${routes.orderDetail}/:id`}
            element={<RequireAuth component={OrderDetail} />}
          />

          <Route
            path={routes.marketMep}
            element={<RequireAuth component={Mep} />}
          />

          <Route
            path={`${routes.market}/:instrument_type/:id`}
            element={<RequireAuth component={Trading} />}
          />

          <Route
            path={`${routes.market}/FCI/:id`}
            element={<RequireAuth component={MarketDetail} />}
          />

          <Route
            path={`${routes.market}/:instrument_type`}
            element={<RequireAuth component={Market} />}
          />

          <Route
            path={routes.market}
            element={<RequireAuth component={NavigateToMarket} />}
          />

          <Route
            path={routes.personalData}
            element={<RequireAuth component={PersonalData} />}
          />

          <Route
            path={routes.profile}
            element={<RequireAuth component={Profile} />}
          />

          <Route
            path={routes.profileTest}
            element={<RequireAuth component={InvestorTest} />}
          />

          <Route
            path={routes.profileConfig}
            element={<RequireAuth component={Config} />}
          />

          <Route
            path={routes.profileLearn}
            element={<RequireAuth component={Learn} />}
          />

          <Route
            path={routes.profileLegals}
            element={<RequireAuth component={Legals} />}
          />

          <Route
            path={routes.portfolio}
            element={<RequireAuth component={CapitalPortfolio} />}
          />

          <Route
            path={`${routes.funds}/:fundId`}
            element={<RequireAuth component={CapitalFunds} />}
          />

          <Route element={<CapitalSendAccountsProvider />}>
            <Route
              path={routes.accounts}
              element={<RequireAuth component={CapitalAccounts} />}
            />
            <Route
              path={routes.send}
              element={<RequireAuth component={CapitalSendPage} />}
            />
          </Route>

          <Route
            path={`${routes.movements}/:instrument_code`}
            element={<RequireAuth component={TickerMovements} />}
          />

          <Route
            path={routes.movements}
            element={<RequireAuth component={Movements} />}
          />

          <Route
            path={`${routes.movementReceipt}/:id`}
            element={<RequireAuth component={MovementReceipt} />}
          />

          <Route
            path={routes.orders}
            element={<RequireAuth component={Orders} />}
          />

          <Route
            path={routes.help}
            element={<RequireAuth component={Help} />}
          />

          <Route
            path={routes.faqs}
            element={<RequireAuth component={Faqs} />}
          />

          <Route
            path={routes.chat}
            element={<RequireAuth component={Chat} />}
          />

          <Route
            path={routes.createAccount}
            element={<RequireAuth component={CreateAccount} />}
          />

          <Route
            path={routes.pay}
            element={<RequireAuth component={CocosPay} />}
          />

          <Route element={<CocosCardProvider />}>
            <Route
              path={routes.card}
              element={<RequireAuth component={CocosCard} />}
            />

            <Route
              path={routes.cardHelp}
              element={<RequireAuth component={CocosCardHelp} />}
            />

            <Route
              path={routes.cardIssuesMovements}
              element={<RequireAuth component={CocosCardIssueMovements} />}
            />

            <Route
              path={routes.cardIssues}
              element={<RequireAuth component={CocosCardIssues} />}
            />

            <Route
              path={routes.cardLimits}
              element={<RequireAuth component={CocosCardLimits} />}
            />

            <Route
              path={routes.cardPin}
              element={<RequireAuth component={CocosCardPin} />}
            />

            <Route
              path={routes.cardSettings}
              element={<RequireAuth component={CocosCardSettings} />}
            />

            <Route
              path={routes.cardTransactions}
              element={<RequireAuth component={CocosCardTransactions} />}
            />

            <Route
              path={`${routes.cardTransactions}/:id`}
              element={<RequireAuth component={CocosCardTransactionDetail} />}
            />
          </Route>

          <Route
            path={routes.security}
            element={<RequireAuth component={Security} />}
          />

          <Route
            path={routes.cocos2FA}
            element={<RequireAuth component={TwoFAPage} />}
          />

          <Route
            path={routes.trustedDevice}
            element={<RequireAuth component={TrustedDevice} />}
          />

          <Route
            path={routes.phoneValidation}
            element={<RequireAuth component={PhoneValidation} />}
          />

          <Route
            path={routes.enableBiometry}
            element={<RequireAuth component={EnableBiometry} />}
          />

          <Route
            path={routes.receive}
            element={<RequireAuth component={CapitalReceivePage} />}
          />

          <Route
            path={routes.donate}
            element={<RequireAuth component={Donate} />}
          />

          {/*  CRYPTO  */}

          <Route
            path={CryptoRoutes.MARKET}
            element={<RequireAuth isCryptoRoute component={CryptoMarket} />}
          />

          <Route
            path={CryptoRoutes.PORTFOLIO}
            element={<RequireAuth isCryptoRoute component={CryptoPortfolio} />}
          />

          <Route
            path={CryptoRoutes.PERFORMANCE}
            element={
              <RequireAuth isCryptoRoute component={CryptoPerformance} />
            }
          />

          <Route
            path={CryptoRoutes.TICKER}
            element={
              <RequireAuth isCryptoRoute component={CryptoMarketDetail} />
            }
          />

          <Route
            path={CryptoRoutes.HOME}
            element={<RequireAuth isCryptoRoute component={CryptoHome} />}
          />

          <Route
            path={CryptoRoutes.ACTIVITY}
            element={<RequireAuth isCryptoRoute component={Activity} />}
          />

          <Route
            path={CryptoRoutes.AGENDA}
            element={<RequireAuth isCryptoRoute component={AgendaSection} />}
          />

          <Route
            path={CryptoRoutes.SWAP}
            element={<RequireAuth isCryptoRoute component={Swap} />}
          />

          <Route
            path={CryptoRoutes.RECEIVE}
            element={<RequireAuth isCryptoRoute component={Receive} />}
          />

          <Route
            path={CryptoRoutes.SEND}
            element={<RequireAuth isCryptoRoute component={Send} />}
          />

          <Route
            path={CryptoRoutes.ALLOCATION}
            element={<RequireAuth isCryptoRoute component={Allocation} />}
          />

          <Route
            path={CryptoRoutes.LEGALS}
            element={<RequireAuth isCryptoRoute component={LegalsSection} />}
          />

          <Route
            path={CryptoRoutes.HELP}
            element={<RequireAuth isCryptoRoute component={HelpSection} />}
          />

          <Route
            path={CryptoRoutes.FAQS}
            element={<RequireAuth isCryptoRoute component={FaqsSection} />}
          />

          <Route
            path={CryptoRoutes.LEARN}
            element={<RequireAuth isCryptoRoute component={LearnSection} />}
          />

          <Route
            path={CryptoRoutes.CONFIG}
            element={<RequireAuth isCryptoRoute component={ConfigSection} />}
          />

          <Route
            path={CryptoRoutes.SECURITY}
            element={<RequireAuth isCryptoRoute component={SecuritySection} />}
          />

          <Route
            path={CryptoRoutes.PROFILE}
            element={<RequireAuth isCryptoRoute component={CryptoProfile} />}
          />

          <Route
            path={CryptoRoutes.TWOFA}
            element={<RequireAuth isCryptoRoute component={Crypto2FAPage} />}
          />

          <Route
            path={CryptoRoutes.TYC}
            element={
              <RequireAuth isCryptoRoute component={CryptoWelcomePage} />
            }
          />

          <Route
            path={CryptoRoutes.CABLE}
            element={<RequireAuth isCryptoRoute component={Cable} />}
          />

          <Route
            path={CryptoRoutes.TAG}
            element={<RequireAuth isCryptoRoute component={CryptoCocosTag} />}
          />

          <Route
            path={CryptoRoutes.LIMITS}
            element={<RequireAuth isCryptoRoute component={CryptoLimits} />}
          />

          <Route
            path={CryptoRoutes.RECEIVE_ARS}
            element={<RequireAuth isCryptoRoute component={ReceiveArs} />}
          />

          <Route path={CryptoRoutes.AGREEMENT} element={<div />} />

          <Route path={mainRoutes.signUp} element={<SignUp />} />

          <Route path={mainRoutes.altas} element={<SignUp />} />

          <Route path={routes.login} element={<Login />} />

          <Route path={routes.forgotPassword} element={<ForgotPassword />} />

          <Route path={routes.resetPassword} element={<ResetPassword />} />

          <Route
            path={routes.changePassword}
            element={<ChangePasswordPage />}
          />

          <Route path={routes.emailValidation} element={<EmailValidation />} />

          <Route path={routes.logout} element={<Logout />} />

          <Route path="*" element={<NotFound />} />
        </SentryRoutes>

        <BackDropSpinner />
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRouter;

const NavigateToMarket: React.FC = () => (
  <Navigate replace to={`${routes.market}/ACCIONES`} />
);
