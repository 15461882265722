import { CDN_URL } from "constants/index";

import type { ReactNode } from "react";

import {
  ArrowDownWideNarrow,
  ArrowUpWideNarrow,
  CreditCard,
  Minus,
  PlusIcon,
  QrCode,
  LucideIcon,
  Send,
} from "lucide-react";
import USFlag from "assets/icons/USFlag";
import {
  Cancelled,
  Filled,
  PartiallyCancelled,
  PartiallyExecuted,
  Pending,
  Rejected,
} from "assets/icons/OrderStatus";
import {
  OperationSide,
  OrderSource,
  OrderStatus,
} from "interfaces/orders/enums";

export const getStatusIcon = (status: OrderStatus): ReactNode => {
  if (status === OrderStatus.CANCELLED) return <Cancelled />;
  if (status === OrderStatus.FILLED) return <Filled />;
  if (status === OrderStatus.PARTIALLY_CANCELLED) return <PartiallyCancelled />;
  if (status === OrderStatus.PARTIALLY_EXECUTED) return <PartiallyExecuted />;
  if (status === OrderStatus.REJECTED) return <Rejected />;

  return <Pending />;
};

export const getOperationSideHeading = (
  oprationSide: OperationSide
): string => {
  if (oprationSide === OperationSide.BID) return "Licitación";
  if (oprationSide === OperationSide.BUY) return "Compra";
  if (oprationSide === OperationSide.SELL) return "Venta";
  if (oprationSide === OperationSide.REDEMPTION) return "Rescate";
  if (oprationSide === OperationSide.SUBSCRIPTION) return "Suscripción";
  if (oprationSide === OperationSide.WITHDRAWAL) return "Enviaste";
  if (oprationSide === OperationSide.TAKER) return "Tomaste";
  if (oprationSide === OperationSide.SETTER) return "Colocaste";

  return "Operación";
};

export const getSmallIcon = (
  side: OperationSide,
  source?: OrderSource
): LucideIcon => {
  if (source === OrderSource.CARD) return CreditCard;
  if (source === OrderSource.QR) return QrCode;
  if (side === OperationSide.SELL) return Minus;
  if (side === OperationSide.REDEMPTION) return ArrowDownWideNarrow;
  if (side === OperationSide.SUBSCRIPTION) return ArrowUpWideNarrow;
  if (side === OperationSide.WITHDRAWAL) return Send;

  return PlusIcon;
};

export const getSettlementDays = (settlementDays: number) => {
  if (settlementDays === 0) return "En C.I.";
  if (settlementDays === 1) return "En 24hs";
  if (settlementDays === 2) return "En 48hs";
  if (settlementDays === 3) return "En 72hs";

  return `A ${settlementDays} días`;
};

export const getMainIcon = (isMep: boolean, logo?: string) => {
  if (isMep) return <USFlag />;
  if (!logo) return <img src={`${CDN_URL}/logos/ARG.jpg`} />;

  return <img src={`${CDN_URL}/logos/${logo}`} />;
};
