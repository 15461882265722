import { ReactNode } from "react";

import CheckedDNI from "assets/illustrations/CheckedDNI";
import FirstDeposit from "assets/illustrations/FirstDeposit";
import EmptyState, { EmptyButtonTypes } from "components/common/EmptyState";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";
import FirstInvestment from "assets/illustrations/FirstInvestment";
import classNames from "classnames";
import { useDeviceCheck } from "hooks/useDeviceCheck";

import styles from "./styles.module.scss";

interface EmptyItemTypes {
  illustration: ReactNode;
  title: string;
  subtitle: string;
  buttonText: string;
  onClick: () => void;
}

export enum PortfolioEmpty {
  ACCOUNT = "ACCOUNT",
  CASH_IN = "CASH_IN",
  INVESTMENT = "INVESTMENT",
  ONBOARDING = "ONBOARDING",
}

interface EmptyStateTypes {
  [PortfolioEmpty.ACCOUNT]: EmptyItemTypes;
  [PortfolioEmpty.CASH_IN]: EmptyItemTypes;
  [PortfolioEmpty.INVESTMENT]: EmptyItemTypes;
  [PortfolioEmpty.ONBOARDING]: EmptyItemTypes;
}

const PortfolioEmptyState = ({
  portfolioState,
  variant = ThemeVariants.Capital,
}: {
  portfolioState: PortfolioEmpty;
  variant?: ThemeVariants;
}) => {
  const navigate = useNavigate();

  const { isDesktop } = useDeviceCheck();

  const isCapital = variant === ThemeVariants.Capital;

  const cashInSubtitle = isCapital
    ? "Vas a poder comprar Dólar MEP, invertir en activos, pagar con QR, y muchas cosas más."
    : "Vas a poder comprar Dólar Crypto, invertir en activos, pagar con QR, y muchas cosas más.";
  const cashInRoute = isCapital ? mainRoutes.receive : CryptoRoutes.RECEIVE_ARS;

  const EMPTY_STATE: EmptyStateTypes = {
    [PortfolioEmpty.ACCOUNT]: {
      illustration: <CheckedDNI size={93} />,
      title: "Abrí tu cuenta comitente",
      subtitle: "Completá tus datos personales para poder operar en Cocos.",
      buttonText: "Abrir cuenta",
      onClick: () => {
        navigate(mainRoutes.createAccount);
      },
    },
    [PortfolioEmpty.CASH_IN]: {
      illustration: <FirstDeposit variant={variant} size={124} />,
      title: "Hacé tu primer depósito",
      subtitle: cashInSubtitle,
      buttonText: "Ingresá dinero",
      onClick: () => {
        navigate(cashInRoute);
      },
    },
    [PortfolioEmpty.INVESTMENT]: {
      illustration: <FirstInvestment variant={variant} />,
      title: "Hacé una inversión",
      subtitle:
        "Visitá el mercado para invertir en Cedears, Acciones, Bonos Públicos, ONs, Letras, Fondos de Inversión y más.",
      buttonText: "Ir al mercado",
      onClick: () => {
        navigate(mainRoutes.market);
      },
    },
    [PortfolioEmpty.ONBOARDING]: {
      illustration: <CheckedDNI size={93} />,
      title: "Finalizá tu cuenta comitente",
      subtitle: "Falta poco para completar tus datos y poder operar en Cocos.",
      buttonText: "Finalizar cuenta",
      onClick: () => {
        navigate(mainRoutes.createAccount);
      },
    },
  };

  return (
    <div className={styles.emptyStateWrapper}>
      <EmptyState
        {...EMPTY_STATE[portfolioState]}
        buttonType={EmptyButtonTypes.BUTTON}
        buttonVariant={variant}
        className={classNames([
          styles.emptyState,
          { [styles.desktop]: isDesktop },
        ])}
      />
    </div>
  );
};

export default PortfolioEmptyState;
