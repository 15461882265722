import { IconProps } from "assets/icons/BTC";
import { ThemeVariants } from "interfaces/theme";
import Heading, { HeadingVariants } from "components/common//Heading";
import FilledButton from "components/common/FilledButton";
import Text, { TextVariants } from "components/common/Text";
import { ArrowUpRight, LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";
import classNames from "classnames";

import Link from "../Link";

import styles from "./styles.module.scss";

export enum EmptyButtonTypes {
  NONE,
  LINK,
  BUTTON,
}

export type EmptyStateProps = {
  icon?: React.FC<IconProps> | LucideIcon;
  illustration?: React.ReactNode;
  title: string;
  subtitle: string;
  className?: string;
} & (
  | {
      buttonType: EmptyButtonTypes.BUTTON | EmptyButtonTypes.LINK;
      buttonText: string;
      onClick: () => void;
      buttonVariant: ThemeVariants;
    }
  | {
      buttonType?: EmptyButtonTypes.NONE;
    }
);

const EmptyState: React.FC<EmptyStateProps> = ({
  icon: Icon,
  illustration,
  title,
  subtitle,
  className,
  ...props
}) => {
  const { buttonType } = props;
  return (
    <div className={classNames([styles.emptyStateWrapper, className])}>
      {Icon ? (
        <Icon
          size={px2rem(24)}
          className={styles.icon}
          color="var(--slate800)"
        />
      ) : (
        <div className={styles.illustrationWrapper}>{illustration}</div>
      )}
      <Heading
        component="h2"
        color="var(--slate900)"
        className={styles.text}
        variant={HeadingVariants.RegularSubtitle}
      >
        {title}
      </Heading>
      <Text
        color="var(--slate800)"
        className={styles.text}
        variant={TextVariants.RegularTextS}
      >
        {subtitle}
      </Text>
      {buttonType === EmptyButtonTypes.LINK && (
        <Link
          isSmall
          icon={ArrowUpRight}
          onClick={props.onClick}
          variant={props.buttonVariant}
        >
          {props.buttonText}
        </Link>
      )}
      {buttonType === EmptyButtonTypes.BUTTON && (
        <FilledButton
          onClick={props.onClick}
          className={styles.button}
          variant={props.buttonVariant}
        >
          {props.buttonText}
        </FilledButton>
      )}
    </div>
  );
};

export default EmptyState;
