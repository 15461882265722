import { CryptoCablePrivacyURL } from "constants/index";

import { useEffect, useState } from "react";

import { Browser } from "@capacitor/browser";
import { ampli } from "ampli";
import { DollarSign } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";
import InputCheckbox from "components/common/input/InputCheckbox";
import mainRoutes, { CryptoRoutes } from "router/routes";
import { ThemeVariants } from "interfaces/theme";
import PayMethod from "components/page-cocos-pay/Method";
import { useCocosPay } from "context/CocosPayProvider";
import { useCryptoCable } from "context/CryptoCableProvider";
import { PaymentStatus } from "interfaces/pay/enums";

import CreateAccount from "../CreateAccount";

const Paywall: React.FC = () => {
  const navigate = useNavigate();
  const { receiptData } = useCocosPay();
  const { isCapital } = useCryptoCable();

  const [displayPaymentMethods, setDisplayPaymentMethods] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  useEffect(() => {
    ampli.cclScreenPaywall({
      is_button_active: true,
    });
  }, []);

  if (receiptData.status === PaymentStatus.TO_BE_CONFIRM_BY_BRIDGE) {
    return <CreateAccount />;
  }

  if (displayPaymentMethods) {
    return <PayMethod isCapital={isCapital} />;
  }
  const onClickContinue = () => {
    ampli.cclCryptoPaywallAccepted();
    setDisplayPaymentMethods(true);
  };

  const onClose = () => {
    ampli.cclCryptoExit({ referral: "paywall" });
    const route = isCapital ? mainRoutes.receive : CryptoRoutes.HOME;
    navigate(route, { state: { isCapital } });
  };

  const onClickLegals = () => {
    Browser.open({ url: CryptoCablePrivacyURL });
  };

  return (
    <BasicWrapper
      themeVariant={isCapital ? ThemeVariants.Capital : ThemeVariants.Crypto}
      navigationHeaderProps={{
        title: "Antes de continuar",
        onClick: onClose,
      }}
      title="La apertura de tu cuenta tiene un costo por única vez de 10 US$."
      subtitle="Debemos realizar un cobro único equivalente a 10 US$ que deberás tener disponible en tu cuenta. ¿Estás de acuerdo?"
      icon={DollarSign}
      primaryButtonProps={{
        children: "Sí, estoy de acuerdo",
        onClick: onClickContinue,
        disabled: !termsAccepted,
      }}
      secondaryButtonProps={{
        children: "En otro momento",
        onClick: onClose,
      }}
    >
      <InputCheckbox
        name="terms"
        checked={termsAccepted}
        onChange={() => setTermsAccepted(!termsAccepted)}
        variant={isCapital ? ThemeVariants.Capital : ThemeVariants.Crypto}
      >
        Acepto los{" "}
        <Text
          component="span"
          color={isCapital ? "var(--blue800)" : "var(--purple800)"}
          variant={TextVariants.SemiboldTextS}
          onClick={() => onClickLegals()}
        >
          Términos y Condiciones
        </Text>
        .
      </InputCheckbox>
    </BasicWrapper>
  );
};

export default Paywall;
