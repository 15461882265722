import ff from "constants/featureFlags";
import { getListMenuHome } from "constants/capitalMenu";

import React, { useEffect } from "react";

import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "hooks/redux";
import { NeedHelp } from "components/common/help-redirect";
import CommonLayout from "layouts/common";
import { getLoggedUser } from "store/selectors/user.selector";
import mainRoutes from "router/routes";
import { ampli } from "ampli";
import useFeatureFlag from "hooks/useFeatureFlag";

import OrdersList from "./OrdersList";
import OrdersHeader from "./OrdersHeader";
import Orders from "./v2";

import styles from "./styles.module.scss";

const OrdersPage: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { isFeatureEnabled: isOrdersRedesignEnabled } = useFeatureFlag(
    ff.ENABLE_ORDERS_REDESIGN
  );

  const from = params.get("from");

  useEffect(() => {
    ampli.ordersScreenViewed({ referral: from || "" });
  }, []);

  const user = useAppSelector(getLoggedUser);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  if (isOrdersRedesignEnabled) return <Orders />;

  return (
    <div className={styles.pageTransfers}>
      <CommonLayout withHeader={false} {...getListMenuHome()}>
        <OrdersHeader goBack={() => navigate(mainRoutes.home)} />
        <div className={styles.pageTransfersContent}>
          <OrdersList />
          {user.hasAccount && (
            <NeedHelp leyend="¿No encontrás una operación?" />
          )}
        </div>
      </CommonLayout>
    </div>
  );
};

export default OrdersPage;
