import CapitalReceivePage from "components/page-capital-receive";
import { CocosPayProvider } from "context/CocosPayProvider";
import { CryptoCableProvider } from "context/CryptoCableProvider";

const Receive: React.FC = () => (
  <CryptoCableProvider>
    <CocosPayProvider>
      <CapitalReceivePage />
    </CocosPayProvider>
  </CryptoCableProvider>
);

export default Receive;
