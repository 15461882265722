import Avatar from "components/common/avatar";
import QuietButton from "components/common/QuietButton";
import Text, { TextVariants } from "components/common/Text";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import { getUserFullName } from "utils";

import SelectAccount from "./SelectAccount";

import styles from "./styles.module.scss";

const ProfileCard: React.FC = () => {
  const navigate = useNavigate();
  const user = useSelector(getLoggedUser);
  const { isMobile } = useDeviceCheck();

  if (!user) return null;

  const { first_name, middle_name, last_name, id_accounts } = user;
  const name = getUserFullName(first_name, middle_name, last_name);

  return (
    <div className={styles.profileCardContainer}>
      <div className={styles.info}>
        <Avatar isLarge />
        {id_accounts.length > 0 && (
          <>
            <Text variant={TextVariants.RegularText} color="var(--slate900)">
              {name}
            </Text>
            <SelectAccount />
          </>
        )}
      </div>

      {isMobile && (
        <QuietButton
          className={styles.button}
          onClick={() => navigate(mainRoutes.receive)}
        >
          Ver alias y CVU
        </QuietButton>
      )}
    </div>
  );
};

export default ProfileCard;
