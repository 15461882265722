import { useEffect } from "react";

import { ampli } from "ampli";
import { ThemeVariants } from "interfaces/theme";
import FilledButton from "components/common/FilledButton";
import Text, { TextVariants } from "components/common/Text";
import { Clock4 } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface CreationInProgressProps {
  onClickLater: () => void;
  variant: ThemeVariants;
}

const CreationInProgress: React.FC<CreationInProgressProps> = ({
  onClickLater,
  variant,
}) => {
  useEffect(() => {
    ampli.cclCryptoOnboarding({
      status: "delayed",
      referral: variant === ThemeVariants.Crypto ? "crypto" : "capital",
    });
  }, []);

  const onClose = () => {
    ampli.cclCryptoExit({ referral: "delayed" });
    onClickLater();
  };

  return (
    <div className={styles.contentWrapper}>
      <Text
        color="var(--slate900)"
        className={styles.title}
        variant={TextVariants.RegularText}
      >
        Creación en proceso
      </Text>
      <div className={styles.iconWrapper}>
        <Clock4 size={px2rem(24)} color="var(--orange900)" />
      </div>
      <Text
        color="var(--slate800)"
        className={styles.text}
        variant={TextVariants.RegularText}
      >
        Estamos procesando los datos para la creación de tu cuenta. Podés volver
        en unos minutos cuando haya finalizado.
      </Text>
      <FilledButton
        onClick={onClose}
        className={styles.button}
        variant={variant}
      >
        Volver más tarde
      </FilledButton>
    </div>
  );
};

export default CreationInProgress;
