import { useEffect } from "react";

import Error from "assets/illustrations/Error";
import BasicWrapper from "components/common/BasicWrapper";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import px2rem from "utils/px2rem";
import Text, { TextVariants } from "components/common/Text";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useAppDispatch } from "hooks/redux";
import { ampli } from "ampli";

import styles from "./styles.module.scss";

interface FailureProps {
  attemptedDonationAmount: number;
}

const Failure: React.FC<FailureProps> = ({ attemptedDonationAmount }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceCheck();

  const goToHome = () => {
    if (isMobile) {
      navigate(mainRoutes.home);
      return;
    }

    dispatch(closeRightModal());
  };

  useEffect(() => {
    ampli.donationOrderError({ amount: attemptedDonationAmount });
  }, []);

  return (
    <BasicWrapper
      navigationHeaderProps={{
        title: "Algo salió mal",
        onClick: goToHome,
      }}
      primaryButtonProps={{
        children: "Volver al inicio",
        onClick: goToHome,
      }}
    >
      <div className={styles.failureWrapper}>
        <Error size={px2rem(84)} />
        <Text
          variant={TextVariants.RegularText}
          color="var(--slate800)"
          className={styles.text}
        >
          No pudimos realizar la donación.
        </Text>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Por favor, volvé a intentarlo más tarde.
        </Text>
      </div>
    </BasicWrapper>
  );
};

export default Failure;
