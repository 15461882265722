import { getListMenuHome } from "constants/capitalMenu";

import { useEffect, useState } from "react";

import BidContainer from "components/common/bid";
import InvestmentSuggestionContainer from "components/common/investment-suggestion";
import ContainerProfileTest from "components/common/investor-test-check/ContainerProfileTest";
import CapitalReceivePage from "components/page-capital-receive";
import CapitalSendPage from "components/page-capital-send";
import { CapitalSendAccountsProvider } from "context/CapitalSendAccountsProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { MarketList, MarketTypes } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import CommonLayout from "layouts/common";
import { default as Drawer } from "layouts/drawer";
import { Navigate, useNavigate } from "react-router-dom";
import mainRoutes, { screenNames } from "router/routes";
import {
  setCurrentScreen,
  getCurrentScreen,
} from "features/global/globalSlice";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";
import { trackAction, trackScreen } from "utils/amplitude";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";

import { HomeMobile } from "./mobile";
import { HomeDesktop } from "./desktop";

import "./index.scss";

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { openRightModalContent } = useAppState();

  const { isMobile } = useDeviceCheck();
  const [selectedTabHome] = useState<string>(MarketTypes.Cedears.toLowerCase());
  const [filteredMarkets, setFilteredMarkets] = useState<MarketList>();
  const marketsLists = useAppSelector(
    (state: RootState) => state.markets.marketsLists
  );
  const user = useAppSelector(getLoggedUser);
  const currentScreen = useAppSelector(getCurrentScreen);
  const [bidCurrency, setBidCurrency] = useState<Currencies>(Currencies.USD);
  const navigate = useNavigate();

  const getMarketsInfo = async () => {
    filterMarkets();
  };

  const filterMarkets = () => {
    const selectedList = marketsLists.find(
      (l) => l.list_name === selectedTabHome
    );
    const selectedIds = selectedList?.market_data.map((s) => s.long_ticker);

    return selectedIds;
  };

  const onSubmitSearch = (value: string) => {
    if (!value || value.length === 0) {
      return getMarketsInfo();
    }
    const searchResults: MarketList | undefined = filteredMarkets && {
      ...filteredMarkets,
      market_data: filteredMarkets?.market_data.filter(
        (m) =>
          m.short_ticker?.toLowerCase().includes(value) ||
          m.long_ticker.toLowerCase().includes(value)
      ),
    };

    setFilteredMarkets(searchResults);
  };

  const handleClickDepositar = () => {
    trackAction(`${screenNames.home} - Click deposit`);
    openRightModalContent(
      <Drawer headerTitle="Depositar">
        <CapitalReceivePage isDesktop />
      </Drawer>
    );
  };

  const handleClickExtraer = () => {
    trackAction(`${screenNames.home} - Click extract`);
    if (!isMobile) {
      openRightModalContent(
        <Drawer headerTitle="Extraer">
          <CapitalSendAccountsProvider>
            <CapitalSendPage isDesktop />
          </CapitalSendAccountsProvider>
        </Drawer>
      );
    } else {
      navigate(mainRoutes.receive);
    }
  };

  const handleClickInvestmentSuggestion = () => {
    if (!isMobile) {
      return openRightModalContent(<InvestmentSuggestionContainer />);
    }
    navigate(mainRoutes.investment);
  };
  const handleClickBid = () => {
    trackAction(`${screenNames.home} - Confirm Bid ${bidCurrency}`);
    if (!isMobile) {
      return openRightModalContent(<BidContainer />);
    }

    navigate(mainRoutes.bid, { state: bidCurrency });
  };
  useEffect(() => {
    trackScreen(screenNames.home, currentScreen);
    dispatch(setCurrentScreen(screenNames.home));
  }, []);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  if (isMobile) return <HomeMobile />;

  return (
    <div className="pages-home">
      {user.hasOwnerAccount && <ContainerProfileTest />}

      <CommonLayout
        {...getListMenuHome()}
        mobileHeaderTitle=""
        search={onSubmitSearch}
        withHeader={false}
      >
        <HomeDesktop
          onClickDepositar={handleClickDepositar}
          onClickInvestmentSuggestion={handleClickInvestmentSuggestion}
          onClickBid={handleClickBid}
          onClickExtraer={handleClickExtraer}
        />
      </CommonLayout>
    </div>
  );
};

export default HomePage;
