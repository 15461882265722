import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";

import API from "apis";
import { cryptoService } from "apis/services";
import { ExternalAccountData } from "interfaces/crypto";
import { useLocation, useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";

interface LocationState {
  isCapital?: boolean;
}

interface Context {
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  error: boolean;
  setError: (v: boolean) => void;
  goToHome: () => void;
  externalAccountData: ExternalAccountData | null;
  setExternalAccountData: (value: ExternalAccountData | null) => void;
  isCreationInProgress: boolean;
  setIsCreationInProgress: (v: boolean) => void;
  isCapital?: boolean;
}

const CryptoCableContext = createContext<Context | null>(null);

export const CryptoCableProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const locationState = useLocation().state as LocationState | null;

  const [
    externalAccountData,
    setExternalAccountData,
  ] = useState<ExternalAccountData | null>(null);
  const [isCreationInProgress, setIsCreationInProgress] = useState<boolean>(
    false
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const isCapital = locationState?.isCapital;

  useEffect(() => {
    const getExternalAccount = async () => {
      try {
        const { data } = await API.get<ExternalAccountData>(
          cryptoService.fiatExternalCustomer
        );
        setExternalAccountData(data);
      } catch (error: any) {
        if (error?.response?.status === 404) {
          setExternalAccountData(null);
        } else {
          setError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    getExternalAccount();
  }, []);

  const goToHome = () => {
    isCapital ? navigate(mainRoutes.home) : navigate(CryptoRoutes.HOME);
  };

  const memoizedValues = useMemo(() => {
    return {
      externalAccountData,
      setExternalAccountData,
      goToHome,

      isLoading,
      setIsLoading,
      error,
      setError,
      isCreationInProgress,
      setIsCreationInProgress,
      isCapital,
    };
  }, [
    externalAccountData,
    setExternalAccountData,
    goToHome,
    isLoading,
    setIsLoading,
    error,
    setError,
    setError,
    isCreationInProgress,
    setIsCreationInProgress,
    isCapital,
  ]);

  return (
    <CryptoCableContext.Provider value={memoizedValues}>
      {children}
    </CryptoCableContext.Provider>
  );
};

export default CryptoCableContext;

export const useCryptoCable = () => {
  const context = useContext(CryptoCableContext);
  if (!context) {
    throw new Error("[CryptoCableContext] Missing context");
  }
  return context;
};
