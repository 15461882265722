import {
  DOLAR_DDJJ,
  DOLAR_OPEN_MEP_DDJJ,
  closedMepTyC,
  openMepTyC,
} from "constants/index";

import { useState } from "react";

import ARGFlag from "assets/icons/ARGFlag";
import USFlag from "assets/icons/USFlag";
import Heading, { HeadingVariants } from "components/common/Heading";
import { UIModalSlider } from "components/common/modal-slider";
import { ArrowRight } from "lucide-react";
import px2rem from "utils/px2rem";
import classNames from "classnames";
import OutlinedButton from "components/common/OutlinedButton";
import FilledButton from "components/common/FilledButton";
import PriceLabel from "components/common/PriceLabel";
import { Currencies } from "interfaces/wallet";
import Text, { TextVariants } from "components/common/Text";
import { confirmDolarOrder } from "features/markets";
import { OrderType } from "interfaces/markets";
import { MepSteps } from "components/page-mep";
import { ampli } from "ampli";
import { getDolarType } from "components/page-mep/getDolarType";
import { Browser } from "@capacitor/browser";
import { useAppDispatch } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";

import DetailBox from "../DetailBox";

import styles from "./styles.module.scss";

interface OrderBottomSheetProps {
  isBuy: boolean;
  amount: string;
  isOpen: boolean;
  isFixed: boolean;
  mepPrice: number;
  onClose: () => void;
  orderType: OrderType;
  convertedAmount: number;
  setCurrentStep: (step: MepSteps) => void;
}

const OrderBottomSheet: React.FC<OrderBottomSheetProps> = ({
  isBuy,
  isOpen,
  amount,
  onClose,
  isFixed,
  mepPrice,
  orderType,
  setCurrentStep,
  convertedAmount,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceCheck();

  const orderSide = isBuy ? "comprar" : "vender";
  const heading = `Cantidad a ${orderSide}`;
  const boxTitle = `A ${orderSide}`;
  const currency = isBuy ? Currencies.ARS : Currencies.USD;
  const buyingCurrency = !isBuy ? Currencies.ARS : Currencies.USD;

  const dolarType = getDolarType(isFixed);

  const createMEPOrder = () => {
    const arsAmount = isBuy ? Number(amount) : convertedAmount;
    const usdAmount = isBuy ? convertedAmount : Number(amount);

    setIsButtonLoading(true);

    ampli.mepOrderReviewed({
      arsAmount,
      usdAmount,
      side: orderType,
      type: dolarType,
    });

    dispatch(
      confirmDolarOrder({
        dolarType,
        dolarOrder: {
          type: orderType,
          usdAmount,
          arsAmount,
          selectedCurrency: isBuy ? Currencies.ARS : Currencies.USD,
        },
        onComplete: setCurrentStep,
      })
    );
  };

  const onClickDDJJ = () => {
    Browser.open({
      url: isFixed ? DOLAR_DDJJ : DOLAR_OPEN_MEP_DDJJ,
    });
  };

  const onClickTyC = () => {
    Browser.open({
      url: isFixed ? closedMepTyC : openMepTyC,
    });
  };

  return (
    <UIModalSlider
      open={isOpen}
      onToggleDisplay={onClose}
      classes={classNames(styles.sheetWrapper, { [styles.desktop]: !isMobile })}
    >
      <div className={styles.contentWrapper}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          component="h2"
          className={styles.heading}
          color="var(--slate900)"
        >
          {heading}
        </Heading>
        <div className={classNames(styles.icons, { [styles.reverse]: isBuy })}>
          <USFlag size={px2rem(32)} />
          <ArrowRight size={px2rem(16)} color="var(--slate800)" />
          <ARGFlag size={px2rem(32)} />
        </div>
        <div className={styles.priceWrapper}>
          <PriceLabel price={convertedAmount} currency={buyingCurrency} />
        </div>
        <DetailBox
          isBuy={isBuy}
          isFixed={isFixed}
          mepPrice={mepPrice}
          boxTitle={boxTitle}
          currency={currency}
          amount={Number(amount)}
        />
        <Text
          className={styles.tyc}
          color="var(--slate800)"
          variant={TextVariants.RegularTextS}
        >
          Al confirmar, acepto la
          <Text
            variant={TextVariants.SemiboldTextS}
            color="var(--blue800)"
            component="span"
            onClick={onClickDDJJ}
            className={styles.cursorPointer}
          >
            {" "}
            Declaración Jurada{" "}
          </Text>
          y los
          <Text
            variant={TextVariants.SemiboldTextS}
            color="var(--blue800)"
            onClick={onClickTyC}
            component="span"
            className={styles.cursorPointer}
          >
            {" "}
            Términos y Condiciones{" "}
          </Text>
          de esta operación.
        </Text>
        <div className={styles.buttonsWrapper}>
          <OutlinedButton onClick={onClose}>Volver</OutlinedButton>
          <FilledButton isLoading={isButtonLoading} onClick={createMEPOrder}>
            Confirmar
          </FilledButton>
        </div>
      </div>
    </UIModalSlider>
  );
};

export default OrderBottomSheet;
