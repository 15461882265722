import { ATENCION_CAPITAL_EMAIL } from "constants/emails";

import { Browser } from "@capacitor/browser";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import BasicWrapper from "components/common/BasicWrapper";
import Accordion from "components/common/Accordion";
import Text, { TextVariants } from "components/common/Text";
import Heading, { HeadingVariants } from "components/common/Heading";

import styles from "./styles.module.scss";

export const menuOptions = [
  {
    options: [
      {
        label: "¿La tarjeta tiene costo?",
        detail: (
          <Text
            variant={TextVariants.RegularTextS}
            className={styles.faqDetail}
          >
            La tarjeta virtual está 100% bonificada, no tiene costos de emisión
            ni de mantenimiento.
            <br />
            <br />
            La primera emisión de tu tarjeta física está 100% bonificada, solo
            se abona el costo de envío y
            <strong>no tiene costos de mantenimiento</strong>.
          </Text>
        ),
      },
    ],
  },
  {
    options: [
      {
        label: "¿Cómo funcionan los diferentes métodos de pago?",
        detail: (
          <Text
            variant={TextVariants.RegularTextS}
            className={styles.faqDetail}
          >
            La suma de los montos disponibles en cada método de pago
            seleccionado será el total disponible para realizar pagos, el cual
            podrás visualizar en la parte superior de la sección Tarjetas.
            <br />
            <br />
            Si el saldo del primer método de pago es suficiente, el importe
            completo se descontará de esta opción. Si no alcanza, se utilizará
            automáticamente el saldo total de la primera opción y lo necesario
            de la segunda. En caso de que estas dos opciones no cubran el monto
            total, se empleará también la tercera opción. Esto permite realizar
            pagos combinados entre los métodos seleccionados.
          </Text>
        ),
      },
    ],
  },
  {
    options: [
      {
        label: "¿Puedo realizar pagos en el exterior?",
        detail: (
          <Text
            variant={TextVariants.RegularTextS}
            className={styles.faqDetail}
          >
            Sí, podés usar tu tarjeta Cocos para realizar pagos en el exterior.
            Es muy importante tener en cuenta que el pago se realizará en pesos.
            Esto significa que en la operación se aplicarán los impuestos
            establecidos por la legislación del país para las compras en el
            exterior. En este caso, se va a ejecutar la conversión necesaria
            para cubrir tanto el monto de la transacción como los impuestos
            correspondientes.
          </Text>
        ),
      },
    ],
  },
  {
    options: [
      {
        label: "¿Cómo funcionan mis límites?",
        detail: (
          <Text
            variant={TextVariants.RegularTextS}
            className={styles.faqDetail}
          >
            Por motivos de seguridad, se establece un límite diario para
            realizar movimientos con la tarjeta. Podés modificar este limite,
            dirigiéndote a la sección
            <strong>Tarjetas &gt; Cambiar límite diario</strong>. Allí, podrás
            ajustar tu límite diario dentro del rango disponible utilizando el
            control deslizante.
          </Text>
        ),
      },
    ],
  },
  {
    options: [
      {
        label: "No recibí mi tarjeta física ¿Qué hago?",
        detail: (
          <Text
            variant={TextVariants.RegularTextS}
            className={styles.faqDetail}
          >
            Primero, revisá el estado de tu envío con el enlace de seguimiento.
            Si ya se cumplió el tiempo estimado de entrega y aún no la has
            recibido, por favor contáctanos en
            <strong>{ATENCION_CAPITAL_EMAIL}</strong>.
          </Text>
        ),
      },
    ],
  },
];

const PageHelp: React.FC = () => {
  const navigate = useNavigate();

  const navigationHeaderProps = {
    onClick: () => navigate(-1),
    title: "Ayuda",
    withCloseIcon: false,
    iconColor: "var(--black-to-white)",
  };

  const onClickOthers = () => {
    Browser.open({ url: "https://cocos.capital/pagos" });
  };

  return (
    <BasicWrapper navigationHeaderProps={navigationHeaderProps}>
      <div className={styles.faqsContainer}>
        <Text variant={TextVariants.RegularText} className={styles.title}>
          Preguntas frecuentes
        </Text>
        {menuOptions.map((option, index) => (
          <Accordion key={index} options={option.options} />
        ))}
        <div className={styles.others} onClick={onClickOthers}>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            component="h2"
            color="var(--slate900)"
          >
            Otras preguntas frecuentes
          </Heading>
          <ChevronRight size={16} color="var(--slate800)" />
        </div>
      </div>
    </BasicWrapper>
  );
};

export default PageHelp;
