import ARGFlag from "assets/icons/ARGFlag";
import USFlag from "assets/icons/USFlag";
import Amount from "components/common/Amount";
import { Currencies } from "interfaces/wallet";
import { Clock4 } from "lucide-react";
import px2rem from "utils/px2rem";
import Text, { TextVariants } from "components/common/Text";
import { useAppState } from "context/AppStateProvider";
import { formatRoundAmount } from "utils";

import styles from "./styles.module.scss";

interface BalanceCardProps {
  amount: number;
  withTimer?: boolean;
  currency: Currencies;
  onClick: () => void;
}

const BalanceCard: React.FC<BalanceCardProps> = ({
  amount,
  currency,
  withTimer,
  onClick,
}) => {
  const { isBalanceHidden } = useAppState();
  const Flag = currency === Currencies.ARS ? ARGFlag : USFlag;

  const getCurrencyLabel = (currency: Currencies): string => {
    if (currency === Currencies.ARS) return "Pesos";
    if (currency === Currencies.USD) return "Dólar MEP";

    return "Dólar Cable";
  };

  return (
    <div className={styles.balanceCardWrapper} onClick={onClick} role="button">
      <div className={styles.topWrapper}>
        <div className={styles.labelWrapper}>
          <Flag size={px2rem(20)} />
          <Text variant={TextVariants.RegularText} color="var(--slate900)">
            {getCurrencyLabel(currency)}
          </Text>
        </div>
        {withTimer && (
          <div className={styles.iconWrapper}>
            <Clock4
              color="var(--orange600"
              className={styles.icon}
              size={px2rem(16)}
            />
          </div>
        )}
      </div>
      <Amount
        isBalanceHidden={isBalanceHidden}
        amount={formatRoundAmount(currency, amount)}
        currency={currency}
        isArsSymbol
      />
    </div>
  );
};

export default BalanceCard;
