import { useEffect } from "react";

import Heading, { HeadingVariants } from "components/common/Heading";
import { useAppSelector } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import { checkMarketOpen } from "store/selectors/markets.selector";
import { Schedules } from "interfaces/calendar";
import { DolarTypes } from "interfaces/markets";
import Divider from "components/common/Divider";
import mainRoutes from "router/routes";
import { ampli } from "ampli";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import Mep from "components/page-mep";
import { useAppState } from "context/AppStateProvider";

import Option from "./Option";

import styles from "./styles.module.scss";

interface SelectorProps {
  trackingReferral?: string;
}

const Selector: React.FC<SelectorProps> = ({ trackingReferral }) => {
  const { dolarMep } = useAppSelector((state) => state.markets);
  const { isMobile } = useDeviceCheck();
  const { openRightModalContent } = useAppState();

  const navigate = useNavigate();

  const isOvernight = checkMarketOpen(Schedules.OVERNIGHT);
  const closedType = isOvernight ? DolarTypes.OVERNIGHT : DolarTypes.CLOSE;

  const openBid = Number(dolarMep.open.bid.toFixed(2)) ?? 0;
  const openAsk = Number(dolarMep.open.ask.toFixed(2)) ?? 0;

  const hasOpenMepBidPrice = !openBid || !openAsk;

  const onClickOption = (isFixed: boolean) => {
    const optionType = isFixed ? closedType : DolarTypes.OPEN;
    ampli.mepTypeChosen({ type: optionType });
    ampli.mepScreenViewed({ type: optionType });

    if (isMobile) {
      trackingReferral && ampli.homeExited({ referral: trackingReferral });
      navigate(mainRoutes.marketMep, { state: { isFixed } });
      return;
    }
    openRightModalContent(<Mep isFixed={isFixed} />);
  };

  useEffect(() => {
    if (isOvernight || hasOpenMepBidPrice) {
      ampli.mepScreenViewed({ type: "overnight" });

      if (isMobile) {
        trackingReferral && ampli.homeExited({ referral: trackingReferral });
        navigate(mainRoutes.marketMep, { state: { isFixed: true } });
        return;
      }
      openRightModalContent(<Mep isFixed={true} />);
    } else {
      ampli.mepBottomsheetViewed();
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <Heading
        component="h2"
        variant={HeadingVariants.RegularSubtitle}
        color="var(--slate900)"
        className={styles.title}
      >
        ¿Qué dólar vas a operar?
      </Heading>
      <div className={styles.optionsWrapper}>
        <Option
          isFixed
          title="Dólar asegurado"
          subtitle="Recibís tus dólares al precio asegurado."
          buyingPrice={Number(dolarMep.close.ask.toFixed(2))}
          sellingPrice={Number(dolarMep.close.bid.toFixed(2))}
          onClickOption={() => onClickOption(true)}
        />
        <Divider />
        <Option
          title="Dólar estimado"
          subtitle="Recibís tus dólares el próximo día hábil."
          buyingPrice={openAsk}
          sellingPrice={openBid}
          onClickOption={() => onClickOption(false)}
          isBlurred={isOvernight}
        />
      </div>
    </div>
  );
};

export default Selector;
