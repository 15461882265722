import { InstrumentData } from "./api-responses";
import { TickerSocketResponse } from "./api-responses/socket/book";
import { Currencies } from "./wallet";

export interface MarketList {
  list_name: MarketTypes | string;
  id_list: string;
  market_data: SocketMarketData[];
}

export interface BondData {
  short_ticker: string;
  name: string;
  id_currency: string;
  logo_file_name: string;
}

export interface BondFlow {
  name: string;
  date: string;
  amort: number;
  vr: number;
  capital: number;
  cashFlow: number;
  acumulated?: number;
  base_days?: number;
  id_currency?: Currencies;
  int?: number;
  interes?: number;
  vp?: number;
  nvp?: number;
}
export interface FlowAnalysis {
  bond: string;
  price: number;
  quantity: number;
  expiration_date: string;
  logo_file_name: string;
  duration: number;
  tir: number;
  flow: BondFlow[];
}

export enum Bonds {
  BONOSP = "BONOSP",
  BONOSC = "BONOSC",
}

export type SocketMarketData = TickerSocketResponse & InstrumentData;

export enum SettlementTerms {
  CI = "CI",
  _24 = "24hs",
}

export enum Method {
  LIMIT = "limite",
  MARKET = "mercado",
}

export type OptionalOrder = Optional<
  Order,
  "price" | "quantity" | "long_ticker"
>;

export interface Order {
  type: OrderType;
  quantity: number;
  long_ticker: string;
  instrument_short_name: string | undefined;
  price: number | null;
  expiration?: string;
  term?: SettlementTerms;
  method?: Method;
  amount?: number;
  currency?: Currencies;
  id?: string;
  totalRedemption?: boolean;
  enabledAmount?: boolean;
  instrument_code?: string;
  returns_perc_last_year?: number;
}

export interface CaucionOrder {
  currency: Currencies;
  amount?: number;
  term: number;
  rate?: number;
  method?: Method;
  id?: string;
}

export interface DolarPrices {
  settlement_date: string;
  short_ticker: string;
  bid: number; // sell
  ask: number; // buy
  settlementForBuy: string;
  settlementForSell: string;
  price_ars: number;
  price_usd: number;
}

export enum DolarTypes {
  OVERNIGHT = "overnight",
  CLOSE = "close",
  OPEN = "open",
}

export type DolarMep = {
  [K in DolarTypes]: DolarPrices;
};

export interface DolarMepOrder {
  type: OrderType;
  usdAmount: number;
  selectedCurrency: Currencies;
  arsAmount: number;
  id?: string;
}

export interface ChartSerie {
  time: ChartSeriesTime;
  value: number;
}

export interface ChartSeriesTime {
  year: number;
  month: number;
}
export interface MarketTermOpen {
  CI: boolean;
  "24hs": boolean;
  MEP: boolean;
  BONOS: boolean;
  CAUCION: boolean;
}

export interface LabelValue {
  label: string;
  value?: number | string | JSX.Element | null;
}

export interface CableResponse {
  id_account: number;
  id_order_canje: string;
  quantity: number;
}

export interface InvestmentSuggestedResponse {
  long_ticker: string;
  price: number;
  tir: number;
  monto_minimo: number;
  name: string;
  lamina_minima: number;
  lamina_incremental: number;
  logo_file_name: string | null;
  instrument_code: string;
  expiration: number | string;
  tasa: number;
  amort_title: string;
}

export interface BidResponse {
  success: boolean;
  orden: number;
}

export enum MarketTypes {
  Favoritos = "Favoritos",
  Cedears = "Cedears",
  Acciones = "Acciones",
  Bonos = "Bonos",
} // DELETE SOON

export enum Instruments {
  CEDEARS = "CEDEARS",
  BONOS_PUBLICOS = "BONOS_PUBLICOS",
  BONOS_CORP = "BONOS_CORP",
  FCI = "FCI",
  ACCIONES = "ACCIONES",
  LETRA_INTERNACIONAL = "LETRA_INTERNACIONAL",
  LETRAS = "LETRAS",
  CAUCION = "CAUCION",
  OPCIONES = "OPCIONES",
  FUTUROS = "FUTUROS",
}

export interface InstrumentSubtype {
  code: string;
  name: string;
}
export interface InstrumentTypeAndSubtypes {
  instrument_type: Instruments;
  type_code: string;
  name: string;
  subtypes: InstrumentSubtype[] | null;
}

export enum OrderType {
  Buy = "BUY",
  Sell = "SELL",
}

export enum Sections {
  HOME = "Home",
  FAVORITOS = "Favoritos",
  MEP = "MEP",
  CABLE = "CABLE",
}

export type MarketSection = Instruments | Sections.FAVORITOS | Sections.HOME;

export type MarketParams = {
  instrument_type: MarketSection;
};
